export const WORDS = [
  'ܬܘܪܬܐ',
  'ܣܡܛܪܐ',
  'ܡܟܢܕܪ',
  'ܛܪܦܬܐ',
  'ܝܘܚܢܝ',
  'ܥܟܘܪܐ',
  'ܚܘܕܒܐ',
  'ܙܩܪܬܐ',
  'ܕܪܦܬܐ',
  'ܫܒܚܬܐ',
  'ܡܦܪܦܫ',
  'ܚܠܨܬܐ',
  'ܡܚܘܛܐ',
  'ܥܓܘܪܐ',
  'ܢܩܝܒܐ',
  'ܓܘܘܣܐ',
  'ܛܠܩܬܐ',
  'ܫܥܘܬܐ',
  'ܨܘܥܪܐ',
  'ܡܥܪܩܠ',
  'ܥܕܝܠܐ',
  'ܫܝܡܪܐ',
  'ܟܘܟܬܐ',
  'ܡܙܪܕܐ',
  'ܐܘܡܘܕ',
  'ܚܠܘܠܐ',
  'ܓܪܘܣܐ',
  'ܚܘܝܕܐ',
  'ܡܓܢܒܚ',
  'ܦܪܡܢܐ',
  'ܡܦܩܬܐ',
  'ܟܪܡܠܐ',
  'ܓܡܘܪܐ',
  'ܦܫܟܬܐ',
  'ܠܝܡܢܐ',
  'ܣܘܟܝܐ',
  'ܫܘܚܪܐ',
  'ܒܥܝܪܐ',
  'ܡܚܘܙܐ',
  'ܣܘܡܟܐ',
  'ܣܡܝܩܐ',
  'ܡܘܚܕܐ',
  'ܒܘܣܡܐ',
  'ܬܝܘܒܐ',
  'ܩܛܝܠܐ',
  'ܫܪܟܢܐ',
  'ܩܦܘܚܐ',
  'ܐܟܝܬܘ', // Kha b'Nissan 2022
  'ܡܦܪܦܐ',
  'ܪܨܘܦܐ',
  'ܬܘܪܝܐ',
  'ܣܪܢܝܐ',
  'ܢܣܘܒܐ',
  'ܚܫܠܬܐ',
  'ܓܝܙܪܐ',
  'ܗܝܪܢܐ',
  'ܐܣܝܕܐ',
  'ܥܣܝܣܐ',
  'ܫܘܘܫܐ',
  'ܕܦܓܪܐ',
  'ܠܐܟܠܐ',
  'ܝܕܥܬܐ',
  'ܚܡܝܬܐ',
  'ܐܪܢܒܐ', // Easter 2022
  'ܡܪܩܩܐ',
  'ܩܢܟܝܐ',
  'ܚܣܟܪܐ', // Left off here
  'ܚܪܝܒܐ',
  'ܚܡܝܥܐ',
  'ܢܫܝܢܐ',
  'ܦܘܢܙܐ',
  'ܝܘܫܬܐ',
  'ܢܦܘܩܐ',
  'ܢܣܪܬܐ',
  'ܣܪܘܛܐ',
  'ܬܪܬܪܐ',
  'ܬܡܢܝܢ',
  'ܣܘܝܚܐ',
  'ܡܫܓܒܪ',
  'ܦܛܝܪܐ',
  'ܟܢܝܙܐ',
  'ܫܝܒܢܐ',
  'ܩܦܓܠܝ',
  'ܥܩܒܬܐ',
  'ܫܒܝܒܐ',
  'ܡܩܡܢܐ',
  'ܡܙܪܙܚ',
  'ܬܢܝܢܐ',
  'ܫܡܘܢܐ',
  'ܓܝܘܠܐ',
  'ܩܡܘܕܝ',
  'ܥܘܠܝܐ',
  'ܣܘܕܢܐ',
  'ܕܘܢܒܓ',
  'ܐܩܠܝܐ',
  'ܠܥܘܒܐ',
  'ܡܘܕܝܪ',
  'ܕܪܒܢܕ',
  'ܟܪܘܟܐ',
  'ܐܚܪܬܐ',
  'ܩܦܝܙܐ',
  'ܗܦܘܪܐ',
  'ܫܟܘܪܐ',
  'ܨܠܘܠܐ',
  'ܡܬܦܪܩ',
  'ܫܡܘܢܐ',
  'ܡܣܒܠܢ',
  'ܫܠܝܩܐ',
  'ܩܘܪܝܬ',
  'ܦܫܘܛܐ',
  'ܣܢܐܬܐ',
  'ܡܢܬܟܪ',
  'ܬܠܘܟܐ',
  'ܐܒܕܣܬ',
  'ܡܨܝܒܐ',
  'ܡܕܒܚܐ',
  'ܚܙܩܝܐ',
  'ܝܘܪܓܐ',
  'ܟܢܘܝܐ',
  'ܩܘܡܬܐ',
  'ܙܘܢܓܐ',
  'ܒܗܘܢܪ',
  'ܟܬܘܬܘ',
  'ܡܕܝܨܐ',
  'ܝܗܘܪܡ',
  'ܒܚܣܒܐ',
  'ܛܘܦܪܐ',
  'ܪܫܘܬܐ',
  'ܕܘܚܝܐ',
  'ܡܩܒܬܐ',
  'ܒܐܝܪܐ',
  'ܡܒܗܒܗ',
  'ܣܡܝܟܐ',
  'ܠܘܬܟܐ',
  'ܙܪܕܢܐ',
  'ܬܦܝܢܐ',
  'ܟܠܒܬܐ',
  'ܡܦܫܡܢ',
  'ܡܗܠܗܠ',
  'ܟܘܛܝܐ',
  'ܚܣܐܪܬ',
  'ܐܘܟܦܐ',
  'ܡܕܒܠܒ',
  'ܦܝܣܝܪ',
  'ܣܟܪܢܐ',
  'ܫܘܡܬܐ',
  'ܐܓܘܪܐ',
  'ܚܕܡܬܐ',
  'ܫܘܩܢܐ',
  'ܐܝܙܡܐ',
  'ܗܘܩܝܐ',
  'ܠܘܫܢܐ',
  'ܟܪܦܣܐ',
  'ܡܩܚܩܚ',
  'ܙܘܝܬܐ',
  'ܩܘܪܘܣ',
  'ܝܘܡܢܐ',
  'ܩܛܝܦܐ',
  'ܫܒܪܬܐ',
  'ܕܘܒܠܐ',
  'ܡܛܠܫܐ',
  'ܢܘܩܒܐ',
  'ܟܘܒܫܐ',
  'ܟܙܢܕܪ',
  'ܥܪܨܬܐ',
  'ܫܦܘܟܐ',
  'ܓܡܪܬܐ',
  'ܒܠܫܝܐ',
  'ܥܪܒܝܐ',
  'ܬܘܒܬܐ',
  'ܚܟܝܠܐ',
  'ܬܘܪܨܐ',
  'ܥܘܡܪܐ',
  'ܒܕܦܢܐ',
  'ܫܡܚܬܐ',
  'ܡܩܘܫܐ',
  'ܐܘܨܪܐ',
  'ܣܝܒܪܐ',
  'ܬܬܘܘܪ',
  'ܡܬܗܝܐ',
  'ܡܟܫܢܐ',
  'ܢܥܘܨܐ',
  'ܡܨܪܒܥ',
  'ܠܘܚܡܐ',
  'ܫܟܝܢܐ',
  'ܬܦܣܝܐ',
  'ܡܕܐܝܬ',
  'ܦܬܚܬܐ',
  'ܡܚܦܝܐ',
  'ܠܒܢܝܐ',
  'ܨܝܢܝܐ',
  'ܕܡܟܢܐ',
  'ܣܬܘܪܐ',
  'ܥܦܝܪܐ',
  'ܫܓܝܡܐ',
  'ܡܚܠܬܐ',
  'ܕܘܗܘܢ',
  'ܡܬܩܬܩ',
  'ܕܘܡܝܐ',
  'ܫܘܗܝܐ',
  'ܒܘܟܪܐ',
  'ܓܠܕܢܐ',
  'ܗܩܝܢܐ',
  'ܡܪܥܢܐ',
  'ܚܣܝܢܐ',
  'ܓܡܝܪܐ',
  'ܩܝܒܪܐ',
  'ܩܪܝܬܐ',
  'ܡܛܘܝܐ',
  'ܢܐܪܒܐ',
  'ܣܘܬܬܐ',
  'ܐܡܝܬܐ',
  'ܓܘܢܬܐ',
  'ܚܘܣܢܐ',
  'ܕܠܘܚܐ',
  'ܪܘܩܬܐ',
  'ܦܨܘܠܐ',
  'ܢܚܫܝܐ',
  'ܬܢܝܬܐ',
  'ܟܢܢܬܐ',
  'ܒܪܩܝܐ',
  'ܒܪܩܩܐ',
  'ܓܫܫܬܐ',
  'ܛܢܘܛܐ',
  'ܨܠܝܬܐ',
  'ܡܘܬܒܐ',
  'ܫܢܢܬܐ',
  'ܡܪܝܬܐ',
  'ܣܪܓܘܢ',
  'ܥܝܒܢܐ',
  'ܡܩܪܢܐ',
  'ܦܪܝܢܐ',
  'ܚܪܨܢܐ',
  'ܚܪܓܠܐ',
  'ܬܦܬܢܐ',
  'ܡܩܝܡܐ',
  'ܫܪܩܬܐ',
  'ܣܡܠܬܐ',
  'ܓܠܝܢܐ',
  'ܓܒܘܝܐ',
  'ܒܪܫܡܐ',
  'ܪܥܠܬܐ',
  'ܦܘܬܬܐ',
  'ܢܐܡܪܕ',
  'ܒܝܒܝܠ',
  'ܢܟܘܝܐ',
  'ܢܘܘܨܐ',
  'ܥܢܐܝܠ',
  'ܒܝܫܪܬ',
  'ܢܫܘܒܐ',
  'ܗܪܐܝܠ',
  'ܚܬܝܪܐ',
  'ܕܪܘܝܐ',
  'ܟܐܝܒܐ',
  'ܡܘܫܡܪ',
  'ܚܠܝܢܐ',
  'ܡܥܩܘܠ',
  'ܡܕܒܩܐ',
  'ܪܦܝܬܐ',
  'ܒܩܪܝܐ',
  'ܫܚܘܩܐ',
  'ܣܘܠܬܢ',
  'ܠܘܓܡܐ',
  'ܣܦܘܘܐ',
  'ܡܚܒܬܐ',
  'ܢܦܩܬܐ',
  'ܕܒܘܩܐ',
  'ܦܪܦܝܫ',
  'ܠܘܟܘܢ',
  'ܙܘܚܦܐ',
  'ܚܘܝܓܐ',
  'ܫܓܘܡܐ',
  'ܩܘܕܠܐ',
  'ܥܡܐܥܡ',
  'ܒܘܪܬܐ',
  'ܐܝܪܬܐ',
  'ܩܝܛܘܢ',
  'ܣܪܘܚܐ',
  'ܩܨܪܬܐ',
  'ܟܘܪܢܐ',
  'ܗܕܝܢܐ',
  'ܡܬܢܕܠ',
  'ܚܢܘܝܐ',
  'ܚܐܘܬܐ',
  'ܡܡܘܚܐ',
  'ܚܬܝܬܐ',
  'ܥܠܘܬܐ',
  'ܝܠܘܕܐ',
  'ܙܚܝܪܐ',
  'ܐܣܬܝܪ',
  'ܬܡܘܙܐ',
  'ܠܒܘܠܐ',
  'ܗܝܗܘܝ',
  'ܩܠܢܝܐ',
  'ܐܪܥܢܐ',
  'ܟܟܪܢܐ',
  'ܛܒܘܒܐ',
  'ܐܚܝܩܪ',
  'ܪܚܒܘܬ',
  'ܩܕܪܠܐ',
  'ܓܘܘܛܐ',
  'ܩܘܦܚܐ',
  'ܕܘܪܩܐ',
  'ܐܫܟܦܐ',
  'ܡܘܩܝܕ',
  'ܨܘܚܒܐ',
  'ܨܦܘܚܐ',
  'ܡܛܦܬܐ',
  'ܬܘܢܘܣ',
  'ܐܫܥܝܐ',
  'ܚܙܘܦܐ',
  'ܦܘܠܛܐ',
  'ܥܛܪܘܬ',
  'ܫܪܟܬܐ',
  'ܛܘܠܥܐ',
  'ܫܩܝܬܐ',
  'ܟܪܣܝܐ',
  'ܢܘܩܡܐ',
  'ܥܓܘܠܐ',
  'ܨܡܝܕܐ',
  'ܡܠܘܠܐ',
  'ܢܘܗܪܐ',
  'ܫܒܠܬܐ',
  'ܡܢܣܝܐ',
  'ܕܘܪܓܐ',
  'ܒܪܟܡܢ',
  'ܩܫܛܝܐ',
  'ܣܒܗܝܐ',
  'ܚܪܘܩܐ',
  'ܫܘܦܢܐ',
  'ܣܝܓܬܐ',
  'ܢܙܘܠܐ',
  'ܗܕܡܬܐ',
  'ܩܡܦܘܢ',
  'ܡܫܪܫܐ',
  'ܓܘܘܢܐ',
  'ܝܘܪܟܐ',
  'ܪܒܐܝܬ',
  'ܗܪܝܣܐ',
  'ܡܥܠܬܐ',
  'ܓܕܘܕܐ',
  'ܦܘܩܕܐ',
  'ܣܪܝܣܐ',
  'ܒܕܩܬܐ',
  'ܡܗܪܒܩ',
  'ܝܕܝܬܐ',
  'ܒܘܗܬܢ',
  'ܦܨܘܝܐ',
  'ܚܨܘܕܐ',
  'ܡܬܪܬܪ',
  'ܐܠܠܡܐ',
  'ܒܚܝܪܐ',
  'ܡܣܡܢܐ',
  'ܫܝܫܠܐ',
  'ܫܠܝܝܐ',
  'ܩܘܦܛܐ',
  'ܬܪܫܝܫ',
  'ܦܠܛܬܐ',
  'ܗܘܦܝܐ',
  'ܡܟܦܬܐ',
  'ܫܘܘܪܐ',
  'ܕܪܕܪܐ',
  'ܚܬܡܢܐ',
  'ܕܘܒܚܐ',
  'ܓܘܝܒܐ',
  'ܓܒܝܪܐ',
  'ܡܕܪܝܐ',
  'ܫܩܘܠܐ',
  'ܩܢܙܬܐ',
  'ܣܗܪܟܪ',
  'ܐܒܕܝܢ',
  'ܐܦܘܬܐ',
  'ܝܛܥܢܐ',
  'ܡܫܚܠܦ',
  'ܕܘܢܩܐ',
  'ܡܚܫܘܫ',
  'ܥܕܪܝܐ',
  'ܣܡܡܢܐ',
  'ܝܠܕܟܐ',
  'ܚܡܪܢܐ',
  'ܢܚܫܬܐ',
  'ܡܩܫܩܫ',
  'ܗܓܓܝܐ',
  'ܩܠܝܒܐ',
  'ܣܢܝܩܐ',
  'ܚܠܘܘܐ',
  'ܕܝܢܢܐ',
  'ܩܢܩܠܐ',
  'ܢܩܒܝܐ',
  'ܙܒܢܬܐ',
  'ܟܡܬܪܐ',
  'ܕܘܡܪܐ',
  'ܪܘܦܬܐ',
  'ܙܗܡܢܐ',
  'ܥܘܛܦܐ',
  'ܡܣܟܢܐ',
  'ܩܘܦܣܐ',
  'ܐܢܘܣܐ',
  'ܟܬܘܢܐ',
  'ܓܪܘܠܐ',
  'ܨܥܪܢܐ',
  'ܕܘܟܬܐ',
  'ܣܝܘܟܐ',
  'ܓܘܢܝܐ',
  'ܒܬܒܢܐ',
  'ܩܘܛܪܐ',
  'ܕܥܝܪܐ',
  'ܐܪܡܝܐ',
  'ܣܚܬܝܢ',
  'ܠܘܣܬܐ',
  'ܫܡܟܢܐ',
  'ܦܣܝܢܐ',
  'ܢܘܝܠܐ',
  'ܩܪܘܝܐ',
  'ܠܛܘܫܐ',
  'ܕܪܝܕܐ',
  'ܓܒܝܢܐ',
  'ܫܠܡܬܐ',
  'ܫܘܠܩܐ',
  'ܦܬܠܬܐ',
  'ܣܘܓܘܠ',
  'ܚܘܪܦܐ',
  'ܟܣܟܪܐ',
  'ܕܪܗܡܬ',
  'ܨܘܨܝܐ',
  'ܝܪܩܝܐ',
  'ܛܘܠܥܐ',
  'ܥܪܦܠܐ',
  'ܬܝܪܟܫ',
  'ܨܠܝܚܐ',
  'ܩܨܡܬܐ',
  'ܩܛܘܦܐ',
  'ܕܘܚܩܐ',
  'ܫܪܝܪܐ',
  'ܦܘܓܚܐ',
  'ܝܣܘܪܐ',
  'ܚܪܫܬܐ',
  'ܐܫܝܡܐ',
  'ܣܒܓܘܠ',
  'ܥܘܬܪܐ',
  'ܚܝܪܬܘ',
  'ܩܘܠܫܐ',
  'ܟܕܘܢܐ',
  'ܢܝܢܘܐ',
  'ܙܘܡܢܐ',
  'ܬܪܝܢܐ',
  'ܢܘܫܪܐ',
  'ܒܘܫܠܐ',
  'ܠܗܝܬܐ',
  'ܐܦܣܪܐ',
  'ܢܐܗܠܠ',
  'ܐܓܘܓܐ',
  'ܟܢܝܢܐ',
  'ܚܐܦܬܐ',
  'ܬܒܪܙܐ',
  'ܨܝܘܒܐ',
  'ܟܘܝܙܐ',
  'ܗܪܡܙܐ',
  'ܣܝܥܬܐ',
  'ܓܘܠܢܐ',
  'ܦܝܒܢܕ',
  'ܡܥܪܩܐ',
  'ܨܦܝܬܐ',
  'ܦܢܬܘܪ',
  'ܝܘܐܢܐ',
  'ܣܚܠܬܐ',
  'ܡܫܚܒܪ',
  'ܒܢܝܣܢ',
  'ܪܥܘܠܐ',
  'ܡܪܙܝܐ',
  'ܨܥܘܪܐ',
  'ܣܘܦܝܐ',
  'ܡܣܪܣܐ',
  'ܒܓܘܠܐ',
  'ܐܣܘܢܐ',
  'ܚܙܐܠܝ',
  'ܒܪܘܢܐ',
  'ܙܘܒܢܐ',
  'ܕܘܡܝܐ',
  'ܡܗܝܡܢ',
  'ܩܘܠܝܐ',
  'ܢܩܘܣܐ',
  'ܩܢܝܢܐ',
  'ܡܓܝܫܢ',
  'ܩܘܝܬܐ',
  'ܒܕܘܘܐ',
  'ܚܘܣܪܬ',
  'ܥܓܪܬܐ',
  'ܠܐܝܬܐ',
  'ܛܘܡܣܐ',
  'ܕܪܘܣܬ',
  'ܣܪܒܬܐ',
  'ܡܐܢܝܐ',
  'ܣܝܘܢܐ',
  'ܡܫܛܚܐ',
  'ܟܢܘܫܐ',
  'ܦܘܠܓܐ',
  'ܦܢܣܝܠ',
  'ܫܠܝܚܐ',
  'ܒܘܠܘܪ',
  'ܚܪܘܩܐ',
  'ܐܠܗܝܐ',
  'ܬܘܠܠܐ',
  'ܟܘܬܪܐ',
  'ܚܢܘܬܐ',
  'ܡܣܬܬܐ',
  'ܥܣܘܣܐ',
  'ܩܛܘܪܐ',
  'ܒܘܠܘܛ',
  'ܠܛܝܫܐ',
  'ܐܦܝܘܢ',
  'ܥܩܒܬܐ',
  'ܓܪܣܝܐ',
  'ܡܝܘܡܐ',
  'ܕܚܝܩܐ',
  'ܕܒܪܢܐ',
  'ܥܙܠܬܐ',
  'ܡܚܠܕܐ',
  'ܒܗܝܬܐ',
  'ܫܦܟܬܐ',
  'ܚܙܝܬܐ',
  'ܐܓܝܬܐ',
  'ܡܢܩܘܫ',
  'ܡܒܙܥܐ',
  'ܪܗܘܒܐ',
  'ܢܙܝܠܐ',
  'ܡܛܢܓܪ',
  'ܣܦܝܦܐ',
  'ܒܢܝܩܐ',
  'ܚܒܘܪܐ',
  'ܐܘܦܩܐ',
  'ܡܫܩܝܐ',
  'ܓܗܝܠܐ',
  'ܒܙܚܪܐ',
  'ܢܩܪܬܐ',
  'ܦܪܙܠܐ',
  'ܕܝܘܬܐ',
  'ܩܦܝܬܐ',
  'ܒܟܬܬܐ',
  'ܟܡܘܢܐ',
  'ܓܘܪܣܐ',
  'ܡܩܪܛܐ',
  'ܡܘܥܕܐ',
  'ܢܩܘܦܐ',
  'ܩܥܠܝܢ',
  'ܗܓܝܬܐ',
  'ܓܘܠܠܐ',
  'ܨܡܨܥܐ',
  'ܡܚܠܠܐ',
  'ܬܩܝܢܐ',
  'ܥܡܛܢܐ',
  'ܩܘܠܝܝ',
  'ܓܙܘܡܐ',
  'ܟܝܦܬܐ',
  'ܩܡܝܢܐ',
  'ܡܣܘܡܐ',
  'ܚܪܡܢܐ',
  'ܒܢܝܬܐ',
  'ܩܪܝܡܐ',
  'ܕܘܢܝܐ',
  'ܡܪܙܒܐ',
  'ܨܕܝܩܐ',
  'ܡܬܠܚܨ',
  'ܒܠܘܠܐ',
  'ܬܦܩܬܐ',
  'ܫܠܘܚܐ',
  'ܪܘܩܒܐ',
  'ܗܢܝܬܐ',
  'ܢܝܪܝܓ',
  'ܦܘܪܘܣ',
  'ܥܡܝܪܐ',
  'ܩܝܢܝܐ',
  'ܗܘܣܠܐ',
  'ܛܚܘܢܐ',
  'ܩܕܡܬܐ',
  'ܡܛܟܣܐ',
  'ܩܕܡܝܐ',
  'ܡܥܠܘܡ',
  'ܩܕܘܡܐ',
  'ܚܨܪܬܐ',
  'ܡܘܪܡܐ',
  'ܬܠܘܩܐ',
  'ܢܘܨܚܐ',
  'ܢܘܘܓܐ',
  'ܡܬܒܬܐ',
  'ܦܓܘܥܐ',
  'ܟܘܠܒܐ',
  'ܦܬܓܡܐ',
  'ܬܠܝܣܐ',
  'ܥܛܪܢܐ',
  'ܡܨܚܨܐ',
  'ܡܘܫܚܐ',
  'ܗܦܬܝܪ',
  'ܒܣܬܪܐ',
  'ܥܘܡܪܐ',
  'ܢܛܦܬܐ',
  'ܚܝܕܢܐ',
  'ܒܙܪܓܢ',
  'ܡܓܘܪܪ',
  'ܡܬܪܓܙ',
  'ܦܪܥܝܐ',
  'ܣܘܢܢܝ',
  'ܪܘܚܬܐ',
  'ܗܦܟܬܐ',
  'ܒܥܝܟܐ',
  'ܒܘܪܟܐ',
  'ܒܣܘܪܐ',
  'ܥܘܪܩܐ',
  'ܙܘܩܢܐ',
  'ܚܩܘܪܐ',
  'ܕܡܢܬܐ',
  'ܦܪܙܝܐ',
  'ܐܓܝܪܐ',
  'ܥܘܬܝܐ',
  'ܐܬܫܥܝ',
  'ܬܘܪܡܐ',
  'ܒܩܘܪܐ',
  'ܐܓܪܬܐ',
  'ܦܕܓܪܐ',
  'ܟܪܡܕܪ',
  'ܩܘܕܡܬ',
  'ܬܙܘܬܐ',
  'ܚܪܘܓܐ',
  'ܙܪܘܙܐ',
  'ܫܒܘܚܐ',
  'ܥܡܘܕܐ',
  'ܢܡܪܘܕ',
  'ܙܓܓܬܐ',
  'ܣܪܘܢܕ',
  'ܛܓܘܢܐ',
  'ܫܐܘܠܐ',
  'ܩܩܢܘܣ',
  'ܒܘܛܬܐ',
  'ܣܘܕܝܐ',
  'ܥܫܩܒܙ',
  'ܚܬܘܬܐ',
  'ܬܒܫܬܐ',
  'ܒܠܘܬܐ',
  'ܥܡܪܬܐ',
  'ܟܢܕܠܢ',
  'ܩܘܛܢܐ',
  'ܡܪܝܪܐ',
  'ܠܘܝܛܐ',
  'ܡܟܘܟܐ',
  'ܐܗܠܝܡ',
  'ܬܥܪܝܦ',
  'ܡܣܪܬܐ',
  'ܡܠܝܚܐ',
  'ܫܡܫܬܐ',
  'ܫܫܡܨܪ',
  'ܓܚܘܬܐ',
  'ܟܬܘܒܐ',
  'ܥܘܨܪܐ',
  'ܝܪܢܬܐ',
  'ܙܕܝܩܐ',
  'ܡܩܠܝܐ',
  'ܒܪܐܒܪ',
  'ܫܘܪܬܐ',
  'ܡܨܚܢܐ',
  'ܣܡܝܡܐ',
  'ܛܦܘܝܐ',
  'ܡܓܡܦܪ',
  'ܩܪܣܬܐ',
  'ܡܛܘܪܐ',
  'ܒܠܘܥܐ',
  'ܡܪܘܬܐ',
  'ܦܠܐܩܐ',
  'ܩܠܘܝܐ',
  'ܙܥܘܦܐ',
  'ܩܘܢܓܐ',
  'ܬܣܟܠܘ',
  'ܚܘܠܕܗ',
  'ܦܪܡܬܐ',
  'ܦܕܘܥܐ',
  'ܡܣܝܕܐ',
  'ܥܕܘܠܐ',
  'ܣܘܪܕܐ',
  'ܐܨܠܝܐ',
  'ܨܠܝܒܐ',
  'ܡܚܘܝܐ',
  'ܡܙܓܢܐ',
  'ܣܦܝܢܐ',
  'ܨܝܘܡܐ',
  'ܝܨܝܠܐ',
  'ܓܠܘܢܐ',
  'ܕܘܒܬܐ',
  'ܡܨܥܙܐ',
  'ܫܘܓܒܐ',
  'ܒܙܘܥܐ',
  'ܠܫܢܝܐ',
  'ܙܓܘܓܐ',
  'ܚܐܘܪܐ',
  'ܩܪܢܬܘ',
  'ܗܡܢܢܐ',
  'ܚܛܝܬܐ',
  'ܕܪܕܪܐ',
  'ܛܛܝܢܐ',
  'ܣܘܪܛܐ',
  'ܡܪܚܪܚ',
  'ܐܘܠܕܐ',
  'ܦܫܡܬܐ',
  'ܪܘܚܢܝ',
  'ܚܢܩܢܐ',
  'ܪܗܒܢܐ',
  'ܡܬܢܬܙ',
  'ܬܦܚܬܐ',
  'ܚܘܠܕܐ',
  'ܒܙܘܚܐ',
  'ܡܝܘܬܐ',
  'ܡܛܥܡܢ',
  'ܦܬܪܬܐ',
  'ܒܐܩܠܐ',
  'ܡܥܦܪܐ',
  'ܣܠܓܢܐ',
  'ܛܝܪܬܐ',
  'ܝܘܩܢܐ',
  'ܩܘܢܓܐ',
  'ܪܛܝܒܐ',
  'ܗܕܘܪܐ',
  'ܫܥܘܬܐ',
  'ܗܢܕܝܐ',
  'ܓܢܝܙܐ',
  'ܫܝܚܘܪ',
  'ܕܐܠܦܐ',
  'ܙܘܕܩܐ',
  'ܫܟܘܠܬ',
  'ܡܩܘܡܐ',
  'ܓܦܝܬܐ',
  'ܣܦܘܕܐ',
  'ܡܘܣܝܐ',
  'ܓܘܪܪܐ',
  'ܒܘܗܬܢ',
  'ܡܫܠܬܐ',
  'ܡܛܘܬܐ',
  'ܥܘܡܨܐ',
  'ܫܩܝܬܐ',
  'ܛܥܡܬܐ',
  'ܡܘܚܕܐ',
  'ܫܡܘܫܐ',
  'ܬܢܝܬܐ',
  'ܡܣܪܣܡ',
  'ܚܒܝܨܐ',
  'ܒܫܝܠܐ',
  'ܩܢܩܢܐ',
  'ܗܪܘܠܐ',
  'ܨܘܠܡܐ',
  'ܢܓܣܬܢ',
  'ܩܠܡܩܠ',
  'ܨܘܝܬܐ',
  'ܐܪܒܝܐ',
  'ܦܪܛܢܐ',
  'ܘܠܐܝܬ',
  'ܓܪܕܘܠ',
  'ܕܘܒܚܐ',
  'ܢܝܚܬܐ',
  'ܓܘܕܡܐ',
  'ܓܫܝܬܐ',
  'ܟܘܠܦܐ',
  'ܫܘܕܪܐ',
  'ܡܒܗܪܢ',
  'ܕܘܘܕܐ',
  'ܝܨܘܦܐ',
  'ܐܕܪܢܐ',
  'ܣܦܣܪܐ',
  'ܨܐܡܬܐ',
  'ܬܠܝܣܐ',
  'ܩܪܝܨܐ',
  'ܓܠܝܪܐ',
  'ܙܟܘܬܐ',
  'ܓܠܛܝܐ',
  'ܩܫܝܛܐ',
  'ܢܩܡܬܐ',
  'ܐܒܘܒܐ',
  'ܕܐܪܢܐ',
  'ܨܘܟܬܐ',
  'ܐܠܝܬܐ',
  'ܚܘܡܨܐ',
  'ܒܡܢܬܐ',
  'ܡܬܢܬܣ',
  'ܒܘܒܓܐ',
  'ܝܬܪܥܡ',
  'ܦܥܘܪܐ',
  'ܢܙܘܢܐ',
  'ܥܘܩܕܐ',
  'ܦܠܫܬܐ',
  'ܩܘܠܘܢ',
  'ܡܥܝܒܢ',
  'ܐܢܝܗܐ',
  'ܕܝܩܬܐ',
  'ܡܝܡܢܐ',
  'ܡܚܒܟܐ',
  'ܨܡܒܪܐ',
  'ܦܘܣܪܐ',
  'ܨܘܠܬܐ',
  'ܩܛܚܬܐ',
  'ܕܥܣܪܐ',
  'ܫܝܘܚܐ',
  'ܟܢܫܝܐ',
  'ܗܓܪܝܐ',
  'ܡܫܩܪܩ',
  'ܘܠܘܠܐ',
  'ܠܘܠܒܐ',
  'ܢܘܦܪܐ',
  'ܡܒܪܒܙ',
  'ܩܠܝܬܐ',
  'ܐܚܕܕܐ',
  'ܓܘܓܝܐ',
  'ܒܝܘܪܐ',
  'ܡܫܟܚܐ',
  'ܡܠܒܢܐ',
  'ܡܣܓܦܐ',
  'ܝܘܒܡܐ',
  'ܨܕܩܝܐ',
  'ܟܬܒܬܐ',
  'ܝܒܝܫܐ',
  'ܡܦܩܢܐ',
  'ܐܡܙܘܓ',
  'ܒܝܬܘܢ',
  'ܝܡܘܨܐ',
  'ܛܘܝܠܐ',
  'ܓܣܪܬܐ',
  'ܥܘܪܛܐ',
  'ܒܫܪܬܐ',
  'ܟܘܢܝܐ',
  'ܣܘܡܒܬ',
  'ܦܠܝܛܐ',
  'ܡܕܢܚܐ',
  'ܡܪܟܒܐ',
  'ܒܥܝܬܐ',
  'ܡܪܝܕܐ',
  'ܩܠܘܦܐ',
  'ܬܪܘܥܐ',
  'ܚܛܝܡܐ',
  'ܢܘܢܬܐ',
  'ܡܒܥܕܐ',
  'ܝܘܕܥܐ',
  'ܟܣܘܬܐ',
  'ܟܘܪܪܐ',
  'ܫܘܠܘܪ',
  'ܐܠܘܨܐ',
  'ܡܬܚܡܠ',
  'ܪܗܛܬܐ',
  'ܣܘܝܛܐ',
  'ܦܘܕܪܬ',
  'ܠܝܡܘܢ',
  'ܡܥܕܪܐ',
  'ܡܠܝܬܐ',
  'ܚܘܪܕܐ',
  'ܙܩܪܬܐ',
  'ܬܩܝܢܐ',
  'ܦܘܟܬܐ',
  'ܦܪܙܝܢ',
  'ܬܝܟܬܐ',
  'ܕܒܝܬܐ',
  'ܓܕܝܡܐ',
  'ܫܢܘܕܐ',
  'ܝܝܪܝܫ',
  'ܕܝܬܩܣ',
  'ܫܪܘܝܐ',
  'ܣܢܪܩܐ',
  'ܩܗܠܢܐ',
  'ܫܦܘܥܐ',
  'ܡܨܪܦܐ',
  'ܟܫܟܘܠ',
  'ܡܠܬܝܐ',
  'ܛܪܝܦܐ',
  'ܫܡܫܝܐ',
  'ܬܦܐܘܬ',
  'ܦܐܟܘܢ',
  'ܨܦܪܝܐ',
  'ܠܥܣܬܐ',
  'ܡܘܪܝܐ',
  'ܥܝܘܢܐ',
  'ܒܣܕܝܐ',
  'ܡܟܪܟܛ',
  'ܕܪܥܝܐ',
  'ܡܘܙܓܐ',
  'ܦܡܦܠܐ',
  'ܡܚܨܘܨ',
  'ܡܕܪܟܐ',
  'ܡܚܒܠܢ',
  'ܒܘܠܓܪ',
  'ܦܘܫܬܝ',
  'ܓܘܠܝܐ',
  'ܩܘܪܨܐ',
  'ܨܗܝܘܢ',
  'ܪܟܫܬܐ',
  'ܡܪܓܢܐ',
  'ܨܕܘܘܐ',
  'ܕܝܠܝܐ',
  'ܐܠܗܢܐ',
  'ܫܘܫܦܐ',
  'ܩܠܘܝܐ',
  'ܐܡܗܝܐ',
  'ܡܥܠܝܐ',
  'ܡܬܢܬܐ',
  'ܡܡܘܠܐ',
  'ܪܘܡܓܐ',
  'ܙܪܘܩܐ',
  'ܝܪܚܝܐ',
  'ܡܓܕܠܐ',
  'ܦܦܠܓܐ',
  'ܡܛܪܛܡ',
  'ܩܕܝܡܝ',
  'ܛܒܢܝܐ',
  'ܓܒܪܟܐ',
  'ܩܘܫܝܐ',
  'ܣܪܢܬܐ',
  'ܢܦܠܢܐ',
  'ܡܚܕܝܐ',
  'ܕܩܝܩܐ',
  'ܨܚܢܬܐ',
  'ܙܠܝܡܐ',
  'ܬܠܘܝܐ',
  'ܩܒܠܬܐ',
  'ܠܘܩܒܠ',
  'ܫܝܡܬܐ',
  'ܫܝܫܬܐ',
  'ܛܐܠܬܐ',
  'ܢܐܒܘܕ',
  'ܫܘܢܢܐ',
  'ܫܘܢܕܐ',
  'ܫܬܡܐܐ',
  'ܡܘܩܒܠ',
  'ܛܥܘܢܐ',
  'ܟܘܬܪܐ',
  'ܬܢܓܘܝ',
  'ܡܙܩܦܐ',
  'ܚܠܝܦܐ',
  'ܥܪܘܪܐ',
  'ܙܝܐܪܬ',
  'ܚܒܘܫܐ',
  'ܐܘܪܚܐ',
  'ܫܘܗܪܬ',
  'ܣܝܠܘܢ',
  'ܐܡܙܘܟ',
  'ܨܗܝܬܐ',
  'ܚܣܘܡܐ',
  'ܫܓܫܢܐ',
  'ܓܪܕܢܐ',
  'ܦܝܠܝܐ',
  'ܗܕܪܬܐ',
  'ܐܝܠܠܐ',
  'ܫܘܦܝܐ',
  'ܒܪܝܬܐ',
  'ܓܒܝܬܐ',
  'ܐܪܟܬܟ',
  'ܐܝܦܕܐ',
  'ܡܫܟܝܐ',
  'ܥܒܐܕܬ',
  'ܝܨܪܢܐ',
  'ܓܪܓܫܐ',
  'ܡܡܘܨܐ',
  'ܟܗܢܝܐ',
  'ܓܙܒܪܐ',
  'ܢܣܝܒܐ',
  'ܟܒܘܣܐ',
  'ܡܬܢܬܐ',
  'ܩܘܒܘܙ',
  'ܬܙܝܢܐ',
  'ܡܙܓܬܐ',
  'ܬܚܡܝܢ',
  'ܦܘܓܕܐ',
  'ܛܘܠܫܐ',
  'ܓܝܘܪܐ',
  'ܪܦܘܦܐ',
  'ܦܢܕܢܐ',
  'ܩܦܢܕܪ',
  'ܚܘܪܫܐ',
  'ܫܦܘܠܐ',
  'ܚܪܘܫܐ',
  'ܦܚܡܬܐ',
  'ܙܘܪܬܐ',
  'ܚܢܝܩܐ',
  'ܢܚܘܡܐ',
  'ܡܠܩܛܐ',
  'ܩܪܘܘܐ',
  'ܦܣܝܩܐ',
  'ܡܨܒܬܐ',
  'ܡܘܠܠܐ',
  'ܡܦܠܝܣ',
  'ܓܫܘܛܐ',
  'ܫܥܪܬܐ',
  'ܣܘܓܐܬ',
  'ܓܠܓܬܐ',
  'ܨܐܝܪܐ',
  'ܒܘܥܩܐ',
  'ܠܚܘܡܐ',
  'ܫܚܝܩܐ',
  'ܡܠܘܐܐ',
  'ܣܪܘܦܐ',
  'ܐܬܢܝܐ',
  'ܝܠܝܬܐ',
  'ܣܟܘܡܐ',
  'ܫܪܝܥܬ',
  'ܡܢܩܢܩ',
  'ܪܬܘܬܐ',
  'ܬܢܝܚܐ',
  'ܥܡܘܘܐ',
  'ܡܓܢܝܐ',
  'ܢܨܝܗܬ',
  'ܡܓܪܥܐ',
  'ܕܝܢܬܐ',
  'ܦܘܫܚܐ',
  'ܫܚܝܪܐ',
  'ܪܩܕܢܐ',
  'ܩܘܪܘܫ',
  'ܫܡܝܠܐ',
  'ܣܚܒܘܪ',
  'ܛܘܓܪܐ',
  'ܦܘܣܘܣ',
  'ܫܬܢܝܐ',
  'ܚܠܥܬܐ',
  'ܫܢܘܓܐ',
  'ܬܥܘܠܐ',
  'ܡܪܢܝܐ',
  'ܢܘܚܬܐ',
  'ܨܢܘܘܐ',
  'ܚܘܪܒܐ',
  'ܟܘܚܝܐ',
  'ܩܘܪܠܐ',
  'ܒܣܪܬܐ',
  'ܚܘܬܡܐ',
  'ܛܥܝܫܐ',
  'ܢܩܫܟܪ',
  'ܩܪܨܬܐ',
  'ܙܠܘܓܐ',
  'ܚܕܥܣܪ',
  'ܬܘܚܬܐ',
  'ܫܘܪܢܐ',
  'ܒܝܬܝܐ',
  'ܩܪܡܒܐ',
  'ܗܝܕܝܢ',
  'ܡܠܟܬܐ',
  'ܠܒܠܬܐ',
  'ܘܪܘܘܣ',
  'ܪܚܘܛܐ',
  'ܡܦܪܬܚ',
  'ܫܘܟܬܐ',
  'ܕܠܦܬܐ',
  'ܚܪܘܣܐ',
  'ܟܣܘܚܐ',
  'ܫܩܠܢܐ',
  'ܦܢܝܬܐ',
  'ܡܢܙܘܠ',
  'ܕܪܘܫܐ',
  'ܩܝܘܕܐ',
  'ܡܩܕܪܐ',
  'ܡܒܝܬܐ',
  'ܩܗܪܡܢ',
  'ܡܛܪܦܫ',
  'ܙܩܘܪܐ',
  'ܛܡܘܐܐ',
  'ܨܠܡܘܢ',
  'ܦܘܙܡܐ',
  'ܚܛܘܛܐ',
  'ܐܟܘܣܐ',
  'ܡܕܘܪܐ',
  'ܪܘܬܝܢ',
  'ܩܛܘܦܐ',
  'ܡܚܒܢܐ',
  'ܦܬܘܦܬ',
  'ܦܬܣܝܣ',
  'ܚܝܘܠܐ',
  'ܬܪܓܬܐ',
  'ܩܦܣܬܐ',
  'ܢܟܘܦܐ',
  'ܗܪܘܬܐ',
  'ܒܪܘܚܐ',
  'ܢܩܝܦܐ',
  'ܐܝܠܬܐ',
  'ܒܙܘܙܐ',
  'ܢܘܪܝܐ',
  'ܫܬܩܢܐ',
  'ܫܡܝܬܐ',
  'ܚܒܝܒܐ',
  'ܛܝܘܦܐ',
  'ܠܩܝܕܐ',
  'ܥܓܠܘܢ',
  'ܥܫܝܪܬ',
  'ܓܙܝܪܐ',
  'ܢܒܝܬܐ',
  'ܫܕܠܬܐ',
  'ܩܣܛܘܪ',
  'ܩܥܘܝܐ',
  'ܓܒܥܘܢ',
  'ܡܨܢܕܩ',
  'ܟܫܘܪܐ',
  'ܚܪܝܣܐ',
  'ܡܓܠܕܐ',
  'ܡܫܢܫܠ',
  'ܟܪܠܝܝ',
  'ܕܝܢܬܐ',
  'ܙܘܓܢܐ',
  'ܒܪܘܝܐ',
  'ܚܘܪܬܐ',
  'ܠܝܨܬܐ',
  'ܡܩܪܕܫ',
  'ܦܩܝܥܐ',
  'ܬܐܢܬܐ',
  'ܩܠܒܠܓ',
  'ܐܪܒܥܝ',
  'ܡܘܩܕܐ',
  'ܓܘܡܪܐ',
  'ܠܨܛܝܟ',
  'ܗܪܙܡܐ',
  'ܛܒܝܬܐ',
  'ܡܫܪܝܐ',
  'ܣܓܝܕܐ',
  'ܒܥܪܒܪ',
  'ܥܝܪܬܐ',
  'ܩܘܠܒܝ',
  'ܚܝܘܢܐ',
  'ܥܠܡܕܪ',
  'ܡܚܡܠܐ',
  'ܛܚܠܝܐ',
  'ܚܪܫܢܐ',
  'ܪܫܝܠܐ',
  'ܬܦܬܬܐ',
  'ܦܫܪܘܓ',
  'ܩܘܫܝܐ',
  'ܕܫܡܠܐ',
  'ܡܗܪܗܪ',
  'ܦܫܘܩܐ',
  'ܩܪܝܛܐ',
  'ܫܘܚܪܐ',
  'ܢܝܚܬܐ',
  'ܫܘܡܢܐ',
  'ܕܩܢܬܐ',
  'ܢܩܒܢܐ',
  'ܚܦܘܛܐ',
  'ܦܨܠܬܐ',
  'ܙܪܩܬܐ',
  'ܫܢܢܝܐ',
  'ܬܠܝܚܐ',
  'ܫܠܘܛܐ',
  'ܙܠܝܩܐ',
  'ܐܢܩܠܐ',
  'ܓܝܓܠܐ',
  'ܡܙܗܪܐ',
  'ܣܘܒܥܐ',
  'ܢܨܚܢܐ',
  'ܦܫܘܘܐ',
  'ܚܝܡܘܟ',
  'ܡܛܪܦܛ',
  'ܡܩܘܩܐ',
  'ܪܒܝܥܐ',
  'ܦܫܦܫܐ',
  'ܨܘܕܝܐ',
  'ܦܚܡܝܐ',
  'ܐܘܪܪܐ',
  'ܙܘܦܢܓ',
  'ܟܠܝܘܡ',
  'ܩܪܨܢܐ',
  'ܩܪܝܬܐ',
  'ܡܘܩܪܪ',
  'ܒܪܢܒܪ',
  'ܥܘܙܝܐ',
  'ܫܕܘܠܐ',
  'ܨܘܦܝܐ',
  'ܬܡܝܗܐ',
  'ܫܥܘܬܐ',
  'ܡܛܪܬܐ',
  'ܢܛܪܬܐ',
  'ܥܘܢܒܐ',
  'ܬܡܘܡܐ',
  'ܟܗܪܝܙ',
  'ܨܪܘܝܐ',
  'ܕܐܘܠܐ',
  'ܡܩܪܡܚ',
  'ܟܪܘܝܐ',
  'ܫܘܦܪܐ',
  'ܘܪܩܬܐ',
  'ܝܘܪܬܐ',
  'ܡܩܘܡܐ',
  'ܢܦܚܬܐ',
  'ܓܪܘܢܐ',
  'ܡܚܘܪܐ',
  'ܦܐܝܬܐ',
  'ܟܘܦܫܐ',
  'ܐܪܣܢܐ',
  'ܨܘܕܟܪ',
  'ܙܘܩܘܡ',
  'ܟܗܘܢܐ',
  'ܟܠܝܠܐ',
  'ܛܡܘܪܐ',
  'ܡܙܢܗܪ',
  'ܥܪܝܬܐ',
  'ܕܘܗܢܐ',
  'ܫܠܫܘܪ',
  'ܛܙܟܬܐ',
  'ܚܒܘܩܐ',
  'ܥܘܙܙܐ',
  'ܓܫܘܪܐ',
  'ܡܘܩܪܐ',
  'ܒܪܒܘܓ',
  'ܓܙܪܬܐ',
  'ܚܕܪܬܐ',
  'ܬܕܒܝܪ',
  'ܓܥܛܬܐ',
  'ܡܣܘܪܐ',
  'ܫܥܘܬܐ',
  'ܡܛܪܬܐ',
  'ܐܪܣܢܐ',
  'ܚܘܣܟܐ',
  'ܥܨܘܒܐ',
  'ܐܘܝܢܐ',
  'ܡܩܪܡܟ',
  'ܟܪܘܬܐ',
  'ܝܟܢܬܐ',
  'ܫܘܦܦܐ',
  'ܐܝܢܝܐ',
  'ܓܘܪܬܐ',
  'ܓܫܪܬܐ',
  'ܬܝܦܢܓ',
  'ܡܛܡܐܢ',
  'ܫܐܘܠܐ',
  'ܓܢܝܙܐ',
  'ܝܪܩܢܐ',
  'ܣܪܝܚܐ',
  'ܡܫܦܢܐ',
  'ܦܘܪܟܐ',
  'ܩܐܡܝܐ',
  'ܚܘܦܢܐ',
  'ܩܪܩܘܠ',
  'ܩܒܝܬܐ',
  'ܪܘܟܟܐ',
  'ܡܗܪܟܫ',
  'ܦܫܘܪܐ',
  'ܘܫܪܟܐ',
  'ܒܚܠܢܐ',
  'ܬܪܬܝܒ',
  'ܩܪܝܚܐ',
  'ܚܦܘܦܐ',
  'ܕܩܢܣܐ',
  'ܡܦܥܘܪ',
  'ܦܕܝܬܐ',
  'ܦܨܠܢܐ',
  'ܣܝܘܪܐ',
  'ܪܒܘܝܐ',
  'ܟܪܒܣܐ',
  'ܒܓܘܠܐ',
  'ܝܩܝܢܐ',
  'ܢܬܘܦܐ',
  'ܬܘܗܬܐ',
  'ܕܘܥܬܐ',
  'ܡܦܪܓܐ',
  'ܥܪܝܝܐ',
  'ܨܨܢܝܐ',
  'ܢܓܕܝܐ',
  'ܡܟܫܟܫ',
  'ܚܫܠܬܐ',
  'ܛܠܡܐܐ',
  'ܬܢܢܝܐ',
  'ܗܒܒܝܐ',
  'ܥܓܠܬܐ',
  'ܥܫܝܬܐ',
  'ܠܩܝܫܐ',
  'ܙܪܥܢܐ',
  'ܟܦܝܠܐ',
  'ܣܓܘܠܐ',
  'ܫܘܬܬܐ',
  'ܡܒܕܩܐ',
  'ܣܛܘܢܐ',
  'ܦܘܫܟܐ',
  'ܟܝܢܝܐ',
  'ܕܪܘܫܐ',
  'ܫܕܠܢܐ',
  'ܩܣܛܪܐ',
  'ܢܨܝܢܐ',
  'ܒܪܓܕܐ',
  'ܒܟܝܪܐ',
  'ܪܒܒܬܐ',
  'ܟܪܒܠܐ',
  'ܣܚܝܦܐ',
  'ܝܘܠܝܐ',
  'ܡܩܪܕܚ',
  'ܒܪܘܙܐ',
  'ܙܘܓܬܐ',
  'ܠܝܘܬܐ',
  'ܐܠܘܨܐ',
  'ܥܒܘܛܐ',
  'ܬܝܒܬܐ',
  'ܡܘܓܒܐ',
  'ܟܘܙܙܐ',
  'ܬܐܟܬܐ',
  'ܠܦܬܩܐ',
  'ܫܪܫܘܪ',
  'ܢܝܘܨܐ',
  'ܠܓܘܡܐ',
  'ܫܢܝܢܐ',
  'ܟܪܢܒܐ',
  'ܩܪܓܘܠ',
  'ܗܕܪܢܐ',
  'ܐܠܝܠܐ',
  'ܡܠܟܝܐ',
  'ܠܒܠܢܐ',
  'ܚܪܘܥܐ',
  'ܨܥܪܢܐ',
  'ܪܚܘܡܐ',
  'ܒܘܩܪܐ',
  'ܐܢܓܠܣ',
  'ܠܚܘܡܐ',
  'ܩܝܕܬܐ',
  'ܗܘܫܬܐ',
  'ܦܪܘܓܐ',
  'ܙܩܘܪܐ',
  'ܡܚܪܒܫ',
  'ܬܙܟܬܐ',
  'ܫܝܘܪܐ',
  'ܬܚܠܝܦ',
  'ܡܕܘܪܐ',
  'ܝܠܘܝܐ',
  'ܚܙܝܪܢ',
  'ܩܛܘܬܐ',
  'ܓܠܘܚܐ',
  'ܩܝܘܕܐ',
  'ܦܓܘܚܐ',
  'ܙܝܘܪܐ',
  'ܥܫܘܢܐ',
  'ܦܬܣܩܐ',
  'ܓܫܘܡܐ',
  'ܣܪܘܫܐ',
  'ܬܪܟܬܐ',
  'ܡܝܨܢܐ',
  'ܢܟܘܠܐ',
  'ܦܘܠܛܐ',
  'ܫܝܦܘܪ',
  'ܛܒܝܒܐ',
  'ܣܘܢܒܕ',
  'ܡܦܪܦܟ',
  'ܓܢܝܬܐ',
  'ܢܨܝܚܐ',
  'ܡܕܟܝܐ',
  'ܙܘܕܕܐ',
  'ܩܣܡܬܐ',
  'ܨܢܕܪܐ',
  'ܒܛܠܬܐ',
  'ܦܘܫܕܐ',
  'ܢܒܚܢܐ',
  'ܣܛܪܦܐ',
  'ܣܗܡܝܐ',
  'ܐܘܣܦܐ',
  'ܢܦܘܪܐ',
  'ܠܩܝܬܐ',
  'ܢܘܪܬܐ',
  'ܡܣܬܢܐ',
  'ܢܚܘܦܐ',
  'ܫܡܝܢܐ',
  'ܚܪܒܬܐ',
  'ܛܦܘܢܐ',
  'ܒܨܘܪܐ',
  'ܣܩܪܘܣ',
  'ܛܥܝܬܐ',
  'ܚܠܥܬܐ',
  'ܗܪܓܠܐ',
  'ܩܘܪܡܐ',
  'ܟܘܚܠܐ',
  'ܒܣܝܪܐ',
  'ܡܫܟܫܟ',
  'ܚܠܢܬܐ',
  'ܒܪܘܢܐ',
  'ܢܩܫܝܐ',
  'ܫܘܪܢܐ',
  'ܬܣܟܪܐ',
  'ܝܠܘܕܐ',
  'ܫܚܘܛܐ',
  'ܦܘܚܢܐ',
  'ܓܥܘܛܐ',
  'ܙܒܘܪܐ',
  'ܛܡܪܬܐ',
  'ܙܩܪܢܐ',
  'ܛܘܠܪܐ',
  'ܗܝܙܪܐ',
  'ܚܨܕܬܐ',
  'ܦܘܡܢܐ',
  'ܢܚܡܝܐ',
  'ܓܝܘܪܐ',
  'ܐܪܥܝܐ',
  'ܟܒܝܕܐ',
  'ܦܢܕܡܐ',
  'ܟܝܦܝܐ',
  'ܡܕܫܡܢ',
  'ܡܦܪܫܢ',
  'ܬܦܠܬܐ',
  'ܚܪܘܫܐ',
  'ܢܚܘܡܐ',
  'ܚܢܝܩܐ',
  'ܡܠܦܬܐ',
  'ܩܪܘܝܐ',
  'ܐܟܠܢܐ',
  'ܚܪܝܓܐ',
  'ܛܫܝܬܐ',
  'ܪܓܫܬܐ',
  'ܝܒܡܬܐ',
  'ܨܢܪܬܐ',
  'ܩܘܒܬܐ',
  'ܚܘܨܦܐ',
  'ܚܝܪܬܐ',
  'ܐܬܢܘܣ',
  'ܫܪܝܢܐ',
  'ܩܘܢܒܐ',
  'ܚܠܘܝܐ',
  'ܓܪܣܓܐ',
  'ܡܣܐܬܐ',
  'ܬܢܓܐܝ',
  'ܣܠܦܬܐ',
  'ܛܥܘܢܐ',
  'ܩܕܘܡܐ',
  'ܡܩܥܠܐ',
  'ܐܘܪܝܐ',
  'ܕܚܘܝܐ',
  'ܚܒܘܫܐ',
  'ܡܚܒܢܐ',
  'ܡܪܥܝܐ',
  'ܣܥܪܬܐ',
  'ܫܓܫܬܐ',
  'ܢܦܝܠܐ',
  'ܓܕܠܝܐ',
  'ܡܓܪܓܪ',
  'ܡܫܪܩܦ',
  'ܣܠܩܢܐ',
  'ܫܘܦܝܐ',
  'ܥܝܘܕܐ',
  'ܓܘܝܬܐ',
  'ܐܝܓܗܐ',
  'ܪܥܝܫܐ',
  'ܫܒܝܬܐ',
  'ܡܙܘܕܐ',
  'ܥܒܐܪܬ',
  'ܠܡܘܨܐ',
  'ܡܝܕܝܢ',
  'ܢܘܒܕܪ',
  'ܢܘܘܣܐ',
  'ܬܡܘܙܐ',
  'ܡܗܕܝܐ',
  'ܠܒܘܟܐ',
  'ܪܗܝܒܐ',
  'ܠܗܠܝܐ',
  'ܒܛܘܠܐ',
  'ܡܡܘܠܐ',
  'ܠܒܝܒܐ',
  'ܦܫܘܘܐ',
  'ܠܚܘܫܐ',
  'ܪܘܨܦܐ',
  'ܕܟܘܝܐ',
  'ܫܚܝܕܐ',
  'ܐܡܘܪܐ',
  'ܝܪܝܟܐ',
  'ܦܦܝܬܐ',
  'ܓܒܪܬܐ',
  'ܒܛܒܛܐ',
  'ܡܛܡܛܡ',
  'ܡܝܟܐܠ',
  'ܡܘܪܩܐ',
  'ܦܫܟܠܐ',
  'ܗܠܘܝܝ',
  'ܬܘܫܥܐ',
  'ܡܘܠܫܐ',
  'ܩܘܫܘܢ',
  'ܩܦܪܢܐ',
  'ܬܘܒܝܠ',
  'ܡܥܓܠܐ',
  'ܡܨܠܬܐ',
  'ܩܒܠܬܐ',
  'ܕܘܒܪܐ',
  'ܙܠܝܡܐ',
  'ܣܩܘܥܐ',
  'ܡܟܣܪܐ',
  'ܚܝܘܒܐ',
  'ܫܘܙܒܐ',
  'ܡܢܘܬܐ',
  'ܡܒܕܠܐ',
  'ܪܓܝܙܐ',
  'ܫܝܫܢܐ',
  'ܫܘܡܬܐ',
  'ܣܘܦܝܐ',
  'ܫܢܢܢܐ',
  'ܚܣܟܬܐ',
  'ܡܙܢܦܪ',
  'ܚܠܝܨܐ',
  'ܓܥܘܝܐ',
  'ܦܪܢܣܐ',
  'ܗܡܝܢܝ',
  'ܛܘܒܝܐ',
  'ܚܘܪܣܢ',
  'ܫܟܝܪܐ',
  'ܦܣܝܢܐ',
  'ܬܦܘܘܬ',
  'ܓܪܓܪܐ',
  'ܡܒܘܫܐ',
  'ܨܦܘܪܐ',
  'ܠܥܣܢܐ',
  'ܐܦܘܠܐ',
  'ܟܡܘܡܐ',
  'ܡܘܙܓܐ',
  'ܛܥܘܢܐ',
  'ܥܠܝܒܐ',
  'ܪܘܥܠܐ',
  'ܚܠܝܨܐ',
  'ܡܟܣܬܐ',
  'ܨܪܝܚܐ',
  'ܛܪܘܕܐ',
  'ܡܗܠܢܐ',
  'ܫܡܥܬܐ',
  'ܢܗܪܬܐ',
  'ܚܒܠܬܐ',
  'ܣܪܟܬܐ',
  'ܓܣܝܬܐ',
  'ܟܣܪܘܢ',
  'ܕܪܝܬܐ',
  'ܩܠܝܫܐ',
  'ܩܪܘܢܐ',
  'ܫܘܫܢܐ',
  'ܓܪܝܬܐ',
  'ܨܦܘܢܐ',
  'ܒܝܬܘܢ',
  'ܓܠܝܬܐ',
  'ܢܗܡܬܐ',
  'ܡܠܘܛܐ',
  'ܦܪܚܬܐ',
  'ܐܣܛܕܐ',
  'ܓܠܒܘܥ',
  'ܢܟܝܢܐ',
  'ܦܠܝܛܐ',
  'ܬܪܘܨܐ',
  'ܓܘܠܬܐ',
  'ܫܘܫܓܪ',
  'ܒܠܟܬܐ',
  'ܓܠܝܢܐ',
  'ܟܘܬܡܐ',
  'ܚܢܓܬܐ',
  'ܩܘܢܬܐ',
  'ܠܓܝܢܐ',
  'ܟܘܪܬܐ',
  'ܡܠܝܫܐ',
  'ܡܬܚܪܡ',
  'ܡܦܪܙܐ',
  'ܣܘܝܟܐ',
  'ܦܓܘܕܐ',
  'ܩܘܩܬܐ',
  'ܬܘܪܢܐ',
  'ܠܩܘܛܐ',
  'ܦܘܩܬܐ',
  'ܦܫܝܪܐ',
  'ܓܕܝܠܐ',
  'ܫܒܘܠܬ',
  'ܩܛܝܒܐ',
  'ܥܘܩܠܐ',
  'ܬܡܢܝܐ',
  'ܡܥܝܘܢ',
  'ܥܘܠܬܐ',
  'ܡܝܡܣܐ',
  'ܠܐܘܛܐ',
  'ܓܘܪܫܐ',
  'ܢܘܚܬܐ',
  'ܚܫܘܒܐ',
  'ܠܝܛܪܐ',
  'ܝܥܘܢܐ',
  'ܦܝܪܡܐ',
  'ܪܡܩܠܐ',
  'ܬܪܫܬܐ',
  'ܠܘܠܟܐ',
  'ܣܪܝܬܐ',
  'ܩܠܝܬܐ',
  'ܕܠܘܩܐ',
  'ܫܓܝܪܕ',
  'ܚܡܠܬܐ',
  'ܛܒܠܪܐ',
  'ܡܙܪܙܪ',
  'ܡܩܢܛܐ',
  'ܡܦܪܡܢ',
  'ܕܝܬܩܐ',
  'ܐܣܩܛܐ',
  'ܒܚܪܢܐ',
  'ܡܩܛܩܛ',
  'ܓܢܝܡܬ',
  'ܡܛܝܬܐ',
  'ܠܘܛܬܐ',
  'ܡܘܕܝܢ',
  'ܣܘܪܕܐ',
  'ܥܡܠܝܩ',
  'ܡܬܠܓܢ',
  'ܩܝܡܬܐ',
  'ܟܠܝܘܢ',
  'ܕܨܪܝܐ',
  'ܫܘܕܠܐ',
  'ܡܒܠܒܠ',
  'ܡܟܫܬܐ',
  'ܕܘܘܢܐ',
  'ܡܩܒܘܠ',
  'ܐܬܪܝܐ',
  'ܓܠܘܒܐ',
  'ܣܢܘܛܐ',
  'ܐܒܪܝܙ',
  'ܡܣܩܠܐ',
  'ܒܪܙܬܐ',
  'ܚܚܢܬܐ',
  'ܒܪܡܫܐ',
  'ܥܠܘܩܐ',
  'ܛܡܦܢܐ',
  'ܩܕܝܠܐ',
  'ܪܨܘܨܐ',
  'ܙܡܪܬܐ',
  'ܚܓܝܠܐ',
  'ܡܫܪܝܐ',
  'ܣܪܥܬܐ',
  'ܦܨܘܠܐ',
  'ܡܦܪܩܐ',
  'ܚܘܠܩܐ',
  'ܫܥܝܢܐ',
  'ܒܘܒܝܐ',
  'ܫܗܝܬܐ',
  'ܡܬܢܬܢ',
  'ܫܘܡܗܐ',
  'ܦܟܘܟܐ',
  'ܙܡܘܪܐ',
  'ܗܕܘܣܬ',
  'ܬܪܟܡܐ',
  'ܠܘܩܝܐ',
  'ܒܛܝܢܐ',
  'ܟܘܣܝܐ',
  'ܟܢܘܦܐ',
  'ܐܝܘܐܢ',
  'ܨܗܘܢܐ',
  'ܦܝܐܠܐ',
  'ܩܛܒܬܐ',
  'ܩܦܘܕܐ',
  'ܡܠܟܬܐ',
  'ܦܕܘܪܐ',
  'ܩܒܘܬܐ',
  'ܠܟܣܠܝ',
  'ܓܘܪܪܐ',
  'ܡܘܣܝܒ',
  'ܬܘܠܥܐ',
  'ܒܘܕܪܐ',
  'ܒܘܣܝܪ',
  'ܟܒܪܝܐ',
  'ܓܢܝܚܐ',
  'ܫܠܝܬܐ',
  'ܒܩܕܡܐ',
  'ܫܦܘܪܐ',
  'ܣܬܘܢܐ',
  'ܛܥܡܬܐ',
  'ܩܘܢܛܐ',
  'ܟܢܟܢܐ',
  'ܚܘܓܬܐ',
  'ܐܡܠܟܐ',
  'ܒܫܘܪܐ',
  'ܫܒܬܝܐ',
  'ܨܘܠܦܐ',
  'ܡܒܪܩܠ',
  'ܚܟܡܬܐ',
  'ܟܪܝܒܐ',
  'ܙܝܒܪܝ',
  'ܒܥܬܬܐ',
  'ܠܒܚܬܐ',
  'ܨܝܘܬܐ',
  'ܣܦܝܠܐ',
  'ܠܒܝܟܐ',
  'ܡܬܘܒܐ',
  'ܒܠܝܬܐ',
  'ܫܐܝܥܐ',
  'ܚܪܡܘܢ',
  'ܥܙܪܪܐ',
  'ܕܘܓܠܐ',
  'ܒܪܘܢܐ',
  'ܟܘܠܘܟ',
  'ܓܥܝܡܐ',
  'ܐܚܘܢܐ',
  'ܪܕܝܬܐ',
  'ܦܪܩܬܐ',
  'ܣܘܪܝܐ',
  'ܢܟܡܝܠ',
  'ܡܪܓܪܓ',
  'ܕܘܝܒܐ',
  'ܐܘܠܘܓ',
  'ܟܪܚܢܐ',
  'ܫܘܠܩܐ',
  'ܐܩܡܝܐ',
  'ܐܬܬܢܚ',
  'ܒܗܬܬܐ',
  'ܚܘܟܡܐ',
  'ܙܪܥܝܐ',
  'ܬܦܟܬܐ',
  'ܦܘܣܩܐ',
  'ܫܘܩܠܐ',
  'ܦܣܛܩܐ',
  'ܥܠܗܕܐ',
  'ܡܘܫܬܟ',
  'ܩܘܢܓܐ',
  'ܕܠܝܬܐ',
  'ܒܓܝܪܐ',
  'ܒܘܒܩܐ',
  'ܡܘܗܪܡ',
  'ܡܚܝܪܐ',
  'ܥܝܢܓܕ',
  'ܩܘܛܦܐ',
  'ܨܘܥܪܐ',
  'ܚܒܠܬܐ',
  'ܨܢܝܥܐ',
  'ܬܫܥܣܪ',
  'ܨܘܡܪܐ',
  'ܐܘܡܬܐ',
  'ܦܪܟܬܐ',
  'ܚܪܝܥܐ',
  'ܩܦܙܬܐ',
  'ܚܘܪܡܬ',
  'ܐܕܗܢܐ',
  'ܢܘܣܟܐ',
  'ܚܠܛܬܐ',
  'ܫܘܐܠܐ',
  'ܥܠܠܬܐ',
  'ܡܫܡܚܢ',
  'ܚܘܝܒܐ',
  'ܡܘܟܠܐ',
  'ܡܘܬܠܐ',
  'ܡܨܕܪܐ',
  'ܕܪܘܢܐ',
  'ܪܡܘܬܐ',
  'ܩܠܘܠܐ',
  'ܚܘܠܕܝ',
  'ܫܘܟܪܐ',
  'ܫܩܝܡܐ',
  'ܩܛܪܩܐ',
  'ܩܘܪܒܐ',
  'ܥܕܘܪܐ',
  'ܥܨܪܬܐ',
  'ܡܟܦܠܐ',
  'ܡܪܗܠܐ',
  'ܡܘܚܬܪ',
  'ܡܬܘܠܐ',
  'ܫܘܪܒܐ',
  'ܕܥܬܝܕ',
  'ܦܘܠܓܐ',
  'ܙܘܪܬܐ',
  'ܢܡܘܢܐ',
  'ܐܣܟܡܐ',
  'ܟܦܘܪܐ',
  'ܕܪܟܡܣ',
  'ܗܕܘܪܐ',
  'ܥܘܬܕܐ',
  'ܦܠܘܚܐ',
  'ܬܘܪܟܬ',
  'ܫܝܘܢܐ',
  'ܚܫܘܒܐ',
  'ܬܘܬܒܐ',
  'ܬܫܥܝܢ',
  'ܣܪܘܢܐ',
  'ܓܡܘܫܐ',
  'ܒܟܘܪܐ',
  'ܐܕܐܪܐ',
  'ܕܒܘܪܐ',
  'ܙܕܘܩܐ',
  'ܡܣܩܢܐ',
  'ܓܕܘܪܐ',
  'ܟܪܡܝܙ',
  'ܩܘܕܩܐ',
  'ܣܩܠܬܐ',
  'ܫܪܘܩܐ',
  'ܓܕܠܬܐ',
  'ܫܦܪܬܐ',
  'ܬܝܒܬܐ',
  'ܚܫܢܬܐ',
  'ܫܐܘܠܐ',
  'ܚܘܪܛܐ',
  'ܩܩܩܩܐ',
  'ܦܫܪܬܐ',
  'ܒܘܝܐܐ',
  'ܫܘܠܚܐ',
  'ܟܐܦܬܐ',
  'ܟܢܕܠܝ',
  'ܪܘܕܘܣ',
  'ܝܬܒܬܐ',
  'ܦܦܪܘܣ',
  'ܩܪܝܝܐ',
  'ܢܘܩܝܐ',
  'ܒܢܝܬܐ',
  'ܚܣܝܟܐ',
  'ܐܠܦܪܐ',
  'ܝܫܘܝܐ',
  'ܘܟܝܠܐ',
  'ܦܫܩܢܐ',
  'ܣܓܝܐܐ',
  'ܡܪܘܡܐ',
  'ܡܬܠܒܠ',
  'ܒܘܕܪܐ',
  'ܡܩܘܪܐ',
  'ܟܟܘܢܐ',
  'ܫܠܘܚܐ',
  'ܣܪܛܢܐ',
  'ܥܪܣܢܐ',
  'ܓܫܘܫܐ',
  'ܡܓܘܫܐ',
  'ܦܨܚܬܐ',
  'ܛܚܘܪܐ',
  'ܗܘܨܠܐ',
  'ܣܝܘܦܐ',
  'ܥܠܠܢܐ',
  'ܒܘܪܟܐ',
  'ܡܘܪܚܨ',
  'ܡܣܚܒܪ',
  'ܫܪܒܠܐ',
  'ܐܝܓܗܐ',
  'ܦܓܘܪܐ',
  'ܚܪܚܪܐ',
  'ܐܬܠܝܢ',
  'ܬܠܝܦܐ',
  'ܚܨܘܕܐ',
  'ܡܨܚܠܚ',
  'ܙܘܛܡܐ',
  'ܢܛܘܪܐ',
  'ܫܘܪܛܐ',
  'ܐܣܛܪܐ',
  'ܚܝܘܕܐ',
  'ܣܘܦܩܐ',
  'ܥܠܡܘܬ',
  'ܫܘܡܢܐ',
  'ܡܬܪܚܢ',
  'ܚܙܝܬܐ',
  'ܢܛܝܥܐ',
  'ܩܐܠܡܐ',
  'ܛܥܘܝܐ',
  'ܢܘܡܬܐ',
  'ܡܙܢܙܪ',
  'ܩܘܩܝܐ',
  'ܢܝܫܢܐ',
  'ܐܪܢܓܐ',
  'ܚܒܘܠܐ',
  'ܪܘܩܘܝ',
  'ܪܣܬܝܐ',
  'ܡܫܪܬܠ',
  'ܡܟܪܦܫ',
  'ܡܩܛܣܐ',
  'ܕܫܢܬܐ',
  'ܡܢܪܬܐ',
  'ܝܠܝܗܝ',
  'ܫܠܝܥܐ',
  'ܦܪܙܠܐ',
  'ܩܣܦܝܐ',
  'ܡܓܪܕܢ',
  'ܕܘܪܛܐ',
  'ܠܚܘܕܗ',
  'ܫܘܦܟܐ',
  'ܢܘܗܙܐ',
  'ܩܡܘܕܐ',
  'ܗܓܝܢܐ',
  'ܡܒܘܬܐ',
  'ܡܣܩܡܐ',
  'ܡܝܣܛܪ',
  'ܡܨܘܚܐ',
  'ܒܘܪܝܐ',
  'ܦܘܪܢܐ',
  'ܡܘܢܥܐ',
  'ܓܘܥܪܐ',
  'ܚܨܪܬܐ',
  'ܒܕܘܝܐ',
  'ܦܘܚܘܢ',
  'ܗܓܝܡܐ',
  'ܛܦܝܬܐ',
  'ܒܣܪܬܐ',
  'ܣܪܒܢܐ',
  'ܡܫܚܝܐ',
  'ܡܠܚܡܐ',
  'ܦܪܝܬܐ',
  'ܓܒܢܬܐ',
  'ܟܘܬܪܐ',
  'ܚܢܘܬܐ',
  'ܬܘܠܚܐ',
  'ܐܩܦܬܐ',
  'ܓܪܫܘܢ',
  'ܩܒܝܥܐ',
  'ܢܒܐܘܬ',
  'ܬܘܝܗܐ',
  'ܚܛܝܬܐ',
  'ܫܠܡܢܐ',
  'ܡܝܬܢܐ',
  'ܬܪܟܝܒ',
  'ܢܫܣܬܐ',
  'ܣܘܝܣܐ',
  'ܣܦܝܕܐ',
  'ܓܪܘܓܐ',
  'ܠܡܐܢܐ',
  'ܕܒܪܝܐ',
  'ܩܢܝܛܐ',
  'ܢܝܒܝܢ',
  'ܣܚܘܦܐ',
  'ܥܘܕܕܐ',
  'ܡܚܙܕܓ',
  'ܥܪܡܬܐ',
  'ܣܪܘܟܐ',
  'ܩܘܪܛܐ',
  'ܡܥܙܠܐ',
  'ܨܦܝܬܐ',
  'ܕܪܝܘܫ',
  'ܒܪܝܟܐ',
  'ܦܝܘܢܕ',
  'ܦܣܠܩܐ',
  'ܪܓܘܙܐ',
  'ܕܘܩܫܐ',
  'ܟܘܟܫܐ',
  'ܢܘܡܦܐ',
  'ܨܝܕܬܐ',
  'ܡܕܥܪܐ',
  'ܡܥܗܪܐ',
  'ܪܘܡܢܐ',
  'ܦܢܬܘܠ',
  'ܣܢܕܢܐ',
  'ܡܘܚܢܐ',
  'ܡܘܪܕܪ',
  'ܥܠܝܨܐ',
  'ܡܪܘܡܐ',
  'ܣܦܝܬܐ',
  'ܬܐܒܝܠ',
  'ܫܟܪܕܪ',
  'ܗܓܘܝܐ',
  'ܦܩܩܬܐ',
  'ܠܝܦܬܐ',
  'ܡܩܪܦܐ',
  'ܢܩܘܙܐ',
  'ܫܘܪܟܬ',
  'ܟܡܪܢܐ',
  'ܣܩܘܠܐ',
  'ܬܠܓܝܐ',
  'ܒܟܝܬܐ',
  'ܐܨܚܬܐ',
  'ܩܢܝܡܬ',
  'ܓܠܘܪܐ',
  'ܡܘܫܪܐ',
  'ܢܘܟܚܐ',
  'ܠܒܝܫܐ',
  'ܟܘܬܡܐ',
  'ܦܬܠܬܐ',
  'ܙܪܓܬܐ',
  'ܟܣܝܬܐ',
  'ܚܘܩܢܐ',
  'ܬܩܘܢܐ',
  'ܡܩܢܒܐ',
  'ܦܘܠܥܐ',
  'ܢܦܘܨܐ',
  'ܛܢܦܢܐ',
  'ܥܦܠܝܐ',
  'ܦܪܘܬܐ',
  'ܬܝܪܫܐ',
  'ܒܠܝܙܘ',
  'ܒܬܘܬܐ',
  'ܩܘܒܠܐ',
  'ܒܕܘܠܐ',
  'ܩܘܒܝܐ',
  'ܬܚܘܡܐ',
  'ܟܪܘܟܐ',
  'ܦܘܓܕܐ',
  'ܨܠܚܬܐ',
  'ܝܣܡܝܢ',
  'ܓܠܘܝܐ',
  'ܥܘܬܩܐ',
  'ܣܒܘܣܐ',
  'ܢܝܢܓܐ',
  'ܒܘܫܝܐ',
  'ܓܒܘܝܐ',
  'ܛܘܪܝܐ',
  'ܕܘܢܬܐ',
  'ܡܟܠܟܠ',
  'ܚܪܘܓܐ',
  'ܒܨܝܪܐ',
  'ܦܛܪܘܣ',
  'ܦܬܚܢܐ',
  'ܡܙܐܬܐ',
  'ܝܠܕܟܘ',
  'ܩܠܝܩܝ',
  'ܦܩܪܬܐ',
  'ܩܪܘܒܐ',
  'ܚܠܘܛܐ',
  'ܬܪܝܬܐ',
  'ܩܠܢܬܐ',
  'ܦܪܝܫܐ',
  'ܕܝܢܝܓ',
  'ܒܨܝܨܐ',
  'ܒܒܠܬܐ',
  'ܬܩܕܝܪ',
  'ܫܩܘܦܐ',
  'ܡܣܡܠܐ',
  'ܨܥܪܬܐ',
  'ܒܘܩܩܐ',
  'ܡܕܫܢܐ',
  'ܒܝܟܢܐ',
  'ܬܝܒܬܐ',
  'ܐܒܠܝܐ',
  'ܓܘܪܒܐ',
  'ܚܢܘܬܐ',
  'ܡܘܚܬܐ',
  'ܚܦܘܛܐ',
  'ܓܕܘܝܐ',
  'ܥܪܒܬܐ',
  'ܬܟܠܬܐ',
  'ܙܡܝܢܐ',
  'ܩܪܒܘܢ',
  'ܡܗܠܒܩ',
  'ܣܪܝܩܐ',
  'ܫܠܐܝܬ',
  'ܪܛܘܒܐ',
  'ܝܕܝܥܐ',
  'ܒܘܗܪܢ',
  'ܡܦܬܚܐ',
  'ܙܗܡܬܐ',
  'ܦܫܘܛܐ',
  'ܦܨܘܝܐ',
  'ܢܘܩܪܐ',
  'ܡܘܚܡܕ',
  'ܡܬܫܒܚ',
  'ܐܬܚܘܒ',
  'ܚܝܛܬܐ',
  'ܗܣܢܝܝ',
  'ܕܝܘܒܐ',
  'ܥܒܕܝܐ',
  'ܢܛܪܝܐ',
  'ܦܠܛܬܐ',
  'ܢܒܘܥܐ',
  'ܙܚܘܦܐ',
  'ܕܘܒܕܪ',
  'ܓܒܝܢܐ',
  'ܡܩܠܥܐ',
  'ܐܒܝܛܐ',
  'ܩܪܙܕܪ',
  'ܐܬܘܬܐ',
  'ܝܕܥܢܐ',
  'ܚܪܘܪܐ',
  'ܝܠܘܕܐ',
  'ܕܝܘܐܢ',
  'ܘܨܘܨܐ',
  'ܣܟܢܝܐ',
  'ܐܪܒܠܐ',
  'ܙܒܢܢܐ',
  'ܐܣܬܩܐ',
  'ܩܠܢܕܪ',
  'ܪܥܘܕܐ',
  'ܪܚܒܥܡ',
  'ܐܘܪܟܐ',
  'ܡܙܥܪܐ',
  'ܩܘܦܝܐ',
  'ܦܩܝܪܐ',
  'ܩܕܝܦܐ',
  'ܚܢܝܝܐ',
  'ܦܝܢܚܣ',
  'ܩܘܢܕܩ',
  'ܬܡܙܢܐ',
  'ܬܒܘܥܐ',
  'ܨܡܚܢܐ',
  'ܓܠܘܠܐ',
  'ܪܚܡܝܐ',
  'ܓܣܘܪܐ',
  'ܕܒܝܩܐ',
  'ܒܪܟܢܐ',
  'ܫܘܦܢܐ',
  'ܢܡܠܬܐ',
  'ܡܫܪܫܪ',
  'ܫܢܝܓܐ',
  'ܫܘܒܬܐ',
  'ܪܕܘܦܐ',
  'ܓܕܫܬܐ',
  'ܬܪܘܒܐ',
  'ܚܒܪܬܐ',
  'ܨܓܘܬܐ',
  'ܩܘܙܝܐ',
  'ܡܠܚܢܐ',
  'ܒܐܩܠܐ',
  'ܣܘܠܬܐ',
  'ܩܒܝܬܐ',
  'ܙܟܘܬܐ',
  'ܬܘܩܝܝ',
  'ܡܫܩܠܐ',
  'ܐܬܘܢܐ',
  'ܕܒܘܠܐ',
  'ܡܕܝܪܐ',
  'ܗܝܘܪܐ',
  'ܨܒܘܥܐ',
  'ܝܟܘܬܐ',
  'ܡܙܘܓܐ',
  'ܦܬܘܫܐ',
  'ܙܚܦܬܐ',
  'ܐܐܪܢܐ',
  'ܒܐܝܕܐ',
  'ܓܕܝܫܐ',
  'ܛܘܗܡܐ',
  'ܪܫܘܡܐ',
  'ܦܪܘܬܐ',
  'ܗܘܪܪܐ',
  'ܦܘܓܠܐ',
  'ܛܪܘܦܐ',
  'ܣܪܘܓܐ',
  'ܡܢܘܢܐ',
  'ܫܒܘܠܐ',
  'ܚܘܪܙܐ',
  'ܒܙܥܒܙ',
  'ܡܠܘܟܐ',
  'ܦܘܠܕܐ',
  'ܦܬܚܬܐ',
  'ܗܕܘܝܐ',
  'ܐܘܒܝܐ',
  'ܚܢܘܩܐ',
  'ܫܘܩܬܐ',
  'ܡܡܘܙܐ',
  'ܡܟܢܟܫ',
  'ܝܩܝܢܐ',
  'ܫܢܢܬܐ',
  'ܫܘܠܝܐ',
  'ܥܦܪܢܐ',
  'ܕܘܫܐܒ',
  'ܡܒܢܦܪ',
  'ܬܦܘܬܐ',
  'ܐܬܬܒܥ',
  'ܬܦܘܘܬ',
  'ܬܦܢܟܐ',
  'ܓܡܥܬܐ',
  'ܝܘܝܕܥ',
  'ܗܩܘܬܐ',
  'ܦܟܘܪܐ',
  'ܫܗܙܕܐ',
  'ܥܠܝܗܝ',
  'ܐܥܝܦܐ',
  'ܬܣܒܝܐ',
  'ܣܩܥܬܐ',
  'ܡܚܠܦܐ',
  'ܦܘܬܩܐ',
  'ܣܝܢܝܐ',
  'ܪܘܡܙܐ',
  'ܪܡܐܝܬ',
  'ܪܝܘܕܐ',
  'ܚܐܘܟܐ',
  'ܝܕܝܥܐ',
  'ܫܡܥܝܐ',
  'ܬܥܠܬܐ',
  'ܛܪܕܬܐ',
  'ܕܬܢܒܐ',
  'ܣܬܘܪܐ',
  'ܬܢܚܬܐ',
  'ܡܥܬܒܪ',
  'ܚܠܝܠܝ',
  'ܡܕܐܬܐ',
  'ܦܝܓܢܐ',
  'ܢܩܘܡܐ',
  'ܩܙܡܦܪ',
  'ܡܦܪܫܐ',
  'ܓܠܝܡܐ',
  'ܥܝܕܝܐ',
  'ܫܦܪܬܐ',
  'ܦܝܘܪܐ',
  'ܣܬܘܝܐ',
  'ܚܘܪܙܐ',
  'ܒܘܚܪܐ',
  'ܫܘܩܩܐ',
  'ܡܪܝܪܐ',
  'ܟܠܘܫܐ',
  'ܡܦܘܒܐ',
  'ܐܡܪܢܐ',
  'ܡܘܪܝܐ',
  'ܡܩܘܦܐ',
  'ܝܘܐܝܠ',
  'ܓܫܘܩܐ',
  'ܒܙܚܡܐ',
  'ܣܛܘܦܐ',
  'ܡܥܣܬܐ',
  'ܡܘܬܒܐ',
  'ܣܬܪܦܐ',
  'ܕܟܘܝܐ',
  'ܩܡܝܛܐ',
  'ܡܫܩܠܐ',
  'ܪܘܘܨܐ',
  'ܕܠܝܠܐ',
  'ܬܘܢܝܐ',
  'ܟܪܦܣܐ',
  'ܚܕܡܬܐ',
  'ܙܘܝܚܐ',
  'ܢܫܪܬܐ',
  'ܣܒܝܥܐ',
  'ܐܪܛܒܐ',
  'ܨܐܝܒܐ',
  'ܫܘܛܦܐ',
  'ܡܥܛܦܐ',
  'ܩܘܕܚܐ',
  'ܡܛܘܫܐ',
  'ܕܡܝܢܐ',
  'ܚܢܘܓܐ',
  'ܚܪܝܦܐ',
  'ܥܠܘܗܝ',
  'ܓܘܪܣܐ',
  'ܪܘܓܙܐ',
  'ܫܦܘܠܐ',
  'ܡܨܠܚܐ',
  'ܡܚܘܪܝ',
  'ܬܘܒܪܐ',
  'ܝܘܚܣܐ',
  'ܟܫܟܪܐ',
  'ܬܘܪܥܐ',
  'ܙܩܘܛܐ',
  'ܟܒܫܬܐ',
  'ܩܝܛܝܐ',
  'ܡܩܛܝܐ',
  'ܕܥܘܟܐ',
  'ܥܫܩܢܐ',
  'ܦܪܘܬܐ',
  'ܫܥܘܬܐ',
  'ܓܙܘܙܐ',
  'ܣܒܠܓܐ',
  'ܓܥܘܨܐ',
  'ܬܐܓܡܐ',
  'ܕܪܘܓܐ',
  'ܪܘܒܠܐ',
  'ܠܘܥܒܐ',
  'ܙܩܝܬܐ',
  'ܢܚܝܪܐ',
  'ܟܪܘܝܫ',
  'ܐܚܪܢܐ',
  'ܡܙܒܠܢ',
  'ܠܘܩܛܐ',
  'ܙܡܝܛܐ',
  'ܕܘܒܪܐ',
  'ܒܙܒܢܐ',
  'ܕܟܘܢܐ',
  'ܓܠܘܬܐ',
  'ܫܠܘܩܐ',
  'ܐܒܕܢܐ',
  'ܡܢܬܢܐ',
  'ܩܘܬܘܪ',
  'ܒܙܘܙܐ',
  'ܕܟܝܪܐ',
  'ܙܘܓܓܐ',
  'ܚܪܕܠܐ',
  'ܡܟܠܢܐ',
  'ܡܘܫܬܐ',
  'ܫܡܘܗܐ',
  'ܩܘܡܨܠ',
  'ܚܘܟܡܐ',
  'ܛܢܦܬܐ',
  'ܡܬܝܢܐ',
  'ܪܩܘܬܐ',
  'ܟܠܒܝܐ',
  'ܩܬܝܬܐ',
  'ܡܠܗܠܗ',
  'ܩܪܛܝܐ',
  'ܡܦܫܦܫ',
  'ܫܟܘܬܐ',
  'ܫܡܚܢܐ',
  'ܩܪܠܘܓ',
  'ܬܡܥܟܪ',
  'ܫܥܫܝܐ',
  'ܥܝܩܘܝ',
  'ܛܠܘܡܐ',
  'ܬܘܠܬܐ',
  'ܛܒܥܝܐ',
  'ܥܒܕܬܐ',
  'ܟܡܘܫܐ',
  'ܣܝܦܝܝ',
  'ܥܡܝܕܐ',
  'ܥܘܪܝܐ',
  'ܚܕܘܬܐ',
  'ܫܘܪܬܐ',
  'ܣܠܘܒܐ',
  'ܡܫܦܪܢ',
  'ܥܓܠܬܐ',
  'ܢܣܪܬܐ',
  'ܣܝܠܝܢ',
  'ܓܪܘܕܐ',
  'ܬܒܪܬܐ',
  'ܗܪܡܝܣ',
  'ܨܘܝܪܐ',
  'ܦܛܝܫܐ',
  'ܢܓܘܙܐ',
  'ܬܪܩܦܐ',
  'ܬܡܢܕܐ',
  'ܚܒܝܒܐ',
  'ܡܡܝܢܐ',
  'ܫܝܓܪܐ',
  'ܐܫܘܪܐ',
  'ܐܬܪܙܝ',
  'ܥܫܘܩܐ',
  'ܡܩܡܬܐ',
  'ܒܐܩܝܢ',
  'ܪܒܝܬܐ',
  'ܬܢܝܢܐ',
  'ܒܛܠܝܬ',
  'ܫܡܘܛܐ',
  'ܨܘܒܝܐ',
  'ܚܠܝܬܐ',
  'ܡܠܘܫܐ',
  'ܒܗܘܪܐ',
  'ܪܬܪܬܐ',
  'ܢܩܘܦܐ',
  'ܕܪܒܢܐ',
  'ܩܒܘܠܐ',
  'ܢܡܘܢܐ',
  'ܠܣܡܠܐ',
  'ܒܪܡܬܐ',
  'ܣܒܪܬܐ',
  'ܫܘܚܬܐ',
  'ܣܐܠܢܐ',
  'ܩܪܘܪܐ',
  'ܣܘܟܘܬ',
  'ܙܐܡܝܢ',
  'ܟܘܣܬܐ',
  'ܒܥܝܢܐ',
  'ܡܪܦܪܦ',
  'ܢܨܘܦܐ',
  'ܒܘܪܣܐ',
  'ܒܠܝܓܐ',
  'ܪܗܘܡܐ',
  'ܡܨܥܬܐ',
  'ܩܪܘܨܐ',
  'ܪܘܪܒܐ',
  'ܥܫܐܝܠ',
  'ܡܚܪܝܐ',
  'ܟܘܠܡܐ',
  'ܪܚܝܩܐ',
  'ܡܕܝܢܐ',
  'ܪܡܘܙܐ',
  'ܢܣܘܪܐ',
  'ܙܡܘܢܐ',
  'ܚܣܡܢܐ',
  'ܒܣܡܬܐ',
  'ܩܘܒܥܐ',
  'ܐܟܠܬܐ',
  'ܚܪܝܒܐ',
  'ܩܛܪܬܐ',
  'ܛܠܥܬܐ',
  'ܡܙܝܕܐ',
  'ܐܘܠܘܓ',
  'ܡܓܢܕܪ',
  'ܓܕܣܡܢ',
  'ܒܠܒܛܐ',
  'ܛܝܓܢܐ',
  'ܗܡܫܝܐ',
  'ܢܩܦܝܐ',
  'ܚܠܨܢܐ',
  'ܝܕܝܥܐ',
  'ܥܪܩܠܐ',
  'ܒܙܝܥܐ',
  'ܐܕܪܒܐ',
  'ܪܚܡܬܐ',
  'ܕܪܝܢܐ',
  'ܠܥܝܢܬ',
  'ܬܠܩܬܐ',
  'ܡܟܪܟܬ',
  'ܡܥܪܩܠ',
  'ܬܒܩܬܐ',
  'ܡܦܪܓܐ',
  'ܥܘܕܕܐ',
  'ܗܘܠܘܟ',
  'ܣܘܡܩܐ',
  'ܩܛܝܢܐ',
  'ܥܘܡܝܐ',
  'ܡܚܪܫܐ',
  'ܨܘܒܝܐ',
  'ܬܝܘܒܐ',
  'ܣܚܘܬܐ',
  'ܦܙܝܙܐ',
  'ܓܢܘܣܐ',
  'ܚܠܘܢܐ',
  'ܡܥܘܟܐ',
  'ܐܪܘܙܐ',
  'ܐܦܗܫܐ',
  'ܐܫܦܙܐ',
  'ܕܝܕܒܐ',
  'ܦܪܝܗܐ',
  'ܣܒܝܢܐ',
  'ܩܘܕܫܐ',
  'ܦܠܣܬܐ',
  'ܨܠܘܠܐ',
  'ܣܪܘܕܐ',
  'ܦܓܪܢܐ',
  'ܚܘܡܠܐ',
  'ܚܙܡܬܐ',
  'ܐܬܝܢܐ',
  'ܒܘܪܡܐ',
  'ܥܣܪܬܐ',
  'ܦܪܣܬܐ',
  'ܪܛܘܢܐ',
  'ܟܘܣܝܐ',
  'ܟܦܘܫܐ',
  'ܙܘܬܪܐ',
  'ܢܡܘܢܐ',
  'ܛܝܘܒܐ',
  'ܣܘܙܢܝ',
  'ܚܙܝܪܐ',
  'ܬܘܢܢܐ',
  'ܙܦܣܟܐ',
  'ܬܩܠܬܐ',
  'ܩܩܘܢܐ',
  'ܓܠܛܝܐ',
  'ܚܘܪܬܐ',
  'ܡܫܠܚܐ',
  'ܩܪܫܬܐ',
  'ܢܘܦܨܐ',
  'ܐܠܝܬܐ',
  'ܟܘܫܦܐ',
  'ܥܡܘܛܐ',
  'ܡܦܠܬܐ',
  'ܕܘܪܝܐ',
  'ܕܘܟܢܐ',
  'ܛܘܒܥܐ',
  'ܐܕܡܬܐ',
  'ܒܠܒܛܐ',
  'ܩܙܝܬܐ',
  'ܦܪܘܢܐ',
  'ܓܠܝܦܐ',
  'ܙܕܩܝܐ',
  'ܐܫܪܠܐ',
  'ܡܓܡܓܡ',
  'ܗܘܓܢܐ',
  'ܟܡܝܣܐ',
  'ܪܘܩܥܐ',
  'ܒܪܐܝܬ',
  'ܦܪܣܝܐ',
  'ܚܘܟܡܐ',
  'ܝܡܘܝܐ',
  'ܛܘܢܒܐ',
  'ܫܐܪܝܐ',
  'ܦܛܡܘܣ',
  'ܥܕܐܠܬ',
  'ܫܡܪܢܐ',
  'ܩܘܪܒܐ',
  'ܡܙܘܥܐ',
  'ܡܙܟܙܟ',
  'ܫܬܝܚܐ',
  'ܒܘܒܝܐ',
  'ܡܣܡܡܐ',
  'ܙܝܙܢܐ',
  'ܕܒܫܬܐ',
  'ܡܟܦܦܐ',
  'ܓܠܘܝܐ',
  'ܣܦܝܢܐ',
  'ܚܘܛܦܐ',
  'ܪܣܝܣܐ',
  'ܓܪܘܫܐ',
  'ܡܠܟܢܐ',
  'ܢܒܓܬܐ',
  'ܩܠܘܦܐ',
  'ܡܓܘܓܐ',
  'ܓܪܡܘܐ',
  'ܗܨܝܪܐ',
  'ܒܣܡܢܐ',
  'ܩܪܦܘܣ',
  'ܫܘܓܠܐ',
  'ܡܠܐܟܐ',
  'ܦܘܫܟܐ',
  'ܬܪܡܩܐ',
  'ܓܠܢܕܝ',
  'ܬܠܓܢܐ',
  'ܬܬܪܝܐ',
  'ܣܦܝܩܐ',
  'ܡܙܥܪܐ',
  'ܒܗܘܓܪ',
  'ܒܪܝܟܐ',
  'ܕܘܝܒܐ',
  'ܛܘܦܙܐ',
  'ܫܠܝܠܐ',
  'ܢܫܝܫܐ',
  'ܡܪܒܠܐ',
  'ܒܪܫܒܥ',
  'ܡܥܡܪܐ',
  'ܡܘܥܕܐ',
  'ܕܪܘܘܫ',
  'ܬܦܚܢܐ',
  'ܫܟܪܢܐ',
  'ܚܘܝܨܐ',
  'ܟܘܪܓܐ',
  'ܫܟܠܢܐ',
  'ܒܗܩܢܐ',
  'ܦܫܝܩܐ',
  'ܣܕܘܩܐ',
  'ܩܬܕܪܐ',
  'ܩܨܘܨܐ',
  'ܡܣܟܝܐ',
  'ܚܘܨܪܐ',
  'ܠܘܡܕܐ',
  'ܩܘܠܐܝ',
  'ܬܘܝܡܪ',
  'ܬܐܪܬܐ',
  'ܫܘܪܛܐ',
  'ܣܪܘܓܐ',
  'ܩܡܝܥܐ',
  'ܩܛܘܪܐ',
  'ܗܒܚܪܐ',
  'ܨܘܡܝܐ',
  'ܫܒܩܬܐ',
  'ܚܨܘܦܐ',
  'ܦܝܐܕܐ',
  'ܢܫܝܬܐ',
  'ܚܒܢܢܐ',
  'ܙܘܗܪܐ',
  'ܐܘܪܗܝ',
  'ܚܙܝܩܐ',
  'ܢܚܒܬܐ',
  'ܩܠܩܬܐ',
  'ܬܘܘܙܐ',
  'ܐܣܥܘܬ',
  'ܐܣܟܠܐ',
  'ܡܟܚܠܐ',
  'ܓܢܚܬܐ',
  'ܒܒܘܢܐ',
  'ܢܟܘܬܐ',
  'ܓܘܕܝܐ',
  'ܡܘܗܪܐ',
  'ܡܫܝܢܐ',
  'ܓܠܘܕܐ',
  'ܙܝܘܚܐ',
  'ܩܠܘܚܐ',
  'ܕܘܕܣܬ',
  'ܛܟܢܢܐ',
  'ܢܨܘܚܐ',
  'ܩܡܝܥܐ',
  'ܦܣܘܣܐ',
  'ܡܬܕܘܣ',
  'ܠܘܒܝܣ',
  'ܩܪܡܬܐ',
  'ܡܬܠܡܣ',
  'ܐܕܝܗܐ',
  'ܦܪܩܛܐ',
  'ܠܘܟܢܐ',
  'ܗܦܘܟܐ',
  'ܚܦܘܬܐ',
  'ܡܬܪܒܚ',
  'ܫܠܘܡܐ',
  'ܒܚܘܪܐ',
  'ܡܪܨܪܨ',
  'ܚܪܚܝܐ',
  'ܓܪܝܒܐ',
  'ܒܣܝܪܐ',
  'ܦܫܝܛܐ',
  'ܪܫܝܬܐ',
  'ܩܪܢܝܙ',
  'ܚܘܡܪܐ',
  'ܟܠܓܕܐ',
  'ܐܘܝܡܐ',
  'ܩܪܐܟܐ',
  'ܕܪܓܘܢ',
  'ܠܘܕܬܐ',
  'ܟܝܘܠܐ',
  'ܩܕܫܬܐ',
  'ܥܠܝܠܐ',
  'ܫܥܘܛܐ',
  'ܙܥܩܬܐ',
  'ܦܬܢܬܐ',
  'ܫܪܐܫܪ',
  'ܡܩܘܙܐ',
  'ܒܐܪܠܓ',
  'ܢܒܥܢܐ',
  'ܡܚܣܢܐ',
  'ܡܕܒܪܐ',
  'ܕܘܝܕܐ',
  'ܗܘܝܠܐ',
  'ܥܣܪܬܐ',
  'ܚܒܫܬܐ',
  'ܙܘܙܦܐ',
  'ܩܪܩܪܐ',
  'ܐܙܩܬܐ',
  'ܬܪܦܝܡ',
  'ܡܓܫܓܫ',
  'ܪܒܝܒܐ',
  'ܓܝܪܢܐ',
  'ܠܙܝܡܐ',
  'ܓܘܪܝܐ',
  'ܬܠܡܬܐ',
  'ܢܘܝܬܐ',
  'ܡܘܟܪܐ',
  'ܦܫܝܫܐ',
  'ܕܝܢܣܣ',
  'ܣܬܝܗܠ',
  'ܩܘܢܥܐ',
  'ܠܟܚܬܐ',
  'ܡܫܘܫܐ',
  'ܝܘܢܝܐ',
  'ܡܚܝܬܐ',
  'ܩܢܟܝܐ',
  'ܪܘܘܩܐ',
  'ܝܘܒܠܐ',
  'ܬܡܐܫܐ',
  'ܥܘܣܪܐ',
  'ܫܐܠܬܐ',
  'ܚܓܠܬܐ',
  'ܚܙܝܬܐ',
  'ܩܬܪܬܐ',
  'ܦܪܝܣܐ',
  'ܠܘܡܢܐ',
  'ܪܘܣܚܬ',
  'ܚܒܠܬܐ',
  'ܓܚܘܟܐ',
  'ܚܡܠܢܐ',
  'ܓܢܫܝܐ',
  'ܟܦܝܦܐ',
  'ܓܠܒܢܐ',
  'ܐܣܘܛܐ',
  'ܒܘܪܓܝ',
  'ܕܪܙܝܐ',
  'ܐܝܡܡܐ',
  'ܦܘܐܛܐ',
  'ܣܪܕܐܒ',
  'ܛܠܝܚܐ',
  'ܩܘܘܚܐ',
  'ܬܚܘܒܐ',
  'ܙܝܢܢܐ',
  'ܛܘܒܥܐ',
  'ܦܘܟܟܐ',
  'ܩܙܢܦܪ',
  'ܡܝܟܪܘ',
  'ܡܚܘܫܐ',
  'ܝܝܡܝܫ',
  'ܟܘܪܣܐ',
  'ܟܦܓܝܪ',
  'ܪܗܣܦܝ',
  'ܫܠܚܬܐ',
  'ܬܩܝܠܐ',
  'ܣܘܚܝܐ',
  'ܦܘܕܢܐ',
  'ܦܬܘܪܐ',
  'ܒܘܚܪܐ',
  'ܟܡܢܝܐ',
  'ܐܦܝܬܐ',
  'ܬܦܗܘܬ',
  'ܡܛܪܦܐ',
  'ܓܘܥܬܐ',
  'ܡܥܪܒܛ',
  'ܡܫܚܛܐ',
  'ܟܡܠܬܐ',
  'ܗܘܙܘܪ',
  'ܡܙܡܦܪ',
  'ܫܒܘܪܐ',
  'ܙܕܝܩܐ',
  'ܒܪܝܪܐ',
  'ܬܢܝܬܐ',
  'ܐܘܡܬܐ',
  'ܙܡܪܘܕ',
  'ܕܪܘܢܐ',
  'ܒܕܘܒܐ',
  'ܓܒܝܬܐ',
  'ܐܢܫܠܗ',
  'ܨܪܝܕܐ',
  'ܬܪܘܓܐ',
  'ܕܡܘܬܐ',
  'ܠܫܢܬܐ',
  'ܚܢܟܬܐ',
  'ܩܘܨܦܐ',
  'ܡܙܘܙܐ',
  'ܥܓܢܬܐ',
  'ܡܗܟܢܐ',
  'ܦܘܩܩܐ',
  'ܐܙܕܗܐ',
  'ܫܛܝܛܐ',
  'ܒܓܕܫܐ',
  'ܢܘܪܒܐ',
  'ܥܨܝܝܐ',
  'ܓܘܒܝܐ',
  'ܓܕܘܦܐ',
  'ܡܡܙܬܐ',
  'ܫܟܘܪܐ',
  'ܬܫܪܝܐ',
  'ܚܘܪܒܐ',
  'ܣܘܘܣܐ',
  'ܥܘܩܒܐ',
  'ܣܟܐܝܬ',
  'ܦܫܡܬܐ',
  'ܪܨܨܬܐ',
  'ܕܩܝܩܐ',
  'ܫܘܢܩܐ',
  'ܩܘܘܡܐ',
  'ܦܩܘܕܐ',
  'ܚܒܘܠܐ',
  'ܓܘܙܪܐ',
  'ܙܝܘܥܐ',
  'ܩܘܪܫܐ',
  'ܗܠܒܬܐ',
  'ܡܓܢܝܐ',
  'ܣܦܝܢܐ',
  'ܛܝܢܢܐ',
  'ܥܠܘܡܐ',
  'ܐܬܝܪܐ',
  'ܡܚܝܠܐ',
  'ܦܛܘܚܐ',
  'ܫܘܝܬܐ',
  'ܐܠܘܠܐ',
  'ܚܣܘܡܐ',
  'ܒܝܩܘܫ',
  'ܟܠܘܬܐ',
  'ܡܩܦܚܐ',
  'ܪܒܘܥܐ',
  'ܓܪܕܢܐ',
  'ܫܕܘܕܐ',
  'ܣܘܠܒܐ',
  'ܠܒܢܬܐ',
  'ܬܠܘܬܐ',
  'ܣܪܘܩܐ',
  'ܩܘܡܬܐ',
  'ܚܠܘܬܐ',
  'ܡܙܪܕܢ',
  'ܢܝܩܝܐ',
  'ܩܕܓܣܙ',
  'ܦܝܪܘܙ',
  'ܕܫܬܝܐ',
  'ܫܪܝܬܐ',
  'ܥܨܝܢܐ',
  'ܡܛܐܒܐ',
  'ܫܥܝܢܐ',
  'ܡܣܚܪܐ',
  'ܥܙܟܬܐ',
  'ܐܝܡܐܢ',
  'ܦܝܠܒܐ',
  'ܥܡܝܪܐ',
  'ܒܪܝܬܐ',
  'ܓܪܚܦܐ',
  'ܓܕܝܫܐ',
  'ܐܪܡܝܐ',
  'ܣܟܘܠܐ',
  'ܣܐܘܪܐ',
  'ܡܩܨܬܐ',
  'ܟܠܝܬܐ',
  'ܕܠܬܢܠ',
  'ܪܘܬܥܐ',
  'ܫܘܐܝܬ',
  'ܡܦܪܩܥ',
  'ܣܩܪܢܐ',
  'ܦܢܕܩܐ',
  'ܗܙܘܙܐ',
  'ܗܘܝܬܐ',
  'ܩܪܕܠܐ',
  'ܚܕܥܣܪ',
  'ܠܒܝܒܐ',
  'ܓܢܒܝܬ',
  'ܛܡܫܬܐ',
  'ܦܘܚܪܐ',
  'ܝܥܢܬܐ',
  'ܟܝܘܢܐ',
  'ܐܬܦܬܚ',
  'ܕܪܓܒܢ',
  'ܫܘܫܢܐ',
  'ܚܝܘܨܐ',
  'ܩܒܘܪܐ',
  'ܝܠܚܬܐ',
  'ܫܪܝܢܐ',
  'ܣܢܘܢܐ',
  'ܡܘܣܦܐ',
  'ܡܠܝܬܐ',
  'ܬܘܟܡܐ',
  'ܨܐܒܘܢ',
  'ܥܕܝܩܐ',
  'ܫܚܪܬܐ',
  'ܡܠܘܟܐ',
  'ܟܘܦܪܐ',
  'ܬܥܠܢܐ',
  'ܒܨܘܪܐ',
  'ܚܘܬܒܐ',
  'ܫܠܦܬܐ',
  'ܢܝܪܝܐ',
  'ܨܘܪܬܐ',
  'ܒܥܝܬܐ',
  'ܛܦܘܝܐ',
  'ܫܡܝܛܐ',
  'ܠܩܪܕܝ',
  'ܚܫܚܬܐ',
  'ܐܣܪܝܐ',
  'ܚܠܥܬܐ',
  'ܠܟܠܚܕ',
  'ܣܘܪܣܬ',
  'ܦܣܘܣܐ',
  'ܚܠܒܬܐ',
  'ܗܘܫܝܪ',
  'ܪܟܝܒܐ',
  'ܦܪܝܩܐ',
  'ܢܨܘܪܐ',
  'ܟܘܪܟܐ',
  'ܙܝܢܬܐ',
  'ܢܒܘܪܐ',
  'ܡܫܚܫܚ',
  'ܦܘܪܬܐ',
  'ܒܛܝܠܐ',
  'ܓܘܠܓܢ',
  'ܩܠܘܕܐ',
  'ܢܩܫܢܐ',
  'ܓܣܝܬܐ',
  'ܙܗܪܢܐ',
  'ܩܝܬܪܢ',
  'ܡܓܡܒܚ',
  'ܡܪܣܝܐ',
  'ܡܥܢܝܐ',
  'ܚܣܘܕܐ',
  'ܥܬܘܬܐ',
  'ܓܘܢܓܐ',
  'ܓܠܒܝܐ',
  'ܫܪܩܢܐ',
  'ܦܘܠܘܣ',
  'ܓܝܠܡܐ',
  'ܓܕܝܬܐ',
  'ܡܢܘܢܐ',
  'ܦܘܙܘܠ',
  'ܡܚܪܡܐ',
  'ܚܘܠܒܐ',
  'ܫܘܛܛܐ',
  'ܘܪܘܪܕ',
  'ܩܪܘܘܠ',
  'ܦܣܦܣܐ',
  'ܟܣܘܝܐ',
  'ܬܪܒܢܐ',
  'ܐܢܘܗܐ',
  'ܫܘܟܘܪ',
  'ܡܦܪܬܟ',
  'ܟܘܪܙܐ',
  'ܬܪܘܕܐ',
  'ܡܚܘܒܐ',
  'ܚܙܘܪܐ',
  'ܗܪܘܡܐ',
  'ܦܣܘܠܐ',
  'ܡܙܒܛܢ',
  'ܡܦܡܦܚ',
  'ܕܢܕܠܐ',
  'ܓܠܥܬܐ',
  'ܓܘܒܓܐ',
  'ܗܝܠܒܙ',
  'ܕܒܩܬܐ',
  'ܣܘܬܬܐ',
  'ܬܢܙܝܠ',
  'ܦܘܫܬܐ',
  'ܫܚܘܪܐ',
  'ܙܪܥܬܐ',
  'ܒܟܪܬܐ',
  'ܡܠܘܣܐ',
  'ܪܓܘܠܐ',
  'ܫܕܪܢܐ',
  'ܣܘܪܘܪ',
  'ܒܓܠܬܐ',
  'ܝܬܒܬܐ',
  'ܡܙܗܡܬ',
  'ܥܪܒܝܐ',
  'ܡܫܘܝܐ',
  'ܓܪܠܬܐ',
  'ܡܗܨܘܠ',
  'ܢܩܘܝܐ',
  'ܩܘܪܥܐ',
  'ܟܘܗܠܐ',
  'ܡܘܕܐܝ',
  'ܝܕܘܥܐ',
  'ܗܬܘܬܐ',
  'ܦܩܘܥܐ',
  'ܛܫܝܢܐ',
  'ܚܪܙܬܐ',
  'ܛܥܘܡܐ',
  'ܬܘܚܡܐ',
  'ܐܟܘܦܐ',
  'ܚܕܬܝܐ',
  'ܓܝܗܢܐ',
  'ܒܟܝܢܐ',
  'ܡܫܡܫܡ',
  'ܐܪܘܢܐ',
  'ܡܣܡܬܐ',
  'ܚܠܠܬܐ',
  'ܒܪܘܕܐ',
  'ܡܩܪܒܫ',
  'ܪܘܟܒܐ',
  'ܡܣܓܕܐ',
  'ܡܘܗܪܐ',
  'ܟܢܝܫܐ',
  'ܡܙܪܙܟ',
  'ܛܠܘܫܐ',
  'ܬܪܬܪܐ',
  'ܗܟܡܬܐ',
  'ܒܕܡܘܬ',
  'ܒܚܪܬܐ',
  'ܘܝܠܘܟ',
  'ܡܛܠܘܒ',
  'ܦܠܘܚܐ',
  'ܫܦܝܟܐ',
  'ܫܩܝܬܐ',
  'ܣܝܘܡܐ',
  'ܩܛܥܬܐ',
  'ܬܪܥܝܠ',
  'ܐܡܝܬܐ',
  'ܡܝܟܝܐ',
  'ܟܪܘܙܐ',
  'ܩܘܪܪܐ',
  'ܓܠܝܕܐ',
  'ܒܣܛܪܐ',
  'ܩܫܛܬܐ',
  'ܦܨܝܕܐ',
  'ܫܟܘܪܐ',
  'ܣܘܩܝܐ',
  'ܪܗܛܢܐ',
  'ܢܓܘܕܐ',
  'ܫܘܘܛܐ',
  'ܒܚܫܝܫ',
  'ܡܪܢܕܫ',
  'ܕܬܢܘܝ',
  'ܚܠܘܬܝ',
  'ܩܘܡܝܣ',
  'ܟܐܢܪܐ',
  'ܬܘܦܫܐ',
  'ܙܘܬܝܢ',
  'ܡܘܪܒܐ',
  'ܡܓܠܬܐ',
  'ܚܩܝܢܐ',
  'ܡܬܦܫܪ',
  'ܝܡܝܨܐ',
  'ܐܚܬܘܢ',
  'ܐܒܪܗܡ',
  'ܙܘܪܝܬ',
  'ܣܝܢܘܕ',
  'ܨܘܒܥܐ',
  'ܢܕܪܬܐ',
  'ܡܫܕܠܢ',
  'ܒܪܡܒܪ',
  'ܕܘܪܬܐ',
  'ܡܩܠܛܐ',
  'ܠܚܡܢܐ',
  'ܐܪܝܢܐ',
  'ܕܫܘܢܐ',
  'ܡܘܛܝܐ',
  'ܥܗܢܝܐ',
  'ܥܘܪܒܐ',
  'ܓܙܝܬܐ',
  'ܡܫܕܪܐ',
  'ܒܘܟܪܐ',
  'ܢܟܦܬܐ',
  'ܡܢܚܢܚ',
  'ܣܠܥܬܐ',
  'ܚܢܦܬܐ',
  'ܐܬܬܝܒ',
  'ܟܘܝܚܐ',
  'ܒܢܘܟܝ',
  'ܥܒܪܬܐ',
  'ܨܪܘܚܐ',
  'ܛܠܩܢܐ',
  'ܡܟܪܡܫ',
  'ܛܝܘܦܐ',
  'ܡܘܢܚܐ',
  'ܢܘܓܬܐ',
  'ܐܝܪܐܢ',
  'ܝܘܙܕܩ',
  'ܚܘܓܝܐ',
  'ܨܝܘܒܐ',
  'ܟܬܘܒܐ',
  'ܫܘܒܚܐ',
  'ܬܒܘܪܐ',
  'ܛܪܝܬܐ',
  'ܡܩܠܨܢ',
  'ܚܘܢܢܐ',
  'ܓܘܐܝܬ',
  'ܙܢܝܬܐ',
  'ܫܒܘܩܐ',
  'ܫܚܪܢܐ',
  'ܝܣܝܪܐ',
  'ܫܚܡܬܐ',
  'ܫܩܘܩܐ',
  'ܡܘܙܓܐ',
  'ܡܓܪܓܣ',
  'ܗܘܢܝܐ',
  'ܕܝܠܟܝ',
  'ܢܨܒܬܐ',
  'ܐܦܗܡܐ',
  'ܦܪܘܓܐ',
  'ܠܡܚܣܢ',
  'ܥܘܕܠܐ',
  'ܨܪܘܦܐ',
  'ܕܘܪܡܝ',
  'ܬܒܥܝܐ',
  'ܬܘܝܪܐ',
  'ܚܬܪܢܐ',
  'ܡܙܡܙܡ',
  'ܥܬܝܪܐ',
  'ܟܠܒܬܐ',
  'ܬܥܕܝܠ',
  'ܫܡܪܝܐ',
  'ܡܗܝܢܐ',
  'ܐܣܦܪܐ',
  'ܢܟܝܪܐ',
  'ܡܦܬܠܐ',
  'ܡܘܚܒܢ',
  'ܟܦܪܬܐ',
  'ܦܠܫܬܐ',
  'ܗܝܗܢܐ',
  'ܙܢܘܓܐ',
  'ܡܟܬܒܐ',
  'ܩܙܒܓܝ',
  'ܣܦܪܝܫ',
  'ܐܟܝܦܐ',
  'ܪܦܝܬܐ',
  'ܣܪܘܫܐ',
  'ܩܨܝܦܐ',
  'ܡܚܢܙܪ',
  'ܡܫܘܚܐ',
  'ܕܐܪܪܐ',
  'ܚܡܬܢܐ',
  'ܩܕܘܡܐ',
  'ܐܡܢܪܐ',
  'ܢܓܠܬܐ',
  'ܩܪܘܨܐ',
  'ܥܡܘܠܐ',
  'ܣܝܦܐܐ',
  'ܩܪܒܝܢ',
  'ܙܥܦܪܢ',
  'ܡܦܪܦܛ',
  'ܐܣܝܝܐ',
  'ܦܬܓܡܐ',
  'ܬܘܦܪܐ',
  'ܟܘܪܕܪ',
  'ܢܫܝܦܐ',
  'ܙܒܘܪܐ',
  'ܟܘܪܡܐ',
  'ܩܛܦܬܐ',
  'ܓܘܐܝܐ',
  'ܚܡܝܪܐ',
  'ܥܟܘܒܐ',
  'ܡܕܓܕܓ',
  'ܦܝܢܟܐ',
  'ܫܪܟܢܐ',
  'ܡܫܦܫܦ',
  'ܢܫܘܫܐ',
  'ܒܠܩܘܢ',
  'ܫܢܝܬܐ',
  'ܛܥܘܡܐ',
  'ܫܥܪܕܪ',
  'ܢܘܓܕܐ',
  'ܐܚܕܬܐ',
  'ܣܝܩܬܐ',
  'ܟܘܕܪܐ',
  'ܚܕܘܪܐ',
  'ܡܒܗܢܐ',
  'ܬܢܝܬܐ',
  'ܕܝܬܩܝ',
  'ܥܕܘܠܐ',
  'ܓܗܘܝܐ',
  'ܥܪܘܠܐ',
  'ܟܒܘܬܐ',
  'ܪܩܘܬܐ',
  'ܝܘܐܒܐ',
  'ܫܕܘܪܐ',
  'ܡܕܝܘܢ',
  'ܫܪܒܝܐ',
  'ܡܨܬܢܐ',
  'ܗܦܬܪܐ',
  'ܒܣܝܡܬ',
  'ܐܘܪܕܘ',
  'ܚܒܛܬܐ',
  'ܝܪܘܩܐ',
  'ܒܝܪܬܐ',
  'ܚܡܪܬܐ',
  'ܨܘܝܕܐ',
  'ܢܓܘܕܐ',
  'ܦܛܘܢܐ',
  'ܣܘܦܢܐ',
  'ܒܐܡܝܢ',
  'ܣܪܩܢܐ',
  'ܩܕܘܪܐ',
  'ܐܟܕܝܐ',
  'ܨܒܘܥܐ',
  'ܡܢܬܫܐ',
  'ܪܫܝܥܐ',
  'ܩܙܕܬܐ',
  'ܙܢܓܪܐ',
  'ܟܪܘܡܐ',
  'ܫܝܪܢܐ',
  'ܢܙܪܬܐ',
  'ܣܘܪܡܗ',
  'ܒܙܝܓܪ',
  'ܫܫܘܢܐ',
  'ܡܟܬܫܐ',
  'ܠܘܬܟܐ',
  'ܦܘܪܝܡ',
  'ܣܪܝܢܐ',
  'ܡܐܪܡܠ',
  'ܩܦܝܬܐ',
  'ܢܝܙܟܐ',
  'ܢܩܝܦܐ',
  'ܛܠܠܝܐ',
  'ܡܨܒܛܐ',
  'ܢܝܦܓܗ',
  'ܟܢܫܬܐ',
  'ܠܘܒܝܐ',
  'ܣܐܘܢܐ',
  'ܒܠܘܩܐ',
  'ܗܓܘܡܐ',
  'ܪܘܚܨܬ',
  'ܝܘܫܒܥ',
  'ܡܚܫܚܫ',
  'ܒܥܨܝܒ',
  'ܟܪܘܒܐ',
  'ܡܘܫܪܐ',
  'ܐܡܢܝܐ',
  'ܡܕܠܡܐ',
  'ܪܩܕܬܐ',
  'ܒܐܛܘܣ',
  'ܡܪܦܬܐ',
  'ܐܬܝܬܐ',
  'ܥܪܘܬܐ',
  'ܩܘܕܡܐ',
  'ܚܪܝܣܐ',
  'ܡܬܘܗܐ',
  'ܡܣܪܩܐ',
  'ܛܘܝܬܐ',
  'ܝܬܡܬܐ',
  'ܡܡܠܠܐ',
  'ܪܘܘܨܐ',
  'ܒܗܝܬܐ',
  'ܫܕܢܬܐ',
  'ܥܝܝܡܐ',
  'ܐܪܝܘܣ',
  'ܫܝܘܬܐ',
  'ܦܪܘܚܐ',
  'ܡܩܪܡܛ',
  'ܢܓܪܬܐ',
  'ܨܘܘܚܐ',
  'ܓܙܘܪܐ',
  'ܫܛܝܬܐ',
  'ܚܘܝܒܐ',
  'ܚܪܝܩܐ',
  'ܦܚܘܡܐ',
  'ܫܚܘܪܐ',
  'ܙܝܕܢܐ',
  'ܡܕܟܕܟ',
  'ܣܘܒܪܐ',
  'ܬܘܟܠܐ',
  'ܥܪܩܬܐ',
  'ܒܠܚܘܕ',
  'ܡܕܥܢܐ',
  'ܛܥܢܬܐ',
  'ܬܢܙܝܠ',
  'ܚܣܘܕܐ',
  'ܗܩܘܝܐ',
  'ܡܐܪܫܢ',
  'ܫܠܝܠܐ',
  'ܒܝܪܬܐ',
  'ܫܝܦܘܪ',
  'ܦܠܙܡܐ',
  'ܪܘܘܙܐ',
  'ܕܢܕܠܐ',
  'ܒܘܪܕܐ',
  'ܪܐܘܠܐ',
  'ܗܢܓܡܐ',
  'ܣܥܪܝܐ',
  'ܩܝܡܢܐ',
  'ܡܝܕܢܝ',
  'ܐܬܫܥܐ',
  'ܒܠܝܛܐ',
  'ܩܘܪܕܬ',
  'ܓܠܘܝܐ',
  'ܛܢܝܬܐ',
  'ܡܩܘܕܐ',
  'ܛܘܪܢܐ',
  'ܩܪܝܪܐ',
  'ܢܘܒܥܐ',
  'ܡܡܠܟܬ',
  'ܐܣܬܕܐ',
  'ܪܡܝܬܐ',
  'ܩܛܝܥܐ',
  'ܚܛܡܬܐ',
  'ܒܙܗܡܐ',
  'ܥܠܝܬܐ',
  'ܣܘܪܦܐ',
  'ܡܡܪܬܐ',
  'ܦܠܢܝܐ',
  'ܩܪܝܬܐ',
  'ܬܠܘܥܐ',
  'ܒܘܛܠܐ',
  'ܪܘܪܒܐ',
  'ܦܥܘܢܐ',
  'ܡܚܣܟܐ',
  'ܦܫܝܡܐ',
  'ܐܚܝܕܐ',
  'ܚܪܦܬܐ',
  'ܪܩܥܝܐ',
  'ܬܒܘܥܐ',
  'ܐܨܠܗܬ',
  'ܬܗܘܡܐ',
  'ܚܘܒܬܐ',
  'ܐܝܪܬܝ',
  'ܝܘܝܬܢ',
  'ܢܛܘܦܐ',
  'ܕܪܢܕܐ',
  'ܫܢܝܙܐ',
  'ܚܘܕܬܐ',
  'ܓܘܦܪܐ',
  'ܒܛܘܘܐ',
  'ܡܟܪܗܢ',
  'ܩܛܘܡܐ',
  'ܩܪܙܠܐ',
  'ܕܝܫܬܐ',
  'ܐܟܕܝܐ',
  'ܥܐܕܢܐ',
  'ܥܓܘܕܐ',
  'ܗܘܙܝܐ',
  'ܦܫܝܚܐ',
  'ܪܐܘܡܐ',
  'ܙܢܒܘܪ',
  'ܦܘܬܚܐ',
  'ܡܫܘܟܐ',
  'ܬܫܥܣܪ',
  'ܠܒܬܪܐ',
  'ܝܬܢܝܐ',
  'ܬܘܪܪܐ',
  'ܠܒܪܝܐ',
  'ܗܝܝܗܐ',
  'ܚܙܘܘܐ',
  'ܡܛܠܝܐ',
  'ܡܬܠܬܐ',
  'ܣܘܘܩܐ',
  'ܡܪܘܥܐ',
  'ܐܬܐܒܢ',
  'ܙܒܢܢܐ',
  'ܪܚܘܩܐ',
  'ܒܘܪܟܐ',
  'ܪܕܝܬܐ',
  'ܣܘܣܝܐ',
  'ܐܫܝܕܐ',
  'ܣܘܠܩܐ',
  'ܫܘܚܡܐ',
  'ܥܬܘܕܐ',
  'ܫܝܠܘܗ',
  'ܢܚܦܬܐ',
  'ܩܩܒܢܐ',
  'ܡܬܝܢܐ',
  'ܪܚܘܫܐ',
  'ܒܪܝܬܐ',
  'ܪܘܒܢܕ',
  'ܢܬܘܥܐ',
  'ܚܙܘܪܐ',
  'ܣܘܕܪܐ',
  'ܡܕܢܚܐ',
  'ܡܚܠܘܢ',
  'ܬܦܝܢܐ',
  'ܠܝܟܘܢ',
  'ܚܫܚܝܐ',
  'ܐܝܘܪܐ',
  'ܩܝܛܝܐ',
  'ܡܛܓܢܐ',
  'ܓܪܫܢܐ',
  'ܛܘܒܝܐ',
  'ܬܠܘܚܐ',
  'ܡܓܙܪܐ',
  'ܓܘܙܠܓ',
  'ܡܘܟܠܐ',
  'ܩܘܦܠܐ',
  'ܡܙܩܙܩ',
  'ܡܫܘܚܐ',
  'ܕܘܡܘܓ',
  'ܡܛܪܒܙ',
  'ܓܣܝܬܐ',
  'ܡܙܚܡܢ',
  'ܐܬܟܪܬ',
  'ܦܘܣܘܣ',
  'ܩܢܢܬܐ',
  'ܚܪܘܪܐ',
  'ܪܒܒܬܐ',
  'ܠܘܙܬܐ',
  'ܐܣܡܪܐ',
  'ܢܘܒܢܐ',
  'ܡܒܠܥܣ',
  'ܩܫܝܫܐ',
  'ܢܘܚܠܐ',
  'ܒܠܡܬܐ',
  'ܡܣܘܝܐ',
  'ܕܒܘܨܐ',
  'ܬܣܡܬܐ',
  'ܕܪܘܙܐ',
  'ܚܐܪܝܐ',
  'ܫܒܟܬܐ',
  'ܚܢܘܢܐ',
  'ܐܡܘܢܐ',
  'ܡܦܟܗܐ',
  'ܡܘܡܬܐ',
  'ܨܡܘܚܐ',
  'ܠܠܝܢܐ',
  'ܙܕܦܬܐ',
  'ܦܫܟܝܪ',
  'ܛܒܝܒܐ',
  'ܕܠܝܚܐ',
  'ܙܪܢܦܐ',
  'ܕܪܘܟܐ',
  'ܫܠܘܐܪ',
  'ܒܗܡܘܬ',
  'ܒܢܘܘܫ',
  'ܚܣܘܡܐ',
  'ܬܘܩܡܩ',
  'ܢܥܡܬܝ',
  'ܚܝܛܬܐ',
  'ܡܩܪܡܐ',
  'ܒܓܝܪܐ',
  'ܚܦܝܬܐ',
  'ܡܚܪܝܐ',
  'ܦܘܩܚܐ',
  'ܩܝܘܪܐ',
  'ܝܩܘܕܐ',
  'ܫܩܘܪܩ',
  'ܬܠܚܢܐ',
  'ܢܥܡܬܐ',
  'ܛܠܘܩܐ',
  'ܡܛܫܘܫ',
  'ܬܘܪܬܐ',
  'ܕܣܬܠܬ',
  'ܢܓܘܪܐ',
  'ܠܒܘܛܐ',
  'ܡܬܪܫܢ',
  'ܫܘܥܠܐ',
  'ܦܪܫܬܐ',
  'ܗܘܣܬܐ',
  'ܒܚܝܠܐ',
  'ܡܝܬܪܐ',
  'ܡܘܐܓܒ',
  'ܫܪܨܝܐ',
  'ܦܠܛܘܢ',
  'ܢܛܦܬܐ',
  'ܡܪܟܪܟ',
  'ܕܪܥܢܐ',
  'ܐܘܣܠܐ',
  'ܦܠܘܓܐ',
  'ܪܓܘܫܐ',
  'ܠܕܐܟܐ',
  'ܨܠܘܠܐ',
  'ܣܢܬܘܪ',
  'ܐܦܠܠܘ',
  'ܩܠܡܝܣ',
  'ܨܪܨܪܐ',
  'ܒܝܠܙܘ',
  'ܦܬܘܚܐ',
  'ܡܣܟܝܠ',
  'ܒܘܨܝܐ',
  'ܒܚܘܢܐ',
  'ܐܝܘܢܐ',
  'ܣܓܕܬܐ',
  'ܩܡܫܬܩ',
  'ܠܩܘܛܐ',
  'ܓܪܘܡܐ',
  'ܡܘܛܘܢ',
  'ܨܦܘܝܐ',
  'ܣܪܘܛܐ',
  'ܗܡܘܘܪ',
  'ܣܟܢܝܐ',
  'ܚܘܪܝܐ',
  'ܦܝܫܟܫ',
  'ܐܬܬܐܡ',
  'ܟܬܘܡܐ',
  'ܐܘܡܡܐ',
  'ܦܩܘܩܐ',
  'ܥܛܠܬܐ',
  'ܥܩܪܒܐ',
  'ܒܪܚܘܫ',
  'ܫܪܘܨܐ',
  'ܢܪܓܝܣ',
  'ܡܘܪܥܐ',
  'ܢܟܝܠܐ',
  'ܦܘܠܠܐ',
  'ܒܙܘܥܐ',
  'ܝܠܕܬܐ',
  'ܢܚܘܠܐ',
  'ܒܕܘܪܐ',
  'ܒܬܘܠܐ',
  'ܛܢܓܘܝ',
  'ܚܢܛܢܐ',
  'ܒܛܡܬܐ',
  'ܚܕܘܡܐ',
  'ܢܘܦܩܐ',
  'ܫܠܝܠܐ',
  'ܥܟܕܬܐ',
  'ܝܐܝܒܐ',
  'ܦܪܝܟܐ',
  'ܟܘܦܠܐ',
  'ܡܠܘܦܐ',
  'ܡܒܨܡܢ',
  'ܘܪܙܓܪ',
  'ܣܒܪܬܐ',
  'ܓܘܠܡܐ',
  'ܕܣܬܡܠ',
  'ܙܪܘܒܐ',
  'ܚܛܘܪܐ',
  'ܐܝܙܒܠ',
  'ܡܐܓܪܢ',
  'ܣܩܦܬܐ',
  'ܨܠܝܒܐ',
  'ܐܓܝܪܐ',
  'ܕܠܘܝܐ',
  'ܟܪܟܝܫ',
  'ܚܝܡܘܢ',
  'ܚܝܪܚܗ',
  'ܥܙܘܠܐ',
  'ܕܐܪܒܥ',
  'ܕܘܠܦܐ',
  'ܬܘܡܢܐ',
  'ܡܙܓܕܐ',
  'ܦܠܚܝܐ',
  'ܓܘܠܫܢ',
  'ܝܬܪܘܢ',
  'ܨܝܕܘܢ',
  'ܐܬܪܝܐ',
  'ܩܠܥܡܐ',
  'ܢܐܒܠܕ',
  'ܪܓܕܬܐ',
  'ܙܛܘܢܐ',
  'ܟܡܝܪܐ',
  'ܟܟܠܬܐ',
  'ܡܩܪܩܙ',
  'ܪܡܫܝܐ',
  'ܨܕܝܕܐ',
  'ܢܒܘܙܐ',
  'ܩܠܝܘܢ',
  'ܙܘܒܠܐ',
  'ܦܠܣܪܐ',
  'ܐܘܟܕܐ',
  'ܡܙܩܠܩ',
  'ܒܒܘܣܐ',
  'ܡܬܠܓܐ',
  'ܓܦܝܦܐ',
  'ܦܬܝܩܐ',
  'ܩܘܡܐܫ',
  'ܦܘܣܥܐ',
  'ܒܘܓܡܐ',
  'ܪܒܕܡܐ',
  'ܫܝܡܝܐ',
  'ܠܢܫܝܐ',
  'ܡܘܕܚܠ',
  'ܒܘܢܝܐ',
  'ܠܚܡܝܐ',
  'ܡܓܘܣܐ',
  'ܒܐܡܝܐ',
  'ܩܨܝܥܐ',
  'ܡܦܢܩܐ',
  'ܬܘܠܬܐ',
  'ܩܘܛܢܐ',
  'ܬܛܪܢܐ',
  'ܠܒܫܬܐ',
  'ܠܒܝܕܐ',
  'ܥܪܒܝܬ',
  'ܩܘܒܒܐ',
  'ܥܛܘܪܐ',
  'ܡܦܘܝܐ',
  'ܥܡܡܝܐ',
  'ܕܠܘܦܐ',
  'ܨܘܨܨܐ',
  'ܦܬܟܪܐ',
  'ܩܝܘܨܐ',
  'ܕܘܠܟܐ',
  'ܛܘܢܦܐ',
  'ܕܡܥܬܐ',
  'ܢܟܦܬܐ',
  'ܝܡܡܝܐ',
  'ܡܛܥܡܐ',
  'ܚܟܢܕܙ',
  'ܥܙܝܙܐ',
  'ܝܐܪܝܫ',
  'ܛܡܝܘܢ',
  'ܚܘܡܫܐ',
  'ܬܪܝܨܐ',
  'ܥܣܩܬܐ',
  'ܙܓܘܓܐ',
  'ܢܒܝܘܬ',
  'ܡܒܣܡܢ',
  'ܐܪܢܥܐ',
  'ܪܘܥܫܐ',
  'ܠܗܢܬܐ',
  'ܙܕܥܢܐ',
  'ܝܠܝܦܐ',
  'ܕܝܘܪܐ',
  'ܐܣܦܪܬ',
  'ܡܫܬܝܐ',
  'ܠܙܘܙܐ',
  'ܡܫܚܢܐ',
  'ܡܣܢܣܠ',
  'ܦܣܛܩܐ',
  'ܡܙܢܓܪ',
  'ܦܝܠܡܫ',
  'ܩܘܠܬܐ',
  'ܨܦܚܬܐ',
  'ܨܠܝܠܐ',
  'ܬܝܘܒܐ',
  'ܛܝܝܬܐ',
  'ܡܙܝܢܐ',
  'ܦܠܟܬܐ',
  'ܚܬܝܡܐ',
  'ܚܘܫܗܠ',
  'ܡܘܓܠܐ',
  'ܣܡܘܩܐ',
  'ܩܘܛܠܐ',
  'ܡܓܪܡܠ',
  'ܡܦܘܚܐ',
  'ܬܪܚܘܢ',
  'ܩܝܕܬܐ',
  'ܣܟܘܪܐ',
  'ܒܘܝܥܐ',
  'ܩܫܝܬܐ',
  'ܙܘܠܦܐ',
  'ܡܘܩܕܪ',
  'ܗܪܘܓܐ',
  'ܛܠܡܗܐ',
  'ܡܣܘܟܐ',
  'ܚܨܪܘܢ',
  'ܙܘܪܛܐ',
  'ܡܟܝܪܐ',
  'ܕܘܘܠܐ',
  'ܫܝܘܓܐ',
  'ܬܘܥܠܐ',
  'ܢܘܩܒܐ',
  'ܡܒܙܒܩ',
  'ܫܠܓܡܐ',
  'ܚܘܣܢܐ',
  'ܐܓܘܓܐ',
  'ܒܘܕܗܐ',
  'ܡܬܝܬܐ',
  'ܗܢܝܢܐ',
  'ܡܪܢܓܢ',
  'ܓܐܝܬܐ',
  'ܡܨܥܬܐ',
  'ܚܨܝܨܐ',
  'ܕܩܪܬܐ',
  'ܦܪܬܢܐ',
  'ܣܘܦܪܐ',
  'ܥܡܐܘܣ',
  'ܡܬܪܟܣ',
  'ܟܠܝܘܐ',
  'ܡܥܒܕܐ',
  'ܩܘܛܒܐ',
  'ܨܝܘܪܐ',
  'ܬܘܬܝܐ',
  'ܩܛܥܛܐ',
  'ܡܛܪܒܣ',
  'ܡܕܘܥܐ',
  'ܡܣܪܩܐ',
  'ܡܚܒܛܐ',
  'ܥܒܪܢܐ',
  'ܗܡܘܣܐ',
  'ܛܠܢܬܐ',
  'ܐܒܠܬܐ',
  'ܝܙܠܝܩ',
  'ܝܩܘܢܐ',
  'ܚܨܪܘܬ',
  'ܡܥܝܢܐ',
  'ܓܘܢܪܐ',
  'ܦܫܛܢܐ',
  'ܡܫܟܢܐ',
  'ܢܘܨܪܐ',
  'ܟܘܡܠܐ',
  'ܚܘܣܢܐ',
  'ܡܬܒܢܐ',
  'ܒܕܥܒܪ',
  'ܫܠܓܢܐ',
  'ܩܪܘܫܐ',
  'ܥܘܡܠܐ',
  'ܙܕܢܬܐ',
  'ܙܘܝܦܐ',
  'ܓܕܝܪܐ',
  'ܒܗܘܬܐ',
  'ܡܘܙܘܙ',
  'ܡܬܪܣܐ',
  'ܒܚܘܠܐ',
  'ܛܢܘܢܐ',
  'ܬܪܥܬܐ',
  'ܡܛܪܝܐ',
  'ܓܠܝܚܐ',
  'ܠܒܘܒܐ',
  'ܡܫܪܫܛ',
  'ܡܛܪܒܫ',
  'ܕܘܗܢܐ',
  'ܕܗܚܝܟ',
  'ܚܒܪܬܐ',
  'ܫܦܬܝܐ',
  'ܡܫܘܥܐ',
  'ܢܛܘܚܐ',
  'ܗܒܝܬܐ',
  'ܚܪܫܢܐ',
  'ܣܒܒܟܪ',
  'ܗܩܘܬܐ',
  'ܐܛܝܡܐ',
  'ܓܪܡܝܐ',
  'ܕܝܘܫܐ',
  'ܠܘܐܝܣ',
  'ܐܣܩܛܐ',
  'ܡܠܚܝܐ',
  'ܟܘܚܠܐ',
  'ܡܘܪܒܥ',
  'ܩܘܡܪܐ',
  'ܢܫܡܬܐ',
  'ܡܙܢܙܠ',
  'ܦܝܠܟܣ',
  'ܩܘܨܦܐ',
  'ܡܝܩܪܐ',
  'ܚܘܠܠܐ',
  'ܩܘܘܬܐ',
  'ܠܠܝܝܐ',
  'ܡܠܚܠܚ',
  'ܣܠܒܢܐ',
  'ܩܥܠܝܐ',
  'ܡܣܒܬܐ',
  'ܫܥܝܢܐ',
  'ܐܦܙܓܐ',
  'ܡܥܕܢܐ',
  'ܕܠܟܘܢ',
  'ܗܡܫܬܝ',
  'ܢܘܕܪܐ',
  'ܫܒܝܚܐ',
  'ܒܬܢܝܐ',
  'ܫܒܝܠܐ',
  'ܐܝܩܪܐ',
  'ܓܝܠܣܐ',
  'ܒܪܥܟܣ',
  'ܣܢܝܬܐ',
  'ܡܨܘܬܐ',
  'ܪܚܘܩܐ',
  'ܦܬܝܚܐ',
  'ܓܒܬܢܐ',
  'ܒܘܢܟܐ',
  'ܩܪܘܛܐ',
  'ܚܘܫܚܐ',
  'ܩܨܝܠܐ',
  'ܒܠܣܢܐ',
  'ܕܪܝܙܐ',
  'ܬܐܝܦܐ',
  'ܟܚܘܕܐ',
  'ܬܪܟܝܬ',
  'ܫܪܝܛܐ',
  'ܣܢܝܬܐ',
  'ܕܪܘܢܓ',
  'ܛܘܢܘܣ',
  'ܕܘܠܠܐ',
  'ܬܝܡܬܐ',
  'ܡܛܝܒܐ',
  'ܡܠܚܒܐ',
  'ܕܘܢܚܐ',
  'ܗܝܡܘܢ',
  'ܙܠܦܬܐ',
  'ܛܡܝܡܐ',
  'ܡܟܪܡܟ',
  'ܥܝܘܪܐ',
  'ܝܘܪܝܫ',
  'ܦܗܘܪܐ',
  'ܒܘܩܬܐ',
  'ܐܣܐܘܬ',
  'ܡܗܢܝܐ',
  'ܡܝܘܟܐ',
  'ܥܨܦܝܪ',
  'ܚܠܢܬܐ',
  'ܢܘܠܬܐ',
  'ܓܢܘܢܐ',
  'ܙܩܘܬܐ',
  'ܗܓܘܡܐ',
  'ܡܨܝܢܐ',
  'ܚܠܒܢܐ',
  'ܪܓܘܕܐ',
  'ܡܩܪܩܦ',
  'ܚܘܙܝܟ',
  'ܐܘܪܘܣ',
  'ܙܪܩܛܐ',
  'ܟܠܫܬܐ',
  'ܟܟܠܢܐ',
  'ܪܣܘܝܝ',
  'ܙܘܒܢܐ',
  'ܕܘܠܬܐ',
  'ܡܙܛܠܛ',
  'ܬܪܓܘܪ',
  'ܒܒܘܢܐ',
  'ܐܣܩܦܐ',
  'ܩܢܝܛܐ',
  'ܩܠܝܬܐ',
  'ܢܩܘܫܐ',
  'ܩܘܝܡܐ',
  'ܗܪܗܪܐ',
  'ܡܘܚܒܐ',
  'ܡܠܐܟܝ',
  'ܕܚܝܚܐ',
  'ܐܪܟܘܣ',
  'ܐܕܘܫܐ',
  'ܢܟܘܠܐ',
  'ܬܥܝܫܐ',
  'ܛܘܠܝܐ',
  'ܡܠܝܢܐ',
  'ܡܫܝܢܐ',
  'ܣܢܒܠܛ',
  'ܒܕܚܘܝ',
  'ܪܟܝܢܐ',
  'ܝܠܕܬܐ',
  'ܥܡܘܪܐ',
  'ܚܢܛܬܐ',
  'ܡܙܐܬܐ',
  'ܢܘܛܠܐ',
  'ܚܕܡܬܐ',
  'ܫܠܝܝܐ',
  'ܬܩܨܝܪ',
  'ܦܪܟܬܐ',
  'ܫܘܒܝܐ',
  'ܕܐܝܟܐ',
  'ܢܝܢܪܐ',
  'ܡܪܝܒܐ',
  'ܥܕܘܩܐ',
  'ܐܢܩܬܐ',
  'ܚܦܘܦܐ',
  'ܚܪܕܢܐ',
  'ܡܠܘܫܐ',
  'ܕܣܬܩܐ',
  'ܚܫܚܬܐ',
  'ܪܥܕܬܐ',
  'ܢܒܝܬܐ',
  'ܩܕܘܝܐ',
  'ܩܦܘܝܐ',
  'ܐܝܘܗܐ',
  'ܐܓܘܪܐ',
  'ܛܡܘܪܐ',
  'ܣܘܣܬܐ',
  'ܚܝܡܘܢ',
  'ܣܒܙܘܬ',
  'ܥܙܘܠܐ',
  'ܐܫܝܠܐ',
  'ܬܓܠܝܕ',
  'ܪܡܘܝܐ',
  'ܟܠܢܝܐ',
  'ܚܘܚܬܐ',
  'ܣܓܘܦܐ',
  'ܠܓܝܢܐ',
  'ܫܡܘܢܝ',
  'ܢܗܘܣܐ',
  'ܥܪܒܬܐ',
  'ܡܐܝܟܐ',
  'ܥܠܘܝܐ',
  'ܚܢܘܟܐ',
  'ܒܘܣܪܐ',
  'ܡܟܘܬܐ',
  'ܫܪܪܬܐ',
  'ܨܦܘܢܐ',
  'ܓܪܘܡܐ',
  'ܡܘܟܟܐ',
  'ܬܠܣܝܡ',
  'ܡܘܩܝܬ',
  'ܚܠܩܝܐ',
  'ܟܢܐܪܐ',
  'ܡܥܩܡܐ',
  'ܛܠܝܚܐ',
  'ܡܓܠܬܐ',
  'ܗܘܢܢܐ',
  'ܕܘܥܪܐ',
  'ܥܝܘܩܐ',
  'ܢܛܪܢܐ',
  'ܩܪܩܣܐ',
  'ܝܪܘܬܐ',
  'ܬܟܘܠܐ',
  'ܩܘܡܛܐ',
  'ܠܝܠܐܝ',
  'ܩܘܪܨܢ',
  'ܥܩܪܒܐ',
  'ܩܝܨܬܐ',
  'ܒܘܙܥܐ',
  'ܛܐܠܬܐ',
  'ܡܬܡܬܡ',
  'ܣܒܘܬܐ',
  'ܐܟܕܢܐ',
  'ܗܘܪܦܐ',
  'ܡܣܘܩܐ',
  'ܟܢܝܟܐ',
  'ܒܢܘܒܐ',
  'ܪܐܘܡܐ',
  'ܬܘܩܢܐ',
  'ܓܕܘܠܐ',
  'ܩܕܠܬܐ',
  'ܢܩܘܕܐ',
  'ܚܦܪܬܐ',
  'ܕܛܫܘܐ',
  'ܡܘܫܝܐ',
  'ܡܠܬܡܐ',
  'ܡܫܥܠܐ',
  'ܐܘܘܢܐ',
  'ܟܬܪܬܐ',
  'ܪܫܝܡܐ',
  'ܙܟܘܝܐ',
  'ܥܝܪܬܐ',
  'ܡܚܪܡܐ',
  'ܡܠܚܒܐ',
  'ܥܪܝܨܐ',
  'ܬܠܚܬܐ',
  'ܥܝܘܬܐ',
  'ܡܨܠܗܬ',
  'ܐܘܘܢܐ',
  'ܣܦܐܪܫ',
  'ܣܪܟܝܝ',
  'ܫܓܪܬܐ',
  'ܬܪܨܬܐ',
  'ܒܣܕܝܐ',
  'ܡܬܪܬܒ',
  'ܪܫܦܬܐ',
  'ܢܛܦܬܐ',
  'ܢܐܢܪܐ',
  'ܡܕܪܫܐ',
  'ܩܪܩܝܐ',
  'ܟܬܝܡܐ',
  'ܛܘܒܝܛ',
  'ܫܒܘܠܐ',
  'ܡܙܦܙܦ',
  'ܩܘܦܣܐ',
  'ܓܘܙܠܐ',
  'ܡܒܛܪܢ',
  'ܨܘܘܪܐ',
  'ܩܒܠܢܐ',
  'ܚܠܘܢܐ',
  'ܝܨܘܦܐ',
  'ܬܘܗܝܐ',
  'ܟܕܝܓܕ',
  'ܟܘܣܦܐ',
  'ܐܛܘܡܐ',
  'ܟܠܘܛܐ',
  'ܥܙܝܙܝ',
  'ܡܒܠܩܛ',
  'ܚܝܛܢܐ',
  'ܪܥܘܙܐ',
  'ܓܝܠܢܪ',
  'ܙܘܢܪܐ',
  'ܫܪܥܒܙ',
  'ܢܘܚܡܐ',
  'ܙܝܦܬܐ',
  'ܟܘܪܒܫ',
  'ܟܠܝܗܝ',
  'ܙܗܘܪܐ',
  'ܚܐܪܬܐ',
  'ܪܟܘܒܐ',
  'ܐܫܚܘܪ',
  'ܡܠܝܘܢ',
  'ܨܡܘܕܐ',
  'ܒܡܬܚܐ',
  'ܩܒܠܕܪ',
  'ܠܠܝܬܐ',
  'ܩܘܨܪܐ',
  'ܙܘܓܪܐ',
  'ܥܘܢܝܐ',
  'ܟܘܦܬܐ',
  'ܡܝܢܝܝ',
  'ܛܪܣܘܣ',
  'ܡܨܘܦܐ',
  'ܡܕܟܝܐ',
  'ܡܢܫܘܪ',
  'ܬܘܟܡܗ',
  'ܡܕܪܬܐ',
  'ܡܬܠܬܐ',
  'ܒܗܠܘܠ',
  'ܩܬܝܗܝ',
  'ܣܪܕܫܬ',
  'ܦܥܘܠܐ',
  'ܙܚܘܪܐ',
  'ܙܢܝܬܐ',
  'ܢܚܘܬܐ',
  'ܡܬܦܟܪ',
  'ܫܘܚܢܐ',
  'ܢܣܪܬܐ',
  'ܣܘܠܘܪ',
  'ܡܢܦܘܣ',
  'ܡܙܘܘܐ',
  'ܡܛܠܛܠ',
  'ܒܙܚܢܐ',
  'ܡܦܪܓܣ',
  'ܐܣܛܪܐ',
  'ܕܡܝܝܐ',
  'ܩܪܒܠܓ',
  'ܚܒܝܬܐ',
  'ܫܘܩܬܐ',
  'ܘܠܟܘܢ',
  'ܐܠܝܠܐ',
  'ܡܚܠܚܕ',
  'ܬܚܦܢܣ',
  'ܨܦܘܦܐ',
  'ܣܩܘܡܐ',
  'ܡܩܠܝܐ',
  'ܒܐܝܪܐ',
  'ܒܘܠܡܐ',
  'ܐܗܪܡܢ',
  'ܢܫܝܬܐ',
  'ܡܣܐܢܐ',
  'ܒܪܝܝܐ',
  'ܡܛܡܪܐ',
  'ܩܪܝܢܐ',
  'ܬܒܥܬܐ',
  'ܩܝܢܬܐ',
  'ܡܫܠܗܡ',
  'ܩܘܦܝܐ',
  'ܝܒܘܠܐ',
  'ܥܨܘܝܐ',
  'ܡܪܟܒܐ',
  'ܡܟܪܒܛ',
  'ܫܝܛܬܐ',
  'ܚܕܡܬܐ',
  'ܥܒܝܬܐ',
  'ܚܘܓܪܐ',
  'ܡܡܫܚܐ',
  'ܫܡܥܘܢ',
  'ܩܒܝܒܐ',
  'ܚܠܘܬܐ',
  'ܚܬܘܬܐ',
  'ܬܒܝܢܐ',
  'ܡܛܪܝܐ',
  'ܒܪܥܡܐ',
  'ܫܚܢܬܐ',
  'ܝܣܪܝܠ',
  'ܟܣܠܢܝ',
  'ܩܛܝܣܐ',
  'ܣܚܪܬܐ',
  'ܡܟܘܠܐ',
  'ܡܗܪܫܢ',
  'ܒܘܙܢܐ',
  'ܠܙܘܙܐ',
  'ܒܠܘܛܐ',
  'ܣܠܝܒܐ',
  'ܦܘܪܝܐ',
  'ܪܘܘܚܐ',
  'ܟܪܘܒܐ',
  'ܣܥܪܢܐ',
  'ܡܢܫܝܐ',
  'ܓܠܝܠܐ',
  'ܨܪܝܟܐ',
  'ܓܠܥܬܐ',
  'ܐܟܬܢܐ',
  'ܕܘܓܢܓ',
  'ܩܘܪܘܓ',
  'ܡܕܪܢܐ',
  'ܡܒܪܣܡ',
  'ܡܓܙܝܐ',
  'ܛܘܪܦܐ',
  'ܟܒܠܢܐ',
  'ܓܠܒܬܐ',
  'ܝܘܢܕܒ',
  'ܩܝܛܢܐ',
  'ܡܡܬܘܡ',
  'ܩܪܝܬܐ',
  'ܦܣܠܛܐ',
  'ܐܚܪܘܗ',
  'ܡܘܩܠܐ',
  'ܚܡܝܠܐ',
  'ܦܫܝܥܐ',
  'ܛܥܢܬܐ',
  'ܓܘܦܠܐ',
  'ܕܠܝܠܐ',
  'ܬܪܝܟܐ',
  'ܡܣܪܬܢ',
  'ܚܪܝܢܐ',
  'ܢܘܒܕܪ',
  'ܐܢܢܣܐ',
  'ܣܛܘܪܐ',
  'ܫܚܬܢܐ',
  'ܚܘܣܕܐ',
  'ܬܡܡܬܐ',
  'ܛܗܪܝܐ',
  'ܥܩܠܕܪ',
  'ܥܙܬܝܐ',
  'ܫܘܩܘܩ',
  'ܝܘܪܩܐ',
  'ܟܪܘܒܐ',
  'ܢܫܩܬܐ',
  'ܡܓܙܢܐ',
  'ܢܓܪܢܐ',
  'ܣܘܘܪܐ',
  'ܐܘܦܬܐ',
  'ܪܘܣܬܡ',
  'ܬܝܢܬܐ',
  'ܡܚܨܘܠ',
  'ܡܕܘܡܐ',
  'ܚܓܘܠܐ',
  'ܐܬܬܓܪ',
  'ܓܪܝܡܐ',
  'ܬܫܥܝܒ',
  'ܒܩܝܬܐ',
  'ܦܣܩܘܢ',
  'ܨܗܒܟܪ',
  'ܕܐܕܝܐ',
  'ܡܠܝܓܐ',
  'ܛܒܝܬܐ',
  'ܬܢܘܪܐ',
  'ܕܝܘܪܐ',
  'ܣܪܩܝܐ',
  'ܪܦܝܬܐ',
  'ܬܢܫܡܬ',
  'ܙܒܢܝܐ',
  'ܬܘܪܨܐ',
  'ܟܬܒܬܐ',
  'ܪܫܝܢܐ',
  'ܙܘܩܠܐ',
  'ܟܪܘܠܐ',
  'ܕܪܓܪܐ',
  'ܣܘܪܣܐ',
  'ܫܒܘܚܐ',
  'ܠܘܬܟܐ',
  'ܡܐܪܓܫ',
  'ܣܘܪܝܐ',
  'ܡܢܬܢܐ',
  'ܛܠܢܝܐ',
  'ܪܘܥܡܐ',
  'ܡܫܟܪܐ',
  'ܚܨܘܝܐ',
  'ܩܪܚܒܪ',
  'ܟܪܛܢܐ',
  'ܐܫܛܪܐ',
  'ܡܠܡܠܡ',
  'ܐܘܟܡܐ',
  'ܝܠܘܙܐ',
  'ܪܘܚܣܬ',
  'ܫܘܚܝܐ',
  'ܠܘܒܢܐ',
  'ܪܘܝܙܐ',
  'ܡܚܫܒܢ',
  'ܬܟܠܝܦ',
  'ܡܘܫܡܐ',
  'ܣܩܘܡܐ',
  'ܡܬܘܝܐ',
  'ܪܩܕܬܐ',
  'ܟܪܣܢܐ',
  'ܕܘܝܬܐ',
  'ܒܗܘܓܪ',
  'ܢܫܝܫܐ',
  'ܒܪܫܝܬ',
  'ܣܟܝܢܐ',
  'ܫܒܥܣܪ',
  'ܨܒܝܬܐ',
  'ܙܝܘܕܐ',
  'ܩܣܘܒܐ',
  'ܡܩܛܠܒ',
  'ܚܕܝܬܐ',
  'ܫܘܪܒܐ',
  'ܚܒܝܫܐ',
  'ܡܪܩܬܐ',
  'ܡܘܫܢܐ',
  'ܥܩܪܫܐ',
  'ܩܘܛܦܐ',
  'ܦܐܘܛܐ',
  'ܓܪܘܥܐ',
  'ܟܘܕܢܐ',
  'ܪܥܝܠܐ',
  'ܡܘܚܒܐ',
  'ܡܥܘܒܐ',
  'ܡܠܩܠܩ',
  'ܡܩܠܘܢ',
  'ܢܘܫܦܐ',
  'ܡܨܪܬܐ',
  'ܣܘܕܬܐ',
  'ܫܒܛܝܐ',
  'ܡܘܣܬܐ',
  'ܚܒܛܬܐ',
  'ܦܪܘܩܐ',
  'ܢܘܦܪܐ',
  'ܨܘܝܚܐ',
  'ܢܓܘܕܐ',
  'ܡܫܠܛܐ',
  'ܚܡܪܬܐ',
  'ܫܘܫܡܪ',
  'ܩܨܘܨܐ',
  'ܐܒܩܝܐ',
  'ܣܘܟܠܐ',
  'ܡܠܙܢܐ',
  'ܪܐܬܢܐ',
  'ܠܝܘܛܐ',
  'ܚܛܝܦܐ',
  'ܕܥܘܟܐ',
  'ܐܝܡܢܐ',
  'ܟܦܪܬܐ',
  'ܪܩܘܥܐ',
  'ܢܘܬܫܐ',
  'ܕܒܚܬܐ',
  'ܫܪܚܬܐ',
  'ܡܕܪܒܢ',
  'ܣܦܫܟܐ',
  'ܣܘܦܝܐ',
  'ܒܪܕܢܐ',
  'ܦܪܘܡܐ',
  'ܛܘܟܢܐ',
  'ܪܦܝܬܐ',
  'ܝܚܡܘܪ',
  'ܙܪܒܢܐ',
  'ܓܕܢܦܐ',
  'ܡܚܢܝܡ',
  'ܗܪܣܝܣ',
  'ܡܠܬܝܐ',
  'ܣܝܦܗܐ',
  'ܩܙܡܬܐ',
  'ܣܡܘܩܐ',
  'ܩܘܪܠܐ',
  'ܡܟܝܢܐ',
  'ܚܘܪܡܐ',
  'ܨܠܦܬܐ',
  'ܫܝܘܦܐ',
  'ܡܓܪܓܡ',
  'ܙܡܘܥܐ',
  'ܫܘܘܝܐ',
  'ܟܘܝܠܐ',
  'ܚܟܢܕܙ',
  'ܥܒܪܝܐ',
  'ܨܒܐܘܬ',
  'ܒܥܠܬܐ',
  'ܬܠܩܢܐ',
  'ܡܟܪܡܢ',
  'ܢܘܩܘܠ',
  'ܚܘܓܝܐ',
  'ܣܡܘܪܐ',
  'ܫܘܒܚܐ',
  'ܣܝܘܡܐ',
  'ܐܩܩܫܐ',
  'ܟܘܬܘܓ',
  'ܪܡܙܬܐ',
  'ܡܬܘܚܪ',
  'ܪܟܒܝܡ',
  'ܫܚܡܬܐ',
  'ܝܣܡܝܢ',
  'ܦܪܡܬܐ',
  'ܓܢܝܒܐ',
  'ܚܠܘܨܐ',
  'ܡܪܥܝܐ',
  'ܐܦܕܢܐ',
  'ܚܠܘܦܐ',
  'ܟܟܝܬܐ',
  'ܨܪܘܦܐ',
  'ܥܘܕܠܐ',
  'ܒܥܘܠܗ',
  'ܕܘܪܢܐ',
  'ܚܕܡܬܐ',
  'ܡܫܠܛܐ',
  'ܫܘܘܫܐ',
  'ܡܗܝܪܐ',
  'ܣܥܬܣܙ',
  'ܐܪܒܝܠ',
  'ܥܒܘܝܐ',
  'ܫܠܩܬܐ',
  'ܢܝܪܬܐ',
  'ܥܪܝܢܐ',
  'ܡܘܒܠܐ',
  'ܦܟܪܬܐ',
  'ܟܢܫܬܐ',
  'ܐܣܦܢܟ',
  'ܩܘܦܠܐ',
  'ܒܩܝܬܐ',
  'ܡܓܪܘܐ',
  'ܚܬܝܬܐ',
  'ܙܘܪܢܐ',
  'ܩܬܘܗܝ',
  'ܝܡܝܡܐ',
  'ܦܚܠܬܐ',
  'ܦܪܓܢܐ',
  'ܐܦܝܘܡ',
  'ܙܘܡܪܐ',
  'ܒܪܡܠܐ',
  'ܠܬܟܝܐ',
  'ܫܒܝܒܐ',
  'ܩܣܝܛܐ',
  'ܬܚܘܬܐ',
  'ܥܩܝܣܐ',
  'ܝܒܫܢܐ',
  'ܓܥܝܨܐ',
  'ܛܪܘܝܐ',
  'ܡܠܛܠܛ',
  'ܦܝܪܝܙ',
  'ܒܪܝܨܐ',
  'ܓܪܒܢܐ',
  'ܨܘܪܦܐ',
  'ܬܠܘܫܐ',
  'ܩܝܘܪܐ',
  'ܗܠܩܬܐ',
  'ܡܗܪܕܦ',
  'ܗܡܝܪܐ',
  'ܡܙܪܙܛ',
  'ܛܢܘܦܐ',
  'ܐܠܩܘܫ',
  'ܕܓܕܫܐ',
  'ܚܝܠܢܐ',
  'ܠܣܟܪܬ',
  'ܩܪܝܒܐ',
  'ܦܫܘܪܐ',
  'ܐܠܬܚܬ',
  'ܠܗܓܝܐ',
  'ܒܚܪܢܐ',
  'ܕܘܪܥܐ',
  'ܗܠܩܢܐ',
  'ܡܦܪܚܐ',
  'ܡܕܡܕܟ',
  'ܡܘܪܕܪ',
  'ܩܝܡܬܐ',
  'ܥܨܝܨܐ',
  'ܥܝܢܬܐ',
  'ܪܘܚܢܐ',
  'ܡܙܠܩܐ',
  'ܐܓܘܢܐ',
  'ܒܘܙܙܐ',
  'ܙܠܡܬܐ',
  'ܛܝܐܝܬ',
  'ܡܠܟܝܐ',
  'ܐܫܬܚܪ',
  'ܚܝܒܢܕ',
  'ܦܫܙܝܢ',
  'ܥܠܘܒܐ',
  'ܣܒܥܝܐ',
  'ܣܪܝܘܢ',
  'ܫܐܗܝܢ',
  'ܚܒܝܬܐ',
  'ܫܟܘܟܐ',
  'ܟܪܕܘܙ',
  'ܩܫܝܛܐ',
  'ܒܚܘܫܐ',
  'ܣܘܩܠܐ',
  'ܪܗܛܪܐ',
  'ܒܓܙܕܐ',
  'ܟܘܡܪܐ',
  'ܓܪܘܫܐ',
  'ܡܘܟܠܐ',
  'ܡܐܬܝܐ',
  'ܩܦܘܫܐ',
  'ܒܙܕܩܐ',
  'ܦܘܫܫܐ',
  'ܡܫܝܢܐ',
  'ܓܙܝܪܐ',
  'ܬܢܚܘܣ',
  'ܫܚܘܩܐ',
  'ܐܓܘܪܐ',
  'ܪܓܘܡܐ',
  'ܕܝܡܬܐ',
  'ܚܠܐܦܐ',
  'ܫܕܪܬܐ',
  'ܙܘܠܢܐ',
  'ܨܪܦܬܐ',
  'ܣܘܪܗܝ',
  'ܨܝܛܠܐ',
  'ܩܘܪܝܐ',
  'ܛܦܢܬܐ',
  'ܟܘܗܢܐ',
  'ܥܒܕܬܐ',
  'ܒܢܝܬܐ',
  'ܛܫܝܬܐ',
  'ܦܕܝܥܐ',
  'ܥܢܝܢܐ',
  'ܙܝܘܢܐ',
  'ܙܓܘܓܐ',
  'ܣܡܩܬܐ',
  'ܦܝܫܬܐ',
  'ܓܡܝܡܐ',
  'ܚܠܠܢܐ',
  'ܐܪܘܢܐ',
  'ܡܟܪܒܫ',
  'ܝܪܒܘܥ',
  'ܢܪܘܢܕ',
  'ܒܪܘܕܐ',
  'ܟܘܡܬܐ',
  'ܡܢܙܢܙ',
  'ܩܝܘܡܐ',
  'ܦܘܠܘܫ',
  'ܨܠܝܬܐ',
  'ܠܡܣܝܐ',
  'ܫܪܘܪܐ',
  'ܩܗܘܕܢ',
  'ܓܗܝܬܐ',
  'ܩܗܪܢܐ',
  'ܡܚܪܡܫ',
  'ܘܪܘܪܐ',
  'ܫܫܢܬܐ',
  'ܚܢܙܝܪ',
  'ܓܫܝܡܐ',
  'ܗܝܘܪܐ',
  'ܩܪܐܘܠ',
  'ܓܫܘܚܐ',
  'ܣܩܠܢܐ',
  'ܟܘܪܚܐ',
  'ܚܪܘܟܐ',
  'ܫܘܝܬܐ',
  'ܡܦܪܬܟ',
  'ܡܕܪܕܪ',
  'ܡܦܘܩܐ',
  'ܬܪܘܚܐ',
  'ܬܩܘܦܐ',
  'ܫܘܬܠܐ',
  'ܒܘܩܠܐ',
  'ܕܢܕܢܐ',
  'ܡܦܡܦܟ',
  'ܐܟܘܬܗ',
  'ܓܠܝܙܬ',
  'ܦܪܬܩܐ',
  'ܪܒܝܨܐ',
  'ܩܛܠܬܐ',
  'ܕܘܪܪܐ',
  'ܗܘܠܬܐ',
  'ܫܘܒܛܐ',
  'ܕܒܩܢܐ',
  'ܠܘܥܙܐ',
  'ܕܘܕܝܐ',
  'ܠܒܟܬܐ',
  'ܨܕܘܝܐ',
  'ܨܦܪܬܐ',
  'ܛܦܐܒܝ',
  'ܐܒܥܝܐ',
  'ܡܪܗܛܐ',
  'ܥܒܝܬܐ',
  'ܢܩܘܠܐ',
  'ܣܘܪܣܕ',
  'ܡܢܝܬܐ',
  'ܪܟܝܒܐ',
  'ܡܗܕܪܐ',
  'ܐܢܢܬܐ',
  'ܐܘܡܝܕ',
  'ܡܫܦܠܦ',
  'ܦܘܪܬܐ',
  'ܣܛܩܛܐ',
  'ܒܪܘܬܐ',
  'ܦܩܘܪܐ',
  'ܡܩܪܨܢ',
  'ܫܒܘܩܐ',
  'ܐܘܪܝܢ',
  'ܓܗܢܝܐ',
  'ܡܓܡܒܠ',
  'ܡܗܡܙܡ',
  'ܫܘܪܥܐ',
  'ܡܣܐܬܐ',
  'ܬܘܚܡܩ',
  'ܕܝܪܝܐ',
  'ܚܕܪܬܐ',
  'ܓܘܓܚܐ',
  'ܫܢܘܢܐ',
  'ܛܟܘܢܐ',
  'ܡܘܕܚܠ',
  'ܡܘܠܦܐ',
  'ܒܐܕܪܐ',
  'ܓܘܢܒܙ',
  'ܡܕܪܘܕ',
  'ܚܪܘܬܐ',
  'ܠܗܝܬܐ',
  'ܡܝܘܪܐ',
  'ܫܘܠܡܬ',
  'ܐܠܒܬܐ',
  'ܩܘܒܘܣ',
  'ܓܒܘܥܐ',
  'ܓܝܫܪܐ',
  'ܦܪܢܣܬ',
  'ܛܡܫܢܐ',
  'ܙܩܦܬܐ',
  'ܦܘܚܪܐ',
  'ܕܟܢܕܪ',
  'ܨܠܝܢܐ',
  'ܡܙܬܢܐ',
  'ܥܪܢܢܐ',
  'ܪܚܦܢܐ',
  'ܫܢܕܠܐ',
  'ܢܥܘܪܐ',
  'ܫܪܝܡܐ',
  'ܪܬܚܬܐ',
  'ܐܓܒܢܐ',
  'ܒܘܫܝܝ',
  'ܡܘܠܘܠ',
  'ܡܦܪܬܫ',
  'ܕܣܬܘܪ',
  'ܪܓܝܫܐ',
  'ܟܘܦܪܐ',
  'ܡܠܘܟܐ',
  'ܨܦܘܢܐ',
  'ܢܗܝܪܐ',
  'ܒܕܡܘܬ',
  'ܢܟܝܦܐ',
  'ܣܠܒܬܐ',
  'ܒܝܨܘܪ',
  'ܨܗܪܘܐ',
  'ܠܘܒܠܐ',
  'ܟܣܚܬܐ',
  'ܚܕܪܬܐ',
  'ܟܠܘܬܐ',
  'ܪܒܘܢܝ',
  'ܫܕܘܝܐ',
  'ܣܠܘܟܬ',
  'ܥܘܝܡܐ',
  'ܨܘܨܠܐ',
  'ܟܐܡܝܠ',
  'ܓܥܘܡܐ',
  'ܡܙܪܒܢ',
  'ܥܪܙܠܐ',
  'ܥܘܠܘܠ',
  'ܚܙܘܦܐ',
  'ܡܕܪܬܐ',
  'ܛܥܘܦܐ',
  'ܫܪܝܬܐ',
  'ܚܪܚܪܐ',
  'ܫܥܝܥܐ',
  'ܩܐܦܛܐ',
  'ܡܣܚܪܐ',
  'ܝܕܘܥܐ',
  'ܩܙܘܪܐ',
  'ܥܙܝܬܐ',
  'ܐܝܡܐܡ',
  'ܐܨܘܬܐ',
  'ܣܡܘܪܐ',
  'ܪܥܠܬܐ',
  'ܩܝܨܬܐ',
  'ܦܘܩܥܐ',
  'ܣܪܢܘܣ',
  'ܪܨܘܦܐ',
  'ܟܡܟܢܐ',
  'ܕܘܪܬܐ',
  'ܕܡܢܬܐ',
  'ܒܥܝܩܘ',
  'ܒܪܝܬܐ',
  'ܓܕܘܫܐ',
  'ܒܚܬܘܪ',
  'ܫܗܥܒܣ',
  'ܩܪܛܠܐ',
  'ܫܟܘܫܐ',
  'ܟܘܬܒܐ',
  'ܐܡܝܢܐ',
  'ܟܠܠܬܐ',
  'ܪܘܡܚܐ',
  'ܟܪܫܠܐ',
  'ܣܬܘܬܐ',
  'ܐܬܦܚܚ',
  'ܫܘܢܝܐ',
  'ܦܩܕܢܐ',
  'ܕܩܝܣܐ',
  'ܚܦܝܪܐ',
  'ܦܩܘܕܐ',
  'ܙܒܝܢܐ',
  'ܡܝܠܢܐ',
  'ܕܘܐܕܘ',
  'ܙܩܘܬܐ',
  'ܨܦܢܝܐ',
  'ܫܫܚܢܐ',
  'ܥܓܝܢܐ',
  'ܬܡܒܘܪ',
  'ܣܢܘܢܐ',
  'ܡܘܪܫܐ',
  'ܓܘܥܡܐ',
  'ܒܝܢܩܐ',
  'ܡܦܪܡܐ',
  'ܚܠܝܡܐ',
  'ܩܝܢܝܐ',
  'ܚܫܒܬܐ',
  'ܐܒܐܪܬ',
  'ܕܘܠܠܐ',
  'ܒܪܝܢܐ',
  'ܙܕܝܩܐ',
  'ܡܓܢܓܙ',
  'ܩܠܘܬܐ',
  'ܕܘܪܬܐ',
  'ܚܪܒܢܐ',
  'ܚܘܕܬܐ',
  'ܦܟܪܬܐ',
  'ܒܥܘܠܐ',
  'ܢܩܕܬܐ',
  'ܓܒܝܢܐ',
  'ܠܣܘܛܪ',
  'ܒܪܘܝܐ',
  'ܡܣܘܣܐ',
  'ܩܘܨܥܐ',
  'ܝܬܝܬܐ',
  'ܒܘܪܢܝ',
  'ܪܚܡܢܐ',
  'ܫܘܚܛܐ',
  'ܙܘܘܕܐ',
  'ܕܩܘܩܐ',
  'ܦܪܘܓܐ',
  'ܪܬܝܚܐ',
  'ܚܙܐܝܠ',
  'ܦܪܘܪܝ',
  'ܣܪܓܝܣ',
  'ܙܪܘܒܐ',
  'ܒܘܠܓܐ',
  'ܦܓܘܪܐ',
  'ܨܘܦܢܐ',
  'ܩܛܦܬܐ',
  'ܡܢܫܠܝ',
  'ܒܟܬܬܐ',
  'ܝܝܙܝܕ',
  'ܟܒܓܝܪ',
  'ܒܠܝܥܠ',
  'ܓܠܝܠܐ',
  'ܫܠܚܢܐ',
  'ܡܗܦܟܐ',
  'ܒܘܚܪܝ',
  'ܣܘܚܦܐ',
  'ܫܝܘܛܐ',
  'ܕܘܢܩܐ',
  'ܐܘܝܠܐ',
  'ܪܝܚܢܐ',
  'ܦܫܝܟܐ',
  'ܩܘܠܒܐ',
  'ܡܢܕܪܐ',
  'ܫܡܟܬܐ',
  'ܓܘܪܡܐ',
  'ܡܠܢܓܠ',
  'ܐܢܚܘܐ',
  'ܕܝܢܪܐ',
  'ܬܩܢܬܐ',
  'ܣܦܢܝܐ',
  'ܩܘܢܡܐ',
  'ܡܚܠܬܐ',
  'ܨܕܪܝܐ',
  'ܡܐܡܪܐ',
  'ܟܒܕܢܐ',
  'ܣܘܘܚܐ',
  'ܬܘܪܟܝ',
  'ܠܐܢܫܐ',
  'ܥܘܣܩܐ',
  'ܒܨܠܬܐ',
  'ܥܘܛܪܕ',
  'ܦܪܝܣܐ',
  'ܦܪܘܫܐ',
  'ܨܘܚܝܐ',
  'ܫܪܝܟܐ',
  'ܓܚܘܟܐ',
  'ܩܠܘܙܐ',
  'ܓܠܘܙܐ',
  'ܢܫܡܬܐ',
  'ܡܫܘܫܐ',
  'ܩܘܢܕܩ',
  'ܓܨܘܨܐ',
  'ܨܝܡܬܐ',
  'ܣܪܣܡܝ',
  'ܬܪܢܓܐ',
  'ܠܘܟܡܐ',
  'ܡܐܕܝܐ',
  'ܡܬܪܒܢ',
  'ܟܦܘܕܐ',
  'ܦܘܬܫܐ',
  'ܢܦܐܫܐ',
  'ܩܪܩܪܐ',
  'ܦܠܓܬܐ',
  'ܫܛܘܪܐ',
  'ܡܦܬܘܠ',
  'ܣܠܝܬܐ',
  'ܙܪܕܐܒ',
  'ܟܪܘܬܐ',
  'ܩܪܟܬܐ',
  'ܐܠܝܘܢ',
  'ܬܘܢܝܠ',
  'ܠܓܠܝܐ',
  'ܓܓܪܝܐ',
  'ܩܕܫܬܐ',
  'ܒܐܪܠܓ',
  'ܩܘܠܦܐ',
  'ܝܘܡܝܐ',
  'ܡܪܚܬܐ',
  'ܡܚܘܝܐ',
  'ܗܦܟܝܐ',
  'ܨܘܬܝܐ',
  'ܡܛܠܬܐ',
  'ܗܘܝܘܬ',
  'ܬܢܒܟܝ',
  'ܦܩܪܬܐ',
  'ܩܒܝܬܐ',
  'ܚܪܝܝܐ',
  'ܩܝܘܡܐ',
  'ܥܘܦܝܐ',
  'ܒܘܗܛܢ',
  'ܚܘܩܒܙ',
  'ܬܐܪܝܚ',
  'ܢܨܒܬܐ',
  'ܥܡܡܬܐ',
  'ܝܚܝܕܐ',
  'ܡܟܫܬܐ',
  'ܫܒܩܢܐ',
  'ܩܛܘܬܐ',
  'ܟܬܪܬܐ',
  'ܙܘܗܪܐ',
  'ܚܡܩܬܐ',
  'ܦܙܝܙܐ',
  'ܩܠܡܢܐ',
  'ܩܘܢܐܐ',
  'ܒܫܠܬܐ',
  'ܒܒܘܪܐ',
  'ܓܘܕܕܐ',
  'ܨܪܝܦܐ',
  'ܩܘܢܙܐ',
  'ܐܣܪܢܐ',
  'ܡܘܙܠܐ',
  'ܢܝܘܡܐ',
  'ܒܣܡܬܐ',
  'ܪܘܣܬܐ',
  'ܚܫܚܬܐ',
  'ܫܘܓܘܠ',
  'ܫܘܢܢܐ',
  'ܡܘܕܥܝ',
  'ܠܘܘܝܐ',
  'ܣܛܪܝܐ',
  'ܐܡܘܢܐ',
  'ܠܛܪܝܐ',
  'ܕܢܘܝܐ',
  'ܪܫܝܬܐ',
  'ܐܒܘܢܐ',
  'ܡܥܢܝܐ',
  'ܡܨܥܝܐ',
  'ܥܛܘܪܐ',
  'ܕܘܓܡܐ',
  'ܫܦܠܬܐ',
  'ܛܘܦܢܐ',
  'ܬܟܝܠܐ',
  'ܣܡܛܢܐ',
  'ܨܘܪܝܐ',
  'ܦܘܣܝܣ',
  'ܝܘܦܩܐ',
  'ܬܚܘܒܐ',
  'ܢܫܝܛܐ',
  'ܝܘܫܦܛ',
  'ܚܘܠܢܐ',
  'ܒܪܩܬܐ',
  'ܕܪܘܙܐ',
  'ܩܘܒܠܐ',
  'ܬܪܦܢܐ',
  'ܬܘܡܚܐ',
  'ܢܫܝܬܐ',
  'ܗܠܘܟܐ',
  'ܢܘܪܢܐ',
  'ܡܕܝܩܐ',
  'ܓܫܘܫܐ',
  'ܚܘܝܛܐ',
  'ܬܦܟܢܐ',
  'ܬܪܝܟܐ',
  'ܫܟܪܬܐ',
  'ܡܪܝܕܐ',
  'ܙܕܩܬܐ',
  'ܕܘܟܘܣ',
  'ܩܢܘܡܐ',
  'ܡܓܢܦܪ',
  'ܦܪܣܢܓ',
  'ܟܡܝܪܐ',
  'ܪܩܝܢܐ',
  'ܩܘܛܝܢ',
  'ܡܫܪܩܐ',
  'ܐܝܬܝܐ',
  'ܠܘܓܝܐ',
  'ܓܪܕܝܐ',
  'ܛܒܘܬܐ',
  'ܡܝܒܫܐ',
  'ܡܨܘܨܐ',
  'ܣܢܘܘܐ',
  'ܫܬܝܢܐ',
  'ܥܬܢܝܐ',
  'ܡܙܘܪܐ',
  'ܡܗܦܘܪ',
  'ܡܚܪܪܐ',
  'ܠܫܢܢܐ',
  'ܒܗܝܠܐ',
  'ܓܪܘܦܐ',
  'ܚܘܛܪܐ',
  'ܠܟܣܝܣ',
  'ܫܘܫܝܐ',
  'ܦܪܢܘܣ',
  'ܚܘܠܡܐ',
  'ܪܓܘܓܐ',
  'ܩܠܘܩܐ',
  'ܕܒܘܫܐ',
  'ܫܪܘܪܐ',
  'ܨܠܘܠܐ',
  'ܡܥܡܠܐ',
  'ܡܗܘܝܐ',
  'ܬܪܬܝܢ',
  'ܡܪܘܕܟ',
  'ܡܚܡܠܐ',
  'ܥܩܘܒܬ',
  'ܦܪܣܬܐ',
  'ܢܦܘܚܐ',
  'ܫܚܝܡܐ',
  'ܒܥܘܬܐ',
  'ܐܣܝܪܐ',
  'ܡܦܪܨܢ',
  'ܣܘܙܢܟ',
  'ܓܪܘܪܐ',
  'ܪܚܡܝܐ',
  'ܒܘܚܢܐ',
  'ܡܘܚܚܐ',
  'ܒܘܪܓܐ',
  'ܒܣܪܢܐ',
  'ܫܠܘܬܐ',
  'ܩܪܘܬܐ',
  'ܢܘܦܩܐ',
  'ܒܠܝܠܐ',
  'ܪܩܡܝܐ',
  'ܟܘܫܪܐ',
  'ܒܩܥܬܐ',
  'ܡܘܨܘܐ',
  'ܓܕܡܬܐ',
  'ܐܕܡܬܐ',
  'ܚܦܘܬܐ',
  'ܬܘܕܪܐ',
  'ܣܪܘܦܐ',
  'ܦܪܘܩܢ',
  'ܙܠܝܚܐ',
  'ܥܩܘܪܐ',
  'ܩܘܠܥܐ',
  'ܩܡܐܘܢ',
  'ܡܘܦܛܐ',
  'ܕܛܘܪܐ',
  'ܫܥܛܢܐ',
  'ܬܡܝܡܐ',
  'ܡܣܟܟܐ',
  'ܬܠܘܟܘ',
  'ܥܘܒܪܐ',
  'ܐܒܠܬܐ',
  'ܫܪܝܬܐ',
  'ܡܩܠܩܠ',
  'ܡܟܬܫܐ',
  'ܗܓܘܝܐ',
  'ܟܬܘܫܐ',
  'ܦܝܟܐܪ',
  'ܣܡܕܪܐ',
  'ܛܢܝܢܐ',
  'ܪܝܩܢܐ',
  'ܩܬܘܬܐ',
  'ܐܣܦܝܣ',
  'ܢܦܘܨܐ',
  'ܓܘܠܐܒ',
  'ܐܛܘܝܐ',
  'ܨܘܒܘܬ',
  'ܙܒܝܠܐ',
  'ܫܠܩܬܐ',
  'ܣܠܡܕܪ',
  'ܩܕܪܘܢ',
  'ܬܪܘܨܐ',
  'ܛܐܓܘܢ',
  'ܕܘܗܘܟ',
  'ܩܘܡܠܐ',
  'ܣܘܠܝܐ',
  'ܡܢܬܝܐ',
  'ܥܘܘܟܐ',
  'ܡܘܪܒܐ',
  'ܐܪܣܢܐ',
  'ܟܝܠܬܐ',
  'ܬܪܨܢܐ',
  'ܚܒܝܨܐ',
  'ܒܣܕܝܐ',
  'ܟܝܢܢܐ',
  'ܥܬܘܝܐ',
  'ܟܘܕܢܐ',
  'ܣܦܘܕܐ',
  'ܚܨܝܬܐ',
  'ܡܟܘܠܐ',
  'ܣܠܘܩܐ',
  'ܡܙܝܢܐ',
  'ܝܪܘܩܐ',
  'ܡܚܘܬܐ',
  'ܛܝܒܝܐ',
  'ܗܠܘܪܐ',
  'ܝܕܥܬܐ',
  'ܚܝܘܟܐ',
  'ܓܠܝܙܐ',
  'ܣܒܥܬܐ',
  'ܙܢܝܬܐ',
  'ܘܪܩܝܐ',
  'ܡܣܝܒܪ',
  'ܒܐܪܘܬ',
  'ܡܕܡܕܓ',
  'ܬܠܘܬܐ',
  'ܝܪܬܢܐ',
  'ܗܘܢܬܐ',
  'ܚܝܠܦܐ',
  'ܝܬܘܒܐ',
  'ܡܗܪܕܩ',
  'ܐܫܟܪܐ',
  'ܡܒܣܡܪ',
  'ܦܠܟܬܐ',
  'ܩܪܝܒܐ',
  'ܡܡܝܬܐ',
  'ܙܪܕܩܐ',
  'ܐܟܘܠܐ',
  'ܨܨܘܨܐ',
  'ܫܘܪܝܐ',
  'ܦܝܫܢܐ',
  'ܓܗܢܬܐ',
  'ܟܫܝܛܐ',
  'ܛܠܘܠܐ',
  'ܢܪܕܝܢ',
  'ܡܩܪܒܢ',
  'ܟܦܪܕܪ',
  'ܪܦܐܝܡ',
  'ܒܪܕܬܐ',
  'ܓܘܡܬܐ',
  'ܢܟܣܬܐ',
  'ܗܪܘܕܐ',
  'ܦܛܝܢܐ',
  'ܡܫܚܡܐ',
  'ܬܠܝܬܐ',
  'ܡܟܡܟܡ',
  'ܣܠܘܪܐ',
  'ܝܬܘܡܐ',
  'ܫܦܢܬܐ',
  'ܝܪܚܢܐ',
  'ܬܪܝܥܐ',
  'ܦܘܫܪܐ',
  'ܕܪܣܥܬ',
  'ܓܕܘܦܐ',
  'ܡܛܚܢܐ',
  'ܥܢܘܝܐ',
  'ܓܘܒܢܐ',
  'ܙܝܘܓܐ',
  'ܚܘܘܝܐ',
  'ܕܪܓܝܐ',
  'ܙܥܪܪܐ',
  'ܚܙܘܩܐ',
  'ܩܦܠܕܪ',
  'ܡܣܝܟܐ',
  'ܬܪܘܓܐ',
  'ܙܒܛܝܐ',
  'ܒܘܩܪܐ',
  'ܚܒܘܒܐ',
  'ܫܚܘܪܐ',
  'ܡܘܬܢܐ',
  'ܡܕܢܝܐ',
  'ܚܛܪܬܐ',
  'ܒܠܣܬܐ',
  'ܐܢܘܗܐ',
  'ܩܨܝܕܐ',
  'ܟܣܘܪܐ',
  'ܚܪܘܒܐ',
  'ܡܩܘܠܐ',
  'ܡܬܘܠܥ',
  'ܡܕܪܕܡ',
  'ܫܘܟܢܐ',
  'ܣܗܪܝܐ',
  'ܡܠܬܠܬ',
  'ܦܘܬܩܐ',
  'ܓܝܓܠܐ',
  'ܛܘܪܩܐ',
  'ܐܝܕܢܐ',
  'ܫܪܝܛܐ',
  'ܦܘܘܪܐ',
  'ܡܕܠܕܢ',
  'ܨܠܠܬܐ',
  'ܡܚܪܚܟ',
  'ܦܛܢܢܐ',
  'ܢܘܝܬܐ',
  'ܡܒܪܚܐ',
  'ܨܢܘܥܐ',
  'ܬܠܡܬܐ',
  'ܫܢܘܝܐ',
  'ܣܙܒܢܕ',
  'ܒܣܡܪܐ',
  'ܫܐܗܒܙ',
  'ܣܝܚܘܢ',
  'ܡܟܝܟܐ',
  'ܓܘܢܒܐ',
  'ܚܪܡܫܐ',
  'ܒܪܙܝܠ',
  'ܕܘܢܝܐ',
  'ܦܩܘܥܐ',
  'ܒܪܝܬܐ',
  'ܬܐܪܬܐ',
  'ܨܡܘܬܐ',
  'ܐܪܒܢܐ',
  'ܬܒܕܝܠ',
  'ܟܬܝܬܐ',
  'ܗܝܟܪܬ',
  'ܟܪܦܣܐ',
  'ܒܒܘܬܐ',
  'ܪܝܫܢܐ',
  'ܚܘܪܝܒ',
  'ܚܕܪܢܐ',
  'ܪܫܡܠܐ',
  'ܪܟܝܟܐ',
  'ܟܠܝܬܐ',
  'ܐܦܪܝܡ',
  'ܥܢܒܬܐ',
  'ܡܠܘܬܐ',
  'ܡܨܦܠܦ',
  'ܣܡܛܝܐ',
  'ܫܚܝܢܐ',
  'ܐܘܦܛܐ',
  'ܕܗܝܢܐ',
  'ܒܛܠܬܐ',
  'ܡܫܚܬܢ',
  'ܢܒܘܚܐ',
  'ܒܩܘܢܐ',
  'ܫܡܥܝܐ',
  'ܒܪܕܪܫ',
  'ܥܫܩܢܐ',
  'ܡܪܘܕܐ',
  'ܚܠܥܬܐ',
  'ܝܬܝܪܐ',
  'ܡܝܘܩܐ',
  'ܪܩܝܦܐ',
  'ܣܙܢܕܐ',
  'ܪܒܘܬܐ',
  'ܥܨܪܬܐ',
  'ܡܘܠܝܡ',
  'ܕܣܬܘܪ',
  'ܒܘܣܡܐ',
  'ܢܟܘܣܐ',
  'ܦܠܫܢܐ',
  'ܫܠܦܟܐ',
  'ܚܘܬܪܐ',
  'ܢܘܒܫܐ',
  'ܒܠܘܨܐ',
  'ܕܒܘܩܐ',
  'ܣܘܣܝܐ',
  'ܡܛܠܠܐ',
  'ܠܘܒܒܐ',
  'ܥܡܪܢܐ',
  'ܦܘܗܪܐ',
  'ܝܠܝܕܐ',
  'ܐܓܘܓܐ',
  'ܢܝܚܢܝ',
  'ܪܦܘܚܐ',
  'ܓܝܕܝܐ',
  'ܓܠܘܙܐ',
  'ܒܝܫܘܬ',
  'ܫܘܝܦܐ',
  'ܪܘܫܢܐ',
  'ܚܨܝܬܐ',
  'ܚܪܘܬܐ',
  'ܟܣܚܬܐ',
  'ܕܗܘܝܢ',
  'ܗܙܙܢܐ',
  'ܡܠܦܢܐ',
  'ܠܒܘܫܐ',
  'ܫܘܥܥܐ',
  'ܐܝܠܬܐ',
  'ܫܡܘܪܐ',
  'ܢܟܘܦܐ',
  'ܬܘܩܢܐ',
  'ܫܡܫܠܐ',
  'ܦܝܟܬܐ',
  'ܫܒܝܩܐ',
  'ܚܢܘܡܢ',
  'ܬܝܒܬܐ',
  'ܓܫܘܒܐ',
  'ܣܦܪܬܐ',
  'ܩܐܥܕܐ',
  'ܛܚܢܬܐ',
  'ܝܕܝܕܐ',
  'ܡܒܘܝܐ',
  'ܡܪܬܒܐ',
  'ܡܘܦܬܐ',
  'ܥܘܝܦܐ',
  'ܡܟܙܟܙ',
  'ܫܟܝܚܐ',
  'ܛܐܠܬܐ',
  'ܓܥܘܛܐ',
  'ܓܘܙܥܐ',
  'ܡܩܦܬܐ',
  'ܙܐܘܝܗ',
  'ܝܨܝܦܐ',
  'ܣܘܥܛܐ',
  'ܛܥܘܫܐ',
  'ܒܦܠܓܐ',
  'ܦܛܒܓܐ',
  'ܐܫܘܝܐ',
  'ܡܩܢܐܐ',
  'ܡܟܒܫܐ',
  'ܒܩܘܪܐ',
  'ܢܦܠܬܐ',
  'ܛܒܝܥܐ',
  'ܡܟܘܦܐ',
  'ܝܨܪܝܐ',
  'ܙܠܝܠܐ',
  'ܛܝܡܢܐ',
  'ܓܡܝܬܐ',
  'ܩܘܣܪܐ',
  'ܐܟܘܦܐ',
  'ܦܘܪܣܐ',
  'ܫܝܠܟܐ',
  'ܠܘܥܠܐ',
  'ܡܟܢܬܐ',
  'ܙܕܥܠܐ',
  'ܐܦܬܚܪ',
  'ܫܘܥܝܐ',
  'ܥܪܘܒܐ',
  'ܟܝܫܝܟ',
  'ܐܗܬܪܡ',
  'ܦܩܕܬܐ',
  'ܬܘܩܢܐ',
  'ܓܥܝܬܐ',
  'ܢܘܪܘܙ',
  'ܫܟܘܬܐ',
  'ܝܐܪܬܐ',
  'ܩܪܘܪܐ',
  'ܐܪܥܢܐ',
  'ܫܩܠܬܐ',
  'ܝܪܘܪܐ',
  'ܓܝܪܬܐ',
  'ܡܘܫܚܐ',
  'ܕܡܥܬܐ',
  'ܫܪܕܬܐ',
  'ܚܪܒܢܐ',
  'ܡܛܦܛܦ',
  'ܓܪܦܬܐ',
  'ܥܕܢܝܐ',
  'ܓܢܐܝܬ',
  'ܩܘܦܬܐ',
  'ܚܘܟܬܐ',
  'ܓܘܠܕܢ',
  'ܒܦܘܡܐ',
  'ܩܛܘܒܐ',
  'ܕܪܝܡܓ',
  'ܣܪܝܩܐ',
  'ܕܫܪܟܐ',
  'ܣܚܘܦܐ',
  'ܪܒܢܬܐ',
  'ܛܠܫܢܐ',
  'ܫܘܬܦܐ',
  'ܒܠܓܬܐ',
  'ܦܟܪܢܐ',
  'ܫܥܪܝܐ',
  'ܩܠܝܩܐ',
  'ܫܗܪܬܐ',
  'ܚܠܝܦܐ',
  'ܠܘܘܬܐ',
  'ܢܨܝܒܐ',
  'ܡܙܡܙܛ',
  'ܕܘܠܠܐ',
  'ܠܦܝܬܐ',
  'ܐܦܬܟܐ',
  'ܨܢܥܬܐ',
  'ܛܪܝܕܐ',
  'ܡܘܙܝܩ',
  'ܛܥܘܦܐ',
  'ܩܘܚܢܐ',
  'ܓܘܦܢܐ',
  'ܟܘܪܣܝ',
  'ܕܚܘܩܐ',
  'ܡܫܪܪܐ',
  'ܦܪܝܩܐ',
  'ܡܓܒܪܢ',
  'ܬܩܘܠܐ',
  'ܦܬܘܚܐ',
  'ܦܪܩܢܐ',
  'ܣܘܚܪܐ',
  'ܠܒܢܬܐ',
  'ܣܪܗܟܪ',
  'ܢܣܟܬܐ',
  'ܛܘܒܢܐ',
  'ܬܘܪܫܝ',
  'ܒܪܝܙܐ',
  'ܫܬܥܣܪ',
  'ܨܠܘܦܐ',
  'ܫܪܘܕܐ',
  'ܥܠܬܢܐ',
  'ܬܬܟܬܐ',
  'ܫܐܒܐܫ',
  'ܬܥܠܬܐ',
  'ܥܪܘܡܐ',
  'ܡܗܕܡܐ',
  'ܩܘܘܝܐ',
  'ܟܪܢܝܝ',
  'ܗܝܠܬܐ',
  'ܙܠܘܙܐ',
  'ܚܣܘܢܐ',
  'ܟܠܝܢܐ',
  'ܡܨܢܥܐ',
  'ܐܢܫܝܐ',
  'ܢܟܣܘܣ',
  'ܟܘܢܫܐ',
  'ܐܢܝܓܐ',
  'ܡܫܛܚܐ',
  'ܬܒܢܬܐ',
  'ܣܪܟܣܐ',
  'ܙܢܢܝܐ',
  'ܦܘܙܙܐ',
  'ܐܬܐܓܪ',
  'ܢܥܨܬܐ',
  'ܡܙܕܩܐ',
  'ܒܪܐܕܡ',
  'ܪܘܣܝܐ',
  'ܦܪܘܩܐ',
  'ܗܪܙܟܪ',
  'ܣܝܘܡܐ',
  'ܚܡܝܬܐ',
  'ܥܘܒܝܕ',
  'ܡܚܝܢܐ',
  'ܡܥܙܘܠ',
  'ܓܘܪܙܐ',
  'ܦܠܘܡܐ',
  'ܥܘܩܣܐ',
  'ܟܪܒܢܐ',
  'ܨܝܡܢܐ',
  'ܡܝܠܬܐ',
  'ܠܟܙܬܐ',
  'ܦܪܝܬܐ',
  'ܡܚܝܢܐ',
  'ܫܟܘܢܐ',
  'ܒܙܝܢܐ',
  'ܩܡܘܚܐ',
  'ܟܠܢܬܪ',
  'ܚܫܟܬܐ',
  'ܡܝܒܠܐ',
  'ܓܓܪܢܐ',
  'ܩܘܒܠܐ',
  'ܓܫܩܬܐ',
  'ܡܥܪܒܐ',
  'ܚܘܝܚܐ',
  'ܡܘܛܠܐ',
  'ܒܐܫܠܩ',
  'ܡܛܒܚܐ',
  'ܡܣܝܢܐ',
  'ܣܡܪܓܠ',
  'ܣܪܦܘܫ',
  'ܡܛܠܠܐ',
  'ܬܟܘܣܐ',
  'ܒܚܝܠܐ',
  'ܩܕܝܫܐ',
  'ܛܘܪܝܐ',
  'ܒܘܓܘܡ',
  'ܛܘܘܫܐ',
  'ܥܘܘܟܐ',
  'ܚܘܡܫܐ',
  'ܒܠܫܝܐ',
  'ܐܠܝܫܥ',
  'ܪܘܚܩܐ',
  'ܢܒܝܗܐ',
  'ܐܡܝܢܝ',
  'ܟܠܣܟܐ',
  'ܬܘܗܝܐ',
  'ܩܪܡܝܙ',
  'ܒܙܝܩܐ',
  'ܥܡܝܩܐ',
  'ܣܘܩܡܐ',
  'ܫܘܓܫܐ',
  'ܟܘܠܠܐ',
  'ܒܣܝܢܐ',
  'ܙܚܘܠܐ',
  'ܟܠܝܬܐ',
  'ܐܬܪܦܝ',
  'ܒܠܫܨܪ',
  'ܦܩܥܬܐ',
  'ܡܨܪܝܢ',
  'ܝܗܒܠܐ',
  'ܩܪܡܢܐ',
  'ܨܝܛܪܓ',
  'ܚܪܘܦܐ',
  'ܦܐܠܐܢ',
  'ܡܬܪܓܡ',
  'ܡܐܝܢܐ',
  'ܥܪܘܝܐ',
  'ܕܘܡܝܟ',
  'ܩܒܝܬܐ',
  'ܦܝܘܓܐ',
  'ܥܓܠܬܐ',
  'ܡܕܥܬܐ',
  'ܬܡܡܬܐ',
  'ܗܨܠܬܐ',
  'ܣܬܡܦܐ',
  'ܪܘܙܓܪ',
  'ܣܕܪܝܐ',
  'ܢܡܪܬܐ',
  'ܫܝܪܝܐ',
  'ܩܘܘܙܐ',
  'ܓܢܣܝܐ',
  'ܡܫܩܠܒ',
  'ܚܕܝܬܐ',
  'ܫܒܘܩܐ',
  'ܓܘܚܪܐ',
  'ܙܟܝܬܐ',
  'ܣܢܪܓܐ',
  'ܙܘܘܓܐ',
  'ܠܝܒܢܐ',
  'ܠܟܣܠܢ',
  'ܩܒܝܬܐ',
  'ܟܘܕܬܐ',
  'ܛܘܢܬܐ',
  'ܥܘܦܝܐ',
  'ܩܛܝܦܐ',
  'ܦܠܝܓܐ',
  'ܬܐܪܝܐ',
  'ܟܕܘܕܐ',
  'ܚܝܠܒܙ',
  'ܥܩܪܬܐ',
  'ܚܢܦܝܐ',
  'ܪܘܚܢܐ',
  'ܬܘܡܢܐ',
  'ܪܗܛܢܐ',
  'ܫܟܪܬܐ',
  'ܡܫܒܛܐ',
  'ܩܢܒܝܐ',
  'ܛܘܦܘܣ',
  'ܬܟܝܫܐ',
  'ܡܩܕܬܐ',
  'ܠܐܥܡܝ',
  'ܨܘܪܢܐ',
  'ܛܠܝܩܐ',
  'ܗܕܘܝܐ',
  'ܢܨܚܬܐ',
  'ܗܪܬܢܐ',
  'ܣܦܝܦܐ',
  'ܢܬܢܝܐ',
  'ܟܢܦܬܐ',
  'ܩܕܘܫܐ',
  'ܥܛܘܝܐ',
  'ܙܡܟܬܐ',
  'ܐܪܘܢܐ',
  'ܓܠܘܠܐ',
  'ܩܝܬܪܐ',
  'ܦܠܓܓܐ',
  'ܢܝܘܬܐ',
  'ܪܫܒܢܐ',
  'ܒܣܦܘܪ',
  'ܫܘܟܒܐ',
  'ܝܠܘܦܐ',
  'ܩܘܒܒܐ',
  'ܢܐܟܪܐ',
  'ܡܦܢܕܡ',
  'ܟܬܝܫܐ',
  'ܚܛܦܬܐ',
  'ܬܪܡܠܐ',
  'ܡܡܨܢܐ',
  'ܛܝܣܬܐ',
  'ܫܗܪܬܐ',
  'ܟܝܪܬܐ',
  'ܒܠܘܘܐ',
  'ܢܨܘܒܐ',
  'ܫܘܫܡܐ',
  'ܚܠܡܢܐ',
  'ܪܟܒܝܐ',
  'ܨܥܪܬܐ',
  'ܕܪܘܫܬ',
  'ܒܝܐܒܝ',
  'ܬܣܦܘܪ',
  'ܓܘܝܬܐ',
  'ܫܬܝܩܐ',
  'ܥܒܘܪܐ',
  'ܡܣܡܣܟ',
  'ܬܒܥܢܐ',
  'ܡܦܨܝܐ',
  'ܗܓܝܢܐ',
  'ܓܢܒܬܐ',
  'ܬܘܝܗܐ',
  'ܨܪܘܦܐ',
  'ܓܘܢܝܐ',
  'ܒܗܘܠܐ',
  'ܡܥܢܩܫ',
  'ܐܪܙܠܐ',
  'ܡܘܗܠܬ',
  'ܓܪܕܘܢ',
  'ܐܕܫܝܐ',
  'ܕܘܟܝܐ',
  'ܥܓܘܒܐ',
  'ܛܪܢܝܐ',
  'ܡܨܬܢܐ',
  'ܚܠܝܛܐ',
  'ܛܪܘܨܐ',
  'ܗܘܓܝܐ',
  'ܩܢܘܝܐ',
  'ܡܓܢܒܠ',
  'ܢܡܘܣܐ',
  'ܦܪܣܚܐ',
  'ܡܚܢܝܡ',
  'ܥܡܘܠܐ',
  'ܓܕܢܝܐ',
  'ܢܨܪܬܐ',
  'ܚܒܘܒܐ',
  'ܡܫܟܢܐ',
  'ܫܐܝܠܐ',
  'ܣܪܘܩܐ',
  'ܒܛܒܛܐ',
  'ܓܦܦܬܐ',
  'ܛܘܝܬܐ',
  'ܓܠܓܡܫ',
  'ܣܘܗܕܐ',
  'ܒܘܝܢܐ',
  'ܦܘܠܣܐ',
  'ܟܦܪܝܐ',
  'ܫܠܘܩܐ',
  'ܩܩܘܠܐ',
  'ܚܪܩܛܐ',
  'ܦܣܘܩܐ',
  'ܢܘܦܚܐ',
  'ܗܟܡܢܐ',
  'ܡܨܕܝܐ',
  'ܠܥܝܢܐ',
  'ܟܘܫܟܐ',
  'ܒܪܘܝܐ',
  'ܫܘܠܛܢ',
  'ܣܟܘܟܐ',
  'ܝܬܘܪܐ',
  'ܚܙܢܕܪ',
  'ܥܩܪܘܢ',
  'ܒܘܪܟܐ',
  'ܐܣܦܢܓ',
  'ܦܠܢܟܣ',
  'ܝܪܟܬܐ',
  'ܟܦܘܫܐ',
  'ܒܥܘܠܐ',
  'ܝܠܦܘܓ',
  'ܢܩܠܬܐ',
  'ܩܪܝܬܐ',
  'ܡܨܘܨܐ',
  'ܓܘܡܬܐ',
  'ܩܘܕܪܬ',
  'ܨܠܘܝܐ',
  'ܙܓܘܪܐ',
  'ܛܠܘܠܐ',
  'ܢܨܘܚܐ',
  'ܝܕܥܬܐ',
  'ܡܛܪܒܚ',
  'ܟܪܬܝܚ',
  'ܢܫܚܘܪ',
  'ܡܨܝܢܐ',
  'ܨܠܝܦܐ',
  'ܨܠܘܝܐ',
  'ܐܘܝܬܐ',
  'ܩܦܘܫܐ',
  'ܦܠܚܬܐ',
  'ܡܓܘܢܢ',
  'ܕܝܘܬܐ',
  'ܚܨܝܦܐ',
  'ܡܬܪܦܬ',
  'ܨܠܚܢܐ',
  'ܦܝܟܪܐ',
  'ܡܦܚܦܚ',
  'ܒܚܘܪܐ',
  'ܩܛܝܥܐ',
  'ܚܝܘܣܐ',
  'ܓܢܝܗܝ',
  'ܫܟܒܢܐ',
  'ܬܪܥܣܪ',
  'ܒܚܫܬܐ',
  'ܬܘܡܡܐ',
  'ܒܢܝܢܐ',
  'ܦܘܝܓܐ',
  'ܚܘܣܝܐ',
  'ܫܠܓܬܐ',
  'ܬܦܢܬܐ',
  'ܙܕܥܝܐ',
  'ܛܘܘܪܐ',
  'ܡܪܕܝܐ',
  'ܛܘܫܝܐ',
  'ܣܠܡܬܐ',
  'ܝܣܦܟܐ',
  'ܚܫܝܒܐ',
  'ܡܙܓܬܐ',
  'ܡܚܡܢܐ',
  'ܕܝܕܪܐ',
  'ܪܐܘܢܕ',
  'ܢܘܓܕܐ',
  'ܕܘܪܡܓ',
  'ܒܕܘܩܐ',
  'ܦܪܓܠܐ',
  'ܐܝܘܐܝ',
  'ܢܟܪܝܐ',
  'ܠܡܨܢܐ',
  'ܣܠܘܒܐ',
  'ܟܘܐܪܐ',
  'ܨܪܝܦܐ',
  'ܓܪܒܝܐ',
  'ܡܪܒܥܐ',
  'ܗܦܟܝܐ',
  'ܣܥܝܛܐ',
  'ܪܒܢܟܐ',
  'ܟܛܝܬܐ',
  'ܥܩܝܩܐ',
  'ܓܘܚܟܐ',
  'ܡܙܢܒܠ',
  'ܩܘܣܘܪ',
  'ܫܘܚܠܐ',
  'ܐܦܘܕܐ',
  'ܓܡܘܡܐ',
  'ܝܗܒܢܐ',
  'ܩܠܦܢܐ',
  'ܚܕܬܬܐ',
  'ܪܥܘܠܐ',
  'ܓܘܡܫܐ',
  'ܙܚܘܠܐ',
  'ܛܠܘܙܐ',
  'ܪܒܘܬܐ',
  'ܚܠܘܦܐ',
  'ܐܫܦܪܐ',
  'ܨܘܪܝܐ',
  'ܨܪܨܪܐ',
  'ܣܘܡܝܐ',
  'ܩܘܪܚܐ',
  'ܡܪܦܢܐ',
  'ܒܪܝܬܐ',
  'ܚܪܣܢܐ',
  'ܝܠܙܬܐ',
  'ܢܡܝܡܐ',
  'ܡܦܘܚܐ',
  'ܠܪܝܝܐ',
  'ܝܠܕܬܐ',
  'ܫܪܝܚܐ',
  'ܡܫܝܚܐ',
  'ܨܘܨܠܐ',
  'ܐܡܒܪܐ',
  'ܣܡܠܬܐ',
  'ܡܚܘܒܐ',
  'ܡܠܓܡܐ',
  'ܚܘܫܬܐ',
  'ܫܠܝܛܐ',
  'ܬܪܒܝܬ',
  'ܢܘܠܬܐ',
  'ܠܝܦܪܐ',
  'ܒܠܣܛܐ',
  'ܡܠܥܬܐ',
  'ܡܦܩܦܩ',
  'ܩܘܝܣܐ',
  'ܝܘܐܢܐ',
  'ܡܘܚܝܐ',
  'ܦܢܝܢܐ',
  'ܩܘܪܦܐ',
  'ܒܪܘܠܐ',
  'ܠܥܙܬܐ',
  'ܡܟܫܢܐ',
  'ܡܨܛܠܛ',
  'ܫܠܝܐܐ',
  'ܨܕܪܢܐ',
  'ܐܪܘܢܐ',
  'ܥܢܘܝܐ',
  'ܟܫܝܬܐ',
  'ܪܥܝܬܐ',
  'ܢܩܙܬܐ',
  'ܐܟܙܢܐ',
  'ܓܪܓܪܐ',
  'ܬܐܡܘܢ',
  'ܓܙܝܙܐ',
  'ܡܚܕܬܐ',
  'ܣܢܕܬܐ',
  'ܡܩܕܫܐ',
  'ܟܒܪܝܐ',
  'ܙܒܘܢܐ',
  'ܪܓܘܕܐ',
  'ܟܨܝܢܐ',
  'ܡܨܦܝܐ',
  'ܡܠܗܠܗ',
  'ܗܪܣܢܐ',
  'ܡܩܪܩܪ',
  'ܙܕܩܬܐ',
  'ܣܛܢܝܐ',
  'ܝܕܥܬܐ',
  'ܩܡܘܠܐ',
  'ܦܘܪܩܐ',
  'ܓܘܠܦܐ',
  'ܡܪܗܒܐ',
  'ܡܣܝܢܐ',
  'ܐܬܢܘܠ',
  'ܟܘܕܢܐ',
  'ܨܪܦܬܐ',
  'ܦܘܚܕܐ',
  'ܐܣܩܠܛ',
  'ܣܦܩܬܐ',
  'ܬܚܢܬܐ',
  'ܙܘܕܢܐ',
  'ܥܦܘܦܐ',
  'ܡܙܝܓܐ',
  'ܫܦܝܪܐ',
  'ܪܩܝܢܐ',
  'ܒܨܘܝܐ',
  'ܓܘܠܝܕ',
  'ܡܦܢܦܠ',
  'ܫܠܘܚܐ',
  'ܢܒܘܥܐ',
  'ܡܦܨܦܨ',
  'ܐܠܒܐܠ',
  'ܚܘܕܪܐ',
  'ܚܪܘܦܐ',
  'ܬܩܣܝܪ',
  'ܫܠܝܢܓ',
  'ܚܘܪܢܐ',
  'ܣܘܒܠܐ',
  'ܫܘܝܬܐ',
  'ܥܬܝܬܐ',
  'ܛܘܠܙܐ',
  'ܟܪܘܡܐ',
  'ܣܗܕܬܐ',
  'ܣܩܡܬܐ',
  'ܨܠܝܬܐ',
  'ܡܣܩܘܦ',
  'ܦܫܡܓܐ',
  'ܡܥܝܢܐ',
  'ܦܐܪܝܐ',
  'ܓܒܪܝܐ',
  'ܦܛܝܚܐ',
  'ܒܒܝܬܐ',
  'ܬܒܪܬܐ',
  'ܫܟܝܢܐ',
  'ܩܝܫܘܢ',
  'ܠܝܠܝܝ',
  'ܡܦܪܫܐ',
  'ܡܟܘܫܐ',
  'ܚܘܫܡܕ',
  'ܡܠܘܛܐ',
  'ܛܫܘܝܐ',
  'ܛܠܥܢܐ',
  'ܡܘܩܘܩ',
  'ܛܠܘܫܐ',
  'ܟܝܘܫܐ',
  'ܫܝܘܫܐ',
  'ܕܪܘܟܐ',
  'ܓܘܡܒܙ',
  'ܡܥܒܪܐ',
  'ܪܟܘܒܐ',
  'ܩܠܝܡܐ',
  'ܐܘܡܘܛ',
  'ܢܒܓܬܐ',
  'ܣܢܣܝܠ',
  'ܬܪܩܝܐ',
  'ܫܝܪܬܐ',
  'ܚܛܝܛܐ',
  'ܚܣܦܘܫ',
  'ܡܬܪܩܠ',
  'ܫܘܘܝܐ',
  'ܢܨܘܦܐ',
  'ܣܦܝܢܐ',
  'ܒܣܪܢܐ',
  'ܛܟܘܣܐ',
  'ܦܠܛܝܐ',
  'ܕܠܘܚܐ',
  'ܒܚܫܟܐ',
  'ܛܠܙܬܐ',
  'ܒܪܝܬܐ',
  'ܚܒܝܛܐ',
  'ܚܦܪܬܐ',
  'ܡܟܬܠܢ',
  'ܡܩܕܡܐ',
  'ܣܒܪܬܐ',
  'ܐܡܝܪܠ',
  'ܪܦܘܬܐ',
  'ܚܒܝܪܐ',
  'ܦܨܘܠܐ',
  'ܬܘܩܕܐ',
  'ܐܚܬܘܟ',
  'ܩܘܠܬܐ',
  'ܡܘܢܬܐ',
  'ܡܝܚܢܐ',
  'ܒܚܐܦܐ',
  'ܒܛܘܪܐ',
  'ܦܩܝܪܐ',
  'ܡܝܪܘܢ',
  'ܠܠܘܬܐ',
  'ܠܚܡܢܐ',
  'ܟܘܠܝܐ',
  'ܕܘܪܐܢ',
  'ܪܝܪܢܐ',
  'ܣܡܝܬܐ',
  'ܡܓܪܒܓ',
  'ܬܪܝܕܐ',
  'ܫܘܚܡܐ',
  'ܚܘܪܬܐ',
  'ܫܢܝܬܐ',
  'ܫܕܘܠܐ',
  'ܠܘܘܨܐ',
  'ܝܠܘܢܐ',
  'ܪܒܘܢܐ',
  'ܡܓܒܓܒ',
  'ܟܠܘܝܐ',
  'ܓܠܘܠܐ',
  'ܚܝܪܬܐ',
  'ܚܕܪܝܐ',
  'ܝܡܘܬܐ',
  'ܣܝܡܝܐ',
  'ܐܪܫܝܢ',
  'ܪܘܝܬܐ',
  'ܡܫܘܝܐ',
  'ܝܦܝܢܐ',
  'ܥܘܠܝܐ',
  'ܡܙܪܡܛ',
  'ܩܘܦܦܐ',
  'ܓܘܙܝܐ',
  'ܟܪܘܝܐ',
  'ܩܐܕܙܝ',
  'ܡܚܠܚܠ',
  'ܕܐܝܪܐ',
  'ܦܩܚܝܐ',
  'ܐܕܡܐܣ',
  'ܓܗܝܪܐ',
  'ܫܘܝܚܐ',
  'ܛܘܝܣܐ',
  'ܡܦܪܓܙ',
  'ܕܡܝܪܐ',
  'ܡܠܦܠܦ',
  'ܟܢܫܬܐ',
  'ܩܪܘܬܐ',
  'ܕܢܘܚܐ',
  'ܟܡܝܢܐ',
  'ܡܚܬܢܐ',
  'ܥܠܠܬܐ',
  'ܕܠܒܕܪ',
  'ܡܕܘܪܐ',
  'ܐܘܠܕܐ',
  'ܠܠܘܬܐ',
  'ܗܘܦܟܐ',
  'ܫܥܝܫܐ',
  'ܕܒܫܬܐ',
  'ܦܪܨܢܕ',
  'ܚܙܘܝܐ',
  'ܝܒܫܬܐ',
  'ܫܗܘܪܐ',
  'ܚܠܘܨܐ',
  'ܦܪܟܬܐ',
  'ܫܘܬܬܐ',
  'ܘܥܘܕܐ',
  'ܩܕܡܝܐ',
  'ܦܘܬܝܐ',
  'ܐܘܣܝܣ',
  'ܚܬܝܪܝ',
  'ܥܡܠܝܐ',
  'ܚܫܘܠܐ',
  'ܢܘܩܨܢ',
  'ܡܪܓܠܐ',
  'ܚܠܡܬܐ',
  'ܥܙܬܝܬ',
  'ܥܗܝܢܐ',
  'ܕܘܝܘܢ',
  'ܕܘܪܦܝ',
  'ܥܨܘܝܐ',
  'ܡܪܟܒܐ',
  'ܒܪܘܡܐ',
  'ܫܡܘܛܐ',
  'ܐܠܘܟܘ',
  'ܥܒܝܪܐ',
  'ܡܣܦܪܐ',
  'ܦܣܦܣܐ',
  'ܚܪܩܬܐ',
  'ܡܛܗܡܐ',
  'ܕܘܠܡܐ',
  'ܫܦܘܟܐ',
  'ܐܫܬܙܢ',
  'ܣܡܢܬܐ',
  'ܥܪܝܬܐ',
  'ܬܕܢܝܐ',
  'ܡܬܩܠܐ',
  'ܙܐܘܡܐ',
  'ܠܘܕܘܢ',
  'ܥܒܘܛܐ',
  'ܨܗܝܬܐ',
  'ܒܠܬܝܟ',
  'ܩܪܟܬܐ',
  'ܐܗܪܘܢ',
  'ܪܘܫܡܐ',
  'ܡܬܥܠܡ',
  'ܒܘܫܟܐ',
  'ܒܚܝܫܐ',
  'ܡܒܪܝܐ',
  'ܗܘܝܘܢ',
  'ܣܒܘܠܬ',
  'ܚܐܝܬܐ',
  'ܗܘܓܝܐ',
  'ܩܘܠܥܝ',
  'ܡܚܙܝܐ',
  'ܩܕܪܘܣ',
  'ܡܛܚܨܠ',
  'ܦܠܝܚܐ',
  'ܡܢܪܢܪ',
  'ܓܐܙܝܗ',
  'ܥܢܒܬܐ',
  'ܐܫܘܦܐ',
  'ܐܕܡܘܣ',
  'ܬܘܚܠܐ',
  'ܕܘܓܡܐ',
  'ܬܘܙܠܓ',
  'ܩܘܪܘܫ',
  'ܒܗܬܬܐ',
  'ܣܦܘܢܐ',
  'ܦܘܩܪܐ',
  'ܡܥܠܕܐ',
  'ܪܘܘܛܐ',
  'ܐܡܩܬܐ',
  'ܓܢܒܠܢ',
  'ܐܘܚܬܐ',
  'ܒܟܘܫܐ',
  'ܠܪܘܡܐ',
  'ܙܘܕܢܐ',
  'ܫܡܛܬܐ',
  'ܛܥܦܬܐ',
  'ܣܝܓܬܐ',
  'ܨܘܘܬܐ',
  'ܢܕܝܕܐ',
  'ܦܠܥܢܐ',
  'ܐܘܚܠܐ',
  'ܓܝܘܕܐ',
  'ܚܪܘܓܐ',
  'ܐܣܠܗܬ',
  'ܟܘܡܘܪ',
  'ܬܫܟܬܐ',
  'ܡܥܒܪܐ',
  'ܛܗܘܡܐ',
  'ܬܒܚܬܐ',
  'ܓܘܫܫܐ',
  'ܢܫܢܩܐ',
  'ܒܕܡܣܬ',
  'ܚܡܝܬܐ',
  'ܐܬܬܘܗ',
  'ܓܪܝܚܐ',
  'ܦܘܕܓܐ',
  'ܥܘܪܒܐ',
  'ܡܛܥܘܐ',
  'ܟܫܝܫܐ',
  'ܡܦܘܩܐ',
  'ܬܟܠܬܐ',
  'ܐܬܘܝܐ',
  'ܩܝܣܝܐ',
  'ܪܩܘܕܐ',
  'ܚܘܟܡܐ',
  'ܥܠܘܩܐ',
  'ܕܘܝܢܐ',
  'ܕܠܝܬܐ',
  'ܛܪܝܢܐ',
  'ܒܫܪܢܐ',
  'ܪܓܣܬܐ',
  'ܒܫܝܢܐ',
  'ܐܘܟܝܬ',
  'ܡܢܡܢܡ',
  'ܡܠܘܙܐ',
  'ܢܥܘܠܐ',
  'ܠܘܒܒܐ',
  'ܕܪܘܝܐ',
  'ܪܐܘܒܢ',
  'ܡܘܫܟܐ',
  'ܪܘܒܨܐ',
  'ܫܘܩܝܐ',
  'ܦܪܫܢܐ',
  'ܟܘܙܒܝ',
  'ܚܦܩܢܐ',
  'ܪܘܦܝܐ',
  'ܓܪܡܢܐ',
  'ܩܦܝܢܐ',
  'ܦܠܓܡܐ',
  'ܡܐܪܦܠ',
  'ܝܩܕܢܐ',
  'ܒܥܕܢܐ',
  'ܒܪܘܩܐ',
  'ܙܡܝܡܐ',
  'ܪܥܝܫܐ',
  'ܦܫܪܬܐ',
  'ܐܒܘܒܐ',
  'ܫܘܠܠܐ',
  'ܣܦܝܬܐ',
  'ܫܦܪܙܐ',
  'ܐܬܬܒܪ',
  'ܪܝܫܝܐ',
  'ܢܣܝܢܐ',
  'ܚܒܛܢܐ',
  'ܐܘܪܕܟ',
  'ܨܘܚܪܐ',
  'ܫܡܗܝܐ',
  'ܛܘܘܝܐ',
  'ܥܕܘܠܡ',
  'ܣܪܝܛܐ',
  'ܩܘܠܒܓ',
  'ܥܘܪܝܒ',
  'ܣܝܪܬܐ',
  'ܡܬܢܩܡ',
  'ܒܥܒܪܐ',
  'ܡܩܒܬܐ',
  'ܟܘܕܚܐ',
  'ܫܪܥܝܐ',
  'ܕܘܩܢܐ',
  'ܓܘܕܝܐ',
  'ܐܡܝܬܐ',
  'ܡܓܫܢܐ',
  'ܕܡܘܣܐ',
  'ܙܝܘܢܐ',
  'ܨܒܝܬܐ',
  'ܣܠܘܩܐ',
  'ܠܘܥܘܐ',
  'ܟܘܫܬܝ',
  'ܡܠܬܝܐ',
  'ܣܝܦܝܐ',
  'ܪܘܚܢܐ',
  'ܡܪܕܝܢ',
  'ܡܚܙܢܐ',
  'ܣܦܝܪܐ',
  'ܫܝܬܬܐ',
  'ܗܘܩܒܙ',
  'ܚܫܝܫܐ',
  'ܗܠܟܬܐ',
  'ܢܥܪܢܢ',
  'ܐܬܢܬܐ',
  'ܣܩܝܛܐ',
  'ܡܠܘܣܐ',
  'ܓܠܝܠܐ',
  'ܡܚܡܚܡ',
  'ܪܬܡܬܐ',
  'ܗܝܘܗܐ',
  'ܩܘܗܠܬ',
  'ܕܓܘܠܐ',
  'ܨܦܪܝܐ',
  'ܨܦܪܝܐ',
  'ܠܒܬܪܗ',
  'ܥܩܪܝܐ',
  'ܡܚܘܬܐ',
  'ܦܫܟܢܐ',
  'ܩܙܒܩܙ',
  'ܡܚܘܛܐ',
  'ܟܦܝܢܐ',
  'ܠܝܘܫܐ',
  'ܛܚܝܢܐ',
  'ܪܗܒܬܐ',
  'ܣܒܝܪܐ',
  'ܢܬܘܝܐ',
  'ܥܘܕܢܐ',
  'ܬܡܝܗܐ',
  'ܟܐܒܢܐ',
  'ܒܪܝܬܐ',
  'ܡܥܪܟܐ',
  'ܡܪܒܥܐ',
  'ܐܡܘܢܐ',
  'ܫܡܪܝܢ',
  'ܦܚܘܠܐ',
  'ܡܓܙܓܙ',
  'ܒܓܠܝܐ',
  'ܫܘܚܠܐ',
  'ܫܟܝܢܐ',
  'ܓܒܝܢܐ',
  'ܒܪܕܢܐ',
  'ܬܐܘܡܐ',
  'ܫܘܠܠܐ',
  'ܟܟܝܬܐ',
  'ܣܦܪܝܐ',
  'ܚܠܝܠܐ',
  'ܥܛܪܢܐ',
  'ܡܦܚܙܐ',
  'ܟܘܝܢܐ',
  'ܡܢܘܟܝ',
  'ܬܦܝܬܐ',
  'ܡܟܪܦܠ',
  'ܥܦܘܩܐ',
  'ܝܗܝܒܐ',
  'ܢܘܓܘܠ',
  'ܥܝܘܢܐ',
  'ܬܠܩܢܐ',
  'ܒܛܠܢܐ',
  'ܨܒܘܬܐ',
  'ܓܙܓܙܐ',
  'ܚܠܛܢܐ',
  'ܟܐܒܝܢ',
  'ܬܗܝܪܐ',
  'ܙܝܘܦܐ',
  'ܦܚܘܢܐ',
  'ܩܠܘܪܐ',
  'ܣܚܝܚܐ',
  'ܨܠܘܚܐ',
  'ܓܠܝܬܐ',
  'ܚܐܨܝܬ',
  'ܡܓܪܘܣ',
  'ܢܨܒܬܐ',
  'ܫܘܪܪܐ',
  'ܚܘܓܬܐ',
  'ܢܛܝܚܐ',
  'ܐܩܦܬܐ',
  'ܢܘܘܓܐ',
  'ܫܫܠܬܐ',
  'ܕܢܘܚܐ',
  'ܒܢܝܬܐ',
  'ܟܘܝܬܐ',
  'ܛܪܦܢܐ',
  'ܡܣܩܬܐ',
  'ܬܦܝܢܐ',
  'ܛܘܟܣܐ',
  'ܛܠܘܝܐ',
  'ܢܘܪܒܐ',
  'ܥܦܝܦܐ',
  'ܛܠܩܢܐ',
  'ܢܘܨܦܐ',
  'ܡܓܪܬܐ',
  'ܗܟܘܡܐ',
  'ܟܣܣܬܐ',
  'ܒܪܢܫܐ',
  'ܡܦܪܦܪ',
  'ܐܣܝܝܐ',
  'ܫܦܘܝܐ',
  'ܒܘܟܢܐ',
  'ܬܚܘܡܐ',
  'ܣܥܪܬܐ',
  'ܥܓܙܢܐ',
  'ܓܝܠܘܣ',
  'ܒܛܘܠܐ',
  'ܒܟܬܝܐ',
  'ܛܢܘܫܐ',
  'ܐܟܬܢܐ',
  'ܫܪܚܬܐ',
  'ܣܩܝܕܐ',
  'ܬܢܝܝܐ',
  'ܕܐܪܐܝ',
  'ܡܢܟܢܟ',
  'ܒܕܪܩܐ',
  'ܓܡܕܢܐ',
  'ܡܙܩܦܐ',
  'ܚܠܝܨܐ',
  'ܩܘܘܚܐ',
  'ܛܫܟܬܐ',
  'ܙܢܓܦܐ',
  'ܕܒܘܪܐ',
  'ܡܚܛܛܐ',
  'ܫܟܝܪܐ',
  'ܘܣܩܝܐ',
  'ܡܠܘܬܐ',
  'ܣܘܟܡܐ',
  'ܐܣܛܠܐ',
  'ܬܫܒܝܐ',
  'ܡܦܩܢܐ',
  'ܦܪܚܬܐ',
  'ܒܫܪܢܐ',
  'ܨܘܝܒܐ',
  'ܫܓܘܫܐ',
  'ܩܘܛܡܐ',
  'ܟܬܒܬܐ',
  'ܢܝܢܘܣ',
  'ܫܗܘܝܐ',
  'ܡܩܠܣܐ',
  'ܬܗܠܠܐ',
  'ܛܒܘܬܐ',
  'ܣܩܝܪܐ',
  'ܟܒܘܫܐ',
  'ܒܠܢܝܐ',
  'ܥܩܪܒܐ',
  'ܡܪܪܬܐ',
  'ܪܚܘܦܐ',
  'ܥܘܪܒܐ',
  'ܬܡܪܬܐ',
  'ܦܠܓܝܐ',
  'ܗܓܪܝܐ',
  'ܗܢܕܒܐ',
  'ܚܢܓܘܠ',
  'ܚܪܝܙܐ',
  'ܐܠܦܝܐ',
  'ܙܟܣܬܐ',
  'ܨܘܡܢܐ',
  'ܚܡܝܩܐ',
  'ܐܠܝܗܝ',
  'ܬܪܥܬܐ',
  'ܙܝܙܢܐ',
  'ܪܝܗܢܝ',
  'ܐܝܟܢܐ',
  'ܠܘܢܕܝ',
  'ܫܐܫܬܐ',
  'ܕܓܠܬܐ',
  'ܓܪܫܬܐ',
  'ܬܟܠܡܫ',
  'ܛܣܪܪܐ',
  'ܐܚܪܢܐ',
  'ܣܪܩܕܐ',
  'ܒܓܪܬܐ',
  'ܕܠܘܦܐ',
  'ܩܝܣܢܐ',
  'ܡܥܝܝܐ',
  'ܦܝܘܓܐ',
  'ܕܘܝܘܢ',
  'ܡܩܕܢܐ',
  'ܚܫܝܠܐ',
  'ܚܣܘܟܐ',
  'ܐܘܟܠܐ',
  'ܠܘܒܛܐ',
  'ܨܘܒܥܐ',
  'ܦܠܩܬܐ',
  'ܚܛܪܬܐ',
  'ܓܘܡܥܐ',
  'ܚܘܝܬܐ',
  'ܚܦܩܬܐ',
  'ܩܦܝܣܐ',
  'ܡܥܪܦܠ',
  'ܡܚܠܘܩ',
  'ܒܠܥܝܐ',
  'ܝܪܡܬܐ',
  'ܣܪܩܬܐ',
  'ܥܛܝܢܐ',
  'ܙܡܝܢܐ',
  'ܫܠܝܡܐ',
  'ܚܛܝܬܐ',
  'ܚܪܘܪܐ',
  'ܐܒܗܝܐ',
  'ܩܪܫܬܐ',
  'ܕܫܬܝܐ',
  'ܥܘܣܣܐ',
  'ܗܫܝܬܐ',
  'ܛܒܠܪܐ',
  'ܥܠܝܡܐ',
  'ܥܘܝܪܐ',
  'ܥܩܘܒܐ',
  'ܢܘܩܡܐ',
  'ܒܘܩܩܐ',
  'ܬܢܘܡܐ',
  'ܫܡܛܢܐ',
  'ܛܒܠܬܐ',
  'ܣܘܘܕܐ',
  'ܣܝܓܪܐ',
  'ܩܠܠܬܐ',
  'ܡܒܪܚܫ',
  'ܙܪܕܩܐ',
  'ܚܛܛܬܐ',
  'ܐܘܦܢܐ',
  'ܠܘܢܕܐ',
  'ܒܩܪܬܐ',
  'ܩܢܘܘܙ',
  'ܬܐܓܪܐ',
  'ܝܘܪܘܦ',
  'ܫܘܨܪܐ',
  'ܒܨܝܢܐ',
  'ܣܟܢܝܐ',
  'ܪܗܝܒܐ',
  'ܓܢܘܙܐ',
  'ܡܩܪܡܫ',
  'ܥܒܘܕܐ',
  'ܒܠܘܪܐ',
  'ܐܪܘܥܐ',
  'ܚܪܝܟܐ',
  'ܚܡܬܝܐ',
  'ܟܪܘܝܐ',
  'ܣܝܡܘܢ',
  'ܐܬܡܠܝ',
  'ܥܘܪܕܐ',
  'ܫܦܕܬܐ',
  'ܣܘܪܩܐ',
  'ܚܒܢܢܐ',
  'ܟܝܘܡܐ',
  'ܫܪܛܠܐ',
  'ܕܘܡܝܐ',
  'ܙܐܘܓܐ',
  'ܗܡܙܡܢ',
  'ܚܦܦܬܐ',
  'ܐܘܝܘܢ',
  'ܩܪܕܚܐ',
  'ܐܘܟܦܐ',
  'ܒܛܘܢܐ',
  'ܪܘܫܡܐ',
  'ܦܬܘܢܐ',
  'ܡܬܣܠܡ',
  'ܡܡܘܕܐ',
  'ܩܛܘܬܐ',
  'ܠܐܠܬܚ',
  'ܣܝܛܠܐ',
  'ܡܓܙܪܐ',
  'ܚܕܬܢܐ',
  'ܩܐܝܫܐ',
  'ܣܬܕܬܐ',
  'ܚܦܛܬܐ',
  'ܡܛܚܛܚ',
  'ܩܛܪܝܓ',
  'ܬܘܟܣܐ',
  'ܕܥܘܪܐ',
  'ܕܘܡܪܐ',
  'ܙܠܦܬܐ',
  'ܕܘܓܡܐ',
  'ܡܓܠܨܐ',
  'ܬܘܚܕܐ',
  'ܡܝܕܢܐ',
  'ܩܠܘܛܐ',
  'ܬܘܙܢܐ',
  'ܩܪܪܕܕ',
  'ܬܦܟܬܐ',
  'ܢܨܘܚܐ',
  'ܚܡܘܝܐ',
  'ܬܦܟܬܐ',
  'ܠܘܟܬܐ',
  'ܡܦܪܦܥ',
  'ܚܘܪܓܐ',
  'ܟܪܘܘܙ',
  'ܣܡܕܪܐ',
  'ܢܦܩܬܐ',
  'ܫܘܬܩܐ',
  'ܦܘܬܚܐ',
  'ܡܫܘܪܐ',
  'ܥܬܝܩܐ',
  'ܡܪܓܝܪ',
  'ܚܫܘܟܐ',
  'ܚܬܝܬܐ',
  'ܡܚܦܘܪ',
  'ܡܣܢܛܐ',
  'ܡܦܪܡܐ',
  'ܢܚܫܘܢ',
  'ܥܨܒܬܐ',
  'ܡܘܨܝܐ',
  'ܟܦܫܬܐ',
  'ܝܨܝܦܐ',
  'ܛܘܥܡܐ',
  'ܚܠܝܠܐ',
  'ܪܥܝܢܐ',
  'ܣܡܢܢܐ',
  'ܚܘܒܢܐ',
  'ܚܪܝܪܐ',
  'ܕܐܝܪܐ',
  'ܩܘܕܕܐ',
  'ܡܚܠܠܬ',
  'ܣܩܘܬܐ',
  'ܐܠܝܦܐ',
  'ܟܘܪܣܐ',
  'ܩܝܪܬܐ',
  'ܦܫܘܠܐ',
  'ܩܝܘܪܐ',
  'ܦܪܘܚܐ',
  'ܕܗܠܢܐ',
  'ܕܡܝܟܐ',
  'ܡܕܡܕܡ',
  'ܒܘܪܝܐ',
  'ܙܦܪܢܐ',
  'ܩܨܘܥܐ',
  'ܓܪܝܫܐ',
  'ܗܝܟܠܐ',
  'ܫܢܓܠܐ',
  'ܩܫܘܫܐ',
  'ܒܪܘܪܐ',
  'ܙܘܒܕܐ',
  'ܦܚܙܬܐ',
  'ܚܙܝܬܐ',
  'ܩܘܡܢܐ',
  'ܪܕܝܕܐ',
  'ܓܘܪܝܢ',
  'ܣܩܪܝܐ',
  'ܡܘܙܓܐ',
  'ܣܒܘܪܐ',
  'ܢܥܪܢܪ',
  'ܐܘܠܕܐ',
  'ܡܚܕܕܐ',
  'ܡܥܩܕܐ',
  'ܛܪܥܬܐ',
  'ܡܝܣܟܪ',
  'ܚܙܝܬܐ',
  'ܛܠܝܝܐ',
  'ܫܐܕܢܐ',
  'ܩܛܝܪܐ',
  'ܓܘܫܢܐ',
  'ܡܘܢܬܐ',
  'ܡܣܚܝܐ',
  'ܡܘܡܟܢ',
  'ܒܪܓܝܠ',
  'ܩܘܡܬܐ',
  'ܒܥܕܢܐ',
  'ܪܩܪܩܐ',
  'ܙܦܘܬܐ',
  'ܡܝܪܙܐ',
  'ܟܠܝܬܐ',
  'ܩܝܝܬܐ',
  'ܠܘܦܪܐ',
  'ܐܝܡܡܐ',
  'ܫܒܟܘܪ',
  'ܫܕܘܢܐ',
  'ܪܗܘܡܐ',
  'ܒܬܪܝܐ',
  'ܙܚܦܠܬ',
  'ܟܢܫܬܐ',
  'ܩܢܢܝܐ',
  'ܒܩܝܩܐ',
  'ܥܪܦܬܐ',
  'ܡܓܦܓܦ',
  'ܕܝܘܢܐ',
  'ܡܩܕܩܕ',
  'ܟܪܝܒܐ',
  'ܝܠܘܢܐ',
  'ܗܪܒܢܐ',
  'ܣܒܟܬܐ',
  'ܒܪܝܕܐ',
  'ܡܚܒܠܐ',
  'ܪܥܘܡܐ',
  'ܫܩܝܠܐ',
  'ܦܛܘܝܐ',
  'ܡܒܠܡܠ',
  'ܗܘܢܝܐ',
  'ܙܥܘܦܐ',
  'ܩܘܣܠܐ',
  'ܩܘܦܪܐ',
  'ܕܩܪܢܐ',
  'ܬܕܐܪܟ',
  'ܬܢܝܒܐ',
  'ܛܒܐܝܬ',
  'ܣܘܠܛܢ',
  'ܫܝܪܬܐ',
  'ܦܩܘܕܐ',
  'ܥܠܝܦܐ',
  'ܣܘܣܢܝ',
  'ܚܦܪܢܐ',
  'ܐܢܘܢܐ',
  'ܡܝܫܢܐ',
  'ܦܠܛܘܢ',
  'ܦܗܝܬܐ',
  'ܫܠܝܬܐ',
  'ܚܪܙܕܪ',
  'ܡܬܩܢܐ',
  'ܛܠܘܫܐ',
  'ܣܦܘܝܐ',
  'ܚܦܘܛܐ',
  'ܥܠܥܠܐ',
  'ܓܕܘܡܐ',
  'ܡܡܚܝܐ',
  'ܫܪܓܡܐ',
  'ܫܒܟܬܐ',
  'ܒܠܘܫܐ',
  'ܡܩܛܪܢ',
  'ܓܪܓܢܐ',
  'ܣܕܘܢܐ',
  'ܟܡܝܕܐ',
  'ܒܘܙܡܐ',
  'ܡܦܬܟܐ',
  'ܡܡܘܪܐ',
  'ܢܘܩܬܐ',
  'ܟܝܡܝܐ',
  'ܚܪܛܘܡ',
  'ܡܦܫܠܐ',
  'ܓܒܪܝܠ',
  'ܓܝܗܢܐ',
  'ܪܝܫܝܐ',
  'ܩܢܫܬܐ',
  'ܬܐܫܘܪ',
  'ܣܒܝܣܐ',
  'ܩܪܩܠܐ',
  'ܦܐܬܢܐ',
  'ܠܩܘܡܐ',
  'ܓܪܘܣܐ',
  'ܐܬܛܠܥ',
  'ܓܘܪܓܐ',
  'ܕܝܡܘܣ',
  'ܡܫܘܝܐ',
  'ܚܘܒܠܐ',
  'ܐܢܘܟܐ',
  'ܟܪܟܢܐ',
  'ܓܘܡܒܪ',
  'ܟܡܐܢܐ',
  'ܥܪܒܝܐ',
  'ܡܕܪܡܢ',
  'ܦܛܝܚܐ',
  'ܒܟܒܫܐ',
  'ܫܢܓܬܐ',
  'ܪܡܝܣܐ',
  'ܟܝܢܬܐ',
  'ܐܘܦܝܪ',
  'ܪܟܒܬܐ',
  'ܠܕܐܟܐ',
  'ܪܓܝܡܐ',
  'ܐܟܣܝܣ',
  'ܩܣܪܝܐ',
  'ܣܦܩܬܐ',
  'ܩܛܘܠܐ',
  'ܘܪܩܬܐ',
  'ܣܬܘܢܐ',
  'ܪܓܠܬܐ',
  'ܙܘܪܩܐ',
  'ܛܚܢܬܐ',
  'ܒܪܘܐܐ',
  'ܙܕܘܢܐ',
  'ܕܪܓܘܢ',
  'ܩܨܘܡܐ',
  'ܦܠܩܘܢ',
  'ܠܚܟܬܐ',
  'ܬܪܝܙܐ',
  'ܬܘܟܢܐ',
  'ܡܦܢܒܠ',
  'ܚܘܠܡܐ',
  'ܒܘܬܘܠ',
  'ܟܦܪܢܐ',
  'ܚܛܘܦܐ',
  'ܫܠܬܘܟ',
  'ܡܠܘܚܐ',
  'ܐܠܐܟܐ',
  'ܦܣܛܓܐ',
  'ܢܘܛܪܐ',
  'ܒܠܥܢܐ',
  'ܡܗܡܝܐ',
  'ܫܘܝܬܐ',
  'ܦܕܝܫܗ',
  'ܣܘܒܥܐ',
  'ܓܘܦܠܐ',
  'ܛܘܠܙܐ',
  'ܨܪܘܝܐ',
  'ܛܢܛܢܐ',
  'ܨܘܦܪܐ',
  'ܢܗܣܬܐ',
  'ܦܪܕܬܐ',
  'ܪܘܫܡܐ',
  'ܒܕܘܩܐ',
  'ܨܠܝܬܐ',
  'ܥܡܘܩܐ',
  'ܓܕܦܬܐ',
  'ܩܘܝܬܐ',
  'ܓܘܦܬܐ',
  'ܣܘܥܝܐ',
  'ܦܢܝܘܨ',
  'ܬܝܘܒܐ',
  'ܡܠܘܕܐ',
  'ܨܨܝܬܐ',
  'ܡܫܘܟܐ',
  'ܪܝܫܬܐ',
  'ܗܘܟܡܐ',
  'ܡܓܫܓܫ',
  'ܐܟܚܕܐ',
  'ܨܕܘܝܐ',
  'ܒܪܝܬܐ',
  'ܙܒܘܢܐ',
  'ܟܟܠܬܐ',
  'ܗܕܡܝܐ',
  'ܥܡܘܕܐ',
  'ܙܘܝܥܐ',
  'ܫܚܘܪܐ',
  'ܡܩܪܩܫ',
  'ܩܠܝܕܫ',
  'ܣܛܣܝܣ',
  'ܕܪܡܢܐ',
  'ܒܘܠܩܐ',
  'ܬܘܡܡܐ',
  'ܣܪܝܕܐ',
  'ܡܪܕܢܐ',
  'ܥܕܘܘܐ',
  'ܩܨܘܢܐ',
  'ܓܘܠܩܐ',
  'ܚܬܘܡܐ',
  'ܡܨܘܨܐ',
  'ܒܝܠܝܐ',
  'ܛܠܝܬܐ',
  'ܟܕܡܬܐ',
  'ܢܐܪܩܝ',
  'ܡܘܐܘܐ',
  'ܢܦܘܫܐ',
  'ܢܘܘܪܐ',
  'ܙܪܙܬܐ',
  'ܫܘܝܫܐ',
  'ܡܪܘܦܐ',
  'ܙܪܩܬܐ',
  'ܐܫܘܦܐ',
  'ܡܢܩܠܐ',
  'ܥܪܢܬܐ',
  'ܐܢܬܩܡ',
  'ܡܦܪܬܐ',
  'ܥܢܝܕܐ',
  'ܛܡܝܪܐ',
  'ܣܘܕܪܐ',
  'ܣܩܬܟܪ',
  'ܣܦܪܝܐ',
  'ܬܘܢܝܐ',
  'ܪܥܘܡܐ',
  'ܚܒܝܣܐ',
  'ܬܚܦܝܣ',
  'ܣܚܝܬܐ',
  'ܥܩܘܣܐ',
  'ܕܪܘܝܫ',
  'ܬܝܡܢܐ',
  'ܒܪܥܢܐ',
  'ܓܘܘܒܐ',
  'ܡܕܪܕܫ',
  'ܦܬܝܠܐ',
  'ܡܥܡܠܐ',
  'ܣܗܡܢܐ',
  'ܬܘܠܥܐ',
  'ܟܙܝܪܐ',
  'ܫܒܚܝܪ',
  'ܦܘܪܓܐ',
  'ܡܫܬܢܝ',
  'ܡܛܟܣܐ',
  'ܡܓܪܡܫ',
  'ܨܦܨܦܐ',
  'ܝܪܥܬܐ',
  'ܒܛܪܝܩ',
  'ܬܒܥܪܐ',
  'ܬܥܙܝܐ',
  'ܩܫܝܬܐ',
  'ܕܘܪܡܐ',
  'ܓܪܒܢܐ',
  'ܣܢܝܢܐ',
  'ܝܥܩܘܒ',
  'ܡܪܐܟܠ',
  'ܪܘܒܥܐ',
  'ܒܬܪܝܐ',
  'ܦܠܟܢܐ',
  'ܡܘܢܣܒ',
  'ܙܡܝܬܐ',
  'ܩܘܠܣܐ',
  'ܡܢܓܘܪ',
  'ܚܒܘܨܐ',
  'ܡܐܬܝܐ',
  'ܡܛܠܘܢ',
  'ܡܫܚܢܐ',
  'ܕܘܠܒܐ',
  'ܩܘܨܘܪ',
  'ܩܘܨܨܐ',
  'ܡܨܢܨܠ',
  'ܡܫܓܬܐ',
  'ܐܦܣܘܣ',
  'ܡܒܩܒܩ',
  'ܪܘܥܠܐ',
  'ܓܘܫܡܐ',
  'ܥܕܘܢܐ',
  'ܪܒܘܬܐ',
  'ܠܘܒܝܐ',
  'ܕܝܘܩܐ',
  'ܣܠܩܝܐ',
  'ܫܢܬܢܐ',
  'ܡܓܠܡܐ',
  'ܩܘܪܪܐ',
  'ܬܘܬܪܐ',
  'ܛܠܘܢܐ',
  'ܡܨܠܦܐ',
  'ܐܝܡܪܐ',
  'ܓܪܙܝܡ',
  'ܦܠܚܢܐ',
  'ܡܫܪܛܐ',
  'ܓܘܢܢܐ',
  'ܙܕܘܥܐ',
  'ܫܘܘܚܐ',
  'ܒܚܘܪܐ',
  'ܣܘܩܝܐ',
  'ܐܘܐܪܐ',
  'ܩܠܝܘܐ',
  'ܐܠܨܢܐ',
  'ܒܢܝܢܐ',
  'ܢܘܩܕܐ',
  'ܨܪܘܪܐ',
  'ܚܠܝܢܐ',
  'ܐܒܘܬܐ',
  'ܛܠܡܗܐ',
  'ܠܐܪܥܐ',
  'ܛܘܫܝܐ',
  'ܫܘܪܬܢ',
  'ܫܩܝܬܐ',
  'ܣܪܚܘܫ',
  'ܡܬܝܟܐ',
  'ܝܙܘܦܐ',
  'ܕܗܘܠܐ',
  'ܚܘܪܩܐ',
  'ܢܫܝܓܐ',
  'ܟܬܝܣܐ',
  'ܡܚܬܬܐ',
  'ܥܕܝܬܐ',
  'ܣܡܪܢܐ',
  'ܡܒܠܒܛ',
  'ܦܘܝܓܐ',
  'ܣܪܘܩܐ',
  'ܛܘܝܒܐ',
  'ܠܝܬܝܐ',
  'ܒܘܝܪܐ',
  'ܡܕܠܕܠ',
  'ܩܩܘܠܓ',
  'ܩܘܢܕܐ',
  'ܡܪܒܝܐ',
  'ܚܘܪܟܐ',
  'ܛܐܠܢܐ',
  'ܓܠܛܝܐ',
  'ܢܩܪܬܝ',
  'ܐܠܝܨܐ',
  'ܟܘܫܦܐ',
  'ܐܙܘܠܐ',
  'ܚܙܢܬܐ',
  'ܒܘܪܟܐ',
  'ܢܫܘܬܐ',
  'ܟܒܢܬܐ',
  'ܫܚܘܡܐ',
  'ܚܩܝܪܐ',
  'ܒܥܘܝܐ',
  'ܟܦܘܫܐ',
  'ܢܟܦܬܐ',
  'ܓܝܣܝܐ',
  'ܟܒܘܫܐ',
  'ܝܠܦܬܐ',
  'ܡܒܪܛܢ',
  'ܒܪܥܢܐ',
  'ܒܬܦܩܘ',
  'ܫܒܒܬܐ',
  'ܥܘܫܢܐ',
  'ܩܘܕܫܐ',
  'ܟܪܟܬܐ',
  'ܛܠܝܬܐ',
  'ܦܠܘܣܐ',
  'ܣܪܘܒܪ',
  'ܒܠܘܛܐ',
  'ܒܚܒܫܐ',
  'ܚܘܛܒܐ',
  'ܘܪܝܕܐ',
  'ܕܘܥܬܐ',
  'ܫܠܛܢܐ',
  'ܡܫܚܫܐ',
  'ܦܚܝܚܐ',
  'ܙܒܘܬܐ',
  'ܐܘܨܪܐ',
  'ܠܘܡܒܐ',
  'ܬܥܘܫܐ',
  'ܨܨܘܢܐ',
  'ܩܘܪܒܢ',
  'ܡܠܙܠܙ',
  'ܚܫܘܫܐ',
  'ܚܬܪܬܐ',
  'ܬܒܥܬܐ',
  'ܚܩܘܬܐ',
  'ܫܬܘܩܐ',
  'ܡܥܢܝܝ',
  'ܡܘܗܘܪ',
  'ܡܠܗܝܐ',
  'ܨܢܦܬܐ',
  'ܬܗܘܝܐ',
  'ܡܟܐܝܬ',
  'ܦܐܘܩܐ',
  'ܕܝܕܠܐ',
  'ܩܪܣܝܐ',
  'ܓܠܘܦܐ',
  'ܕܘܟܣܐ',
  'ܛܡܐܬܐ',
  'ܫܪܬܐܒ',
  'ܚܨܘܦܐ',
  'ܚܝܐܝܬ',
  'ܒܪܒܐܕ',
  'ܘܗܡܝܐ',
  'ܩܘܨܬܐ',
  'ܣܚܘܪܐ',
  'ܓܥܝܛܐ',
  'ܢܘܪܬܐ',
  'ܒܚܘܫܐ',
  'ܣܪܘܣܐ',
  'ܥܛܝܦܐ',
  'ܕܘܛܠܒ',
  'ܙܢܓܘܠ',
  'ܨܘܪܡܐ',
  'ܡܠܘܚܐ',
  'ܐܣܛܕܐ',
  'ܡܚܪܥܐ',
  'ܝܘܢܝܐ',
  'ܡܚܫܢܐ',
  'ܬܘܪܬܐ',
  'ܩܙܘܪܐ',
  'ܡܬܘܩܐ',
  'ܣܪܢܢܐ',
  'ܡܠܥܣܐ',
  'ܥܛܘܦܐ',
  'ܩܝܘܡܐ',
  'ܫܦܢܬܐ',
  'ܒܘܠܛܐ',
  'ܩܘܪܥܐ',
  'ܡܪܫܒܢ',
  'ܟܘܙܝܐ',
  'ܠܘܬܡܐ',
  'ܫܟܘܢܐ',
  'ܪܘܕܢܐ',
  'ܒܘܓܫܐ',
  'ܣܘܪܡܐ',
  'ܩܪܢܢܐ',
  'ܫܝܪܝܢ',
  'ܪܘܘܠܐ',
  'ܕܝܓܕܐ',
  'ܡܙܪܘܬ',
  'ܡܣܪܓܕ',
  'ܓܡܥܬܐ',
  'ܓܘܕܦܐ',
  'ܡܨܘܬܐ',
  'ܪܢܓܢܐ',
  'ܫܕܪܬܐ',
  'ܦܪܘܥܐ',
  'ܘܝܪܐܢ',
  'ܢܘܕܝܐ',
  'ܫܡܥܝܐ',
  'ܝܥܝܬܐ',
  'ܒܘܨܝܐ',
  'ܡܕܪܫܐ',
  'ܟܬܪܢܐ',
  'ܟܒܝܫܐ',
  'ܫܪܐܨܪ',
  'ܝܕܘܥܐ',
  'ܨܘܡܕܐ',
  'ܫܝܙܩܐ',
  'ܛܝܘܠܐ',
  'ܕܘܦܢܐ',
  'ܢܝܙܪܐ',
  'ܙܘܘܓܐ',
  'ܟܘܕܪܬ',
  'ܫܪܘܪܐ',
  'ܓܦܣܝܢ',
  'ܨܢܘܒܪ',
  'ܝܗܘܘܐ',
  'ܥܘܦܦܐ',
  'ܓܘܫܡܐ',
  'ܙܪܝܩܐ',
  'ܥܠܝܡܐ',
  'ܓܓܪܬܐ',
  'ܕܝܪܢܐ',
  'ܒܪܥܠܝ',
  'ܚܡܘܬܐ',
  'ܐܓܘܣܐ',
  'ܥܡܪܬܐ',
  'ܣܡܬܘܪ',
  'ܡܛܠܠܐ',
  'ܦܘܢܩܐ',
  'ܚܪܒܬܐ',
  'ܛܘܪܝܐ',
  'ܡܗܝܙܪ',
  'ܣܝܘܒܐ',
  'ܣܩܝܠܐ',
  'ܒܠܩܝܨ',
  'ܥܒܝܛܐ',
  'ܗܡܝܫܐ',
  'ܛܥܝܢܐ',
  'ܬܘܢܝܐ',
  'ܪܘܚܦܐ',
  'ܢܒܘܗܐ',
  'ܚܝܐܒܢ',
  'ܩܪܡܢܐ',
  'ܐܬܬܗܝ',
  'ܥܡܙܘܟ',
  'ܠܘܝܬܢ',
  'ܒܙܝܩܐ',
  'ܫܘܓܪܐ',
  'ܥܕܘܝܐ',
  'ܡܪܣܪܣ',
  'ܟܠܘܬܐ',
  'ܓܪܝܘܐ',
  'ܓܠܘܝܐ',
  'ܡܨܪܝܐ',
  'ܡܓܠܝܐ',
  'ܙܟܘܝܐ',
  'ܡܡܠܠܐ',
  'ܣܦܢܝܐ',
  'ܚܪܝܒܐ',
  'ܥܪܒܠܐ',
  'ܦܐܠܩܐ',
  'ܬܠܥܣܪ',
  'ܙܝܕܝܝ',
  'ܩܕܡܬܐ',
  'ܫܡܣܝܐ',
  'ܩܢܕܪܐ',
  'ܘܠܝܝܐ',
  'ܨܦܝܬܐ',
  'ܠܘܠܩܐ',
  'ܡܨܪܨܪ',
  'ܐܝܫܠܟ',
  'ܫܛܦܬܐ',
  'ܗܕܘܣܐ',
  'ܚܡܝܬܐ',
  'ܪܣܝܣܐ',
  'ܬܢܚܢܐ',
  'ܡܚܝܢܐ',
  'ܬܘܘܒܐ',
  'ܒܩܝܢܐ',
  'ܒܫܪܬܐ',
  'ܝܘܒܒܐ',
  'ܚܒܝܠܐ',
  'ܫܐܡܝܐ',
  'ܩܘܠܒܣ',
  'ܥܘܩܪܐ',
  'ܡܘܠܕܐ',
  'ܨܝܡܬܐ',
  'ܡܠܢܓܢ',
  'ܪܒܘܥܐ',
  'ܐܪܝܟܐ',
  'ܙܠܒܢܐ',
  'ܦܝܘܚܐ',
  'ܟܠܘܙܐ',
  'ܡܨܚܪܐ',
  'ܬܬܢܝܐ',
  'ܙܗܘܪܐ',
  'ܩܒܝܠܐ',
  'ܢܘܢܘܢ',
  'ܠܦܘܦܐ',
  'ܒܠܝܬܐ',
  'ܒܕܫܘܡ',
  'ܘܐܪܣܐ',
  'ܚܘܪܓܐ',
  'ܬܩܘܠܐ',
  'ܩܪܒܝܐ',
  'ܫܠܚܬܐ',
  'ܣܘܛܝܐ',
  'ܓܘܪܬܐ',
  'ܒܘܚܫܐ',
  'ܛܦܪܬܐ',
  'ܡܫܡܗܐ',
  'ܝܚܣܝܪ',
  'ܬܐܓܪܐ',
  'ܒܕܨܦܬ',
  'ܫܪܘܙܐ',
  'ܢܣܪܘܟ',
  'ܕܒܝܩܐ',
  'ܫܐܓܪܕ',
  'ܓܘܠܓܙ',
  'ܐܩܠܬܐ',
  'ܩܘܘܠܐ',
  'ܫܐܪܝܢ',
  'ܥܘܟܪܐ',
  'ܝܡܝܢܐ',
  'ܥܪܡܬܐ',
  'ܛܦܝܚܐ',
  'ܦܠܐܟܢ',
  'ܩܩܒܢܐ',
  'ܡܚܝܢܐ',
  'ܡܙܘܕܐ',
  'ܡܬܘܠܐ',
  'ܐܣܘܪܐ',
  'ܡܙܦܪܢ',
  'ܝܩܪܬܐ',
  'ܚܙܝܪܐ',
  'ܟܡܢܕܪ',
  'ܫܝܢܝܐ',
  'ܐܕܠܬܐ',
  'ܡܟܠܝܐ',
  'ܒܛܢܬܐ',
  'ܚܘܒܘܟ',
  'ܓܘܠܓܐ',
  'ܟܘܠܒܐ',
  'ܡܨܘܨܐ',
  'ܦܣܛܓܐ',
  'ܡܫܟܢܐ',
  'ܚܘܘܝܐ',
  'ܘܪܕܝܐ',
  'ܫܘܬܦܐ',
  'ܙܘܬܪܐ',
  'ܫܠܓܡܐ',
  'ܒܠܓܬܐ',
  'ܢܫܘܬܐ',
  'ܡܦܪܦܠ',
  'ܬܗܝܪܐ',
  'ܟܢܫܝܐ',
  'ܣܢܕܠܐ',
  'ܩܪܦܬܐ',
  'ܨܦܝܢܐ',
  'ܝܐܝܣܐ',
  'ܫܒܛܪܐ',
  'ܟܣܘܝܐ',
  'ܡܟܘܦܐ',
  'ܐܣܘܢܐ',
  'ܐܫܬܩܕ',
  'ܛܝܡܘܢ',
  'ܡܝܠܛܐ',
  'ܥܕܢܝܐ',
  'ܡܚܝܢܐ',
  'ܬܘܢܚܐ',
  'ܙܪܢܝܓ',
  'ܡܒܓܒܫ',
  'ܠܘܥܛܐ',
  'ܕܩܘܪܐ',
  'ܓܠܝܪܐ',
  'ܫܬܒܗܪ',
  'ܓܘܒܬܐ',
  'ܡܡܘܬܐ',
  'ܣܕܝܢܐ',
  'ܟܢܐܝܬ',
  'ܢܩܘܒܐ',
  'ܚܝܡܬܐ',
  'ܒܢܘܝܐ',
  'ܩܪܛܬܐ',
  'ܫܠܓܡܐ',
  'ܩܪܘܣܐ',
  'ܟܢܘܬܐ',
  'ܥܘܛܡܐ',
  'ܡܢܩܕܐ',
  'ܗܦܟܬܐ',
  'ܣܝܓܪܐ',
  'ܩܒܠܬܐ',
  'ܝܪܘܬܐ',
  'ܡܠܘܐܢ',
  'ܩܝܣܘܣ',
  'ܕܡܢܕܪ',
  'ܡܝܘܬܐ',
  'ܦܪܫܘܬ',
  'ܡܫܪܛܚ',
  'ܡܘܕܟܐ',
  'ܚܡܘܝܐ',
  'ܐܪܡܘܓ',
  'ܢܘܚܫܐ',
  'ܡܩܨܩܨ',
  'ܐܝܕܡܐ',
  'ܣܐܘܢܐ',
  'ܟܗܝܢܐ',
  'ܒܙܘܢܐ',
  'ܡܘܡܝܐ',
  'ܪܒܩܬܐ',
  'ܦܟܝܗܐ',
  'ܥܛܘܦܐ',
  'ܫܥܝܬܐ',
  'ܬܚܘܢܐ',
  'ܐܒܝܠܐ',
  'ܡܒܘܥܐ',
  'ܣܘܠܢܐ',
  'ܐܡܝܪܐ',
  'ܡܐܫܪܐ',
  'ܥܘܠܒܐ',
  'ܡܚܡܝܐ',
  'ܣܓܠܬܐ',
  'ܣܝܠܬܐ',
  'ܦܛܘܚܐ',
  'ܡܒܢܩܠ',
  'ܢܗܪܢܐ',
  'ܒܝܥܬܐ',
  'ܡܡܟܟܐ',
  'ܕܘܬܠܒ',
  'ܣܝܘܛܐ',
  'ܝܡܒܪܣ',
  'ܫܚܡܝܐ',
  'ܫܚܬܢܐ',
  'ܐܘܪܙܐ',
  'ܒܩܘܝܐ',
  'ܫܒܥܣܪ',
  'ܡܩܢܫܪ',
  'ܫܪܘܒܐ',
  'ܪܒܘܠܝ',
  'ܕܣܬܝܓ',
  'ܡܦܠܬܫ',
  'ܒܘܣܡܐ',
  'ܢܘܟܠܐ',
  'ܚܘܬܡܐ',
  'ܦܠܫܬܐ',
  'ܫܠܦܬܐ',
  'ܒܚܪܬܐ',
  'ܬܢܘܝܐ',
  'ܬܘܡܝܡ',
  'ܬܪܬܢܘ',
  'ܙܝܢܬܐ',
  'ܩܒܘܪܐ',
  'ܣܒܘܟܐ',
  'ܥܙܘܦܐ',
  'ܓܝܕܡܐ',
  'ܦܪܕܝܢ',
  'ܥܡܪܝܐ',
  'ܩܪܝܒܐ',
  'ܥܙܘܪܐ',
  'ܐܓܘܢܐ',
  'ܗܓܘܓܐ',
  'ܩܘܒܒܐ',
  'ܡܚܨܢܐ',
  'ܦܘܛܩܐ',
  'ܚܣܘܟܐ',
  'ܒܗܕܟܐ',
  'ܪܘܫܥܐ',
  'ܡܪܘܝܐ',
  'ܫܘܝܢܐ',
  'ܡܕܪܩܠ',
  'ܨܚܘܢܐ',
  'ܠܪܣܬܢ',
  'ܡܘܗܪܐ',
  'ܡܘܡܬܐ',
  'ܠܒܘܫܐ',
  'ܢܘܟܝܐ',
  'ܩܘܝܡܫ',
  'ܒܪܢܒܐ',
  'ܣܦܝܬܐ',
  'ܫܢܘܚܐ',
  'ܫܝܡܝܐ',
  'ܨܦܘܦܐ',
  'ܒܣܡܪܐ',
  'ܡܘܕܝܐ',
  'ܕܙܝܬܐ',
  'ܢܩܫܬܐ',
  'ܡܝܘܛܐ',
  'ܥܩܪܒܐ',
  'ܐܘܒܠܐ',
  'ܥܝܝܩܐ',
  'ܐܦܫܬܐ',
  'ܦܝܫܘܙ',
  'ܫܡܥܕܢ',
  'ܚܘܪܝܝ',
  'ܕܓܝܓܐ',
  'ܗܘܪܪܐ',
  'ܐܦܝܬܐ',
  'ܪܟܝܟܐ',
  'ܣܥܘܪܐ',
  'ܟܘܠܢܐ',
  'ܡܩܘܨܐ',
  'ܢܒܘܝܐ',
  'ܦܘܪܬܐ',
  'ܥܒܝܬܐ',
  'ܝܬܘܥܐ',
  'ܥܕܝܬܐ',
  'ܫܫܠܬܐ',
  'ܗܠܘܠܐ',
  'ܡܢܦܥܐ',
  'ܓܠܣܬܐ',
  'ܝܠܘܬܐ',
  'ܡܕܘܘܐ',
  'ܗܝܠܓܪ',
  'ܒܓܝܪܬ',
  'ܥܢܘܕܐ',
  'ܬܪܘܟܐ',
  'ܚܙܘܦܐ',
  'ܡܠܐܡܬ',
  'ܐܣܘܕܐ',
  'ܥܙܘܙܐ',
  'ܒܠܥܣܐ',
  'ܦܠܣܬܟ',
  'ܐܬܢܦܚ',
  'ܪܓܬܢܐ',
  'ܪܓܘܬܐ',
  'ܐܘܫܢܐ',
  'ܟܚܝܕܐ',
  'ܟܢܘܟܐ',
  'ܫܒܒܝܐ',
  'ܡܕܪܕܓ',
  'ܣܗܪܢܐ',
  'ܫܘܟܢܐ',
  'ܡܦܪܬܠ',
  'ܫܘܕܝܐ',
  'ܟܘܪܟܐ',
  'ܢܫܪܢܐ',
  'ܦܘܬܩܐ',
  'ܢܙܠܢܐ',
  'ܩܗܘܝܝ',
  'ܡܬܩܢܐ',
  'ܫܪܝܟܐ',
  'ܣܘܪܝܬ',
  'ܕܐܒܬܐ',
  'ܙܩܝܦܐ',
  'ܝܘܟܒܕ',
  'ܪܓܠܝܐ',
  'ܫܘܪܝܐ',
  'ܙܕܪܩܐ',
  'ܦܝܫܢܓ',
  'ܣܡܣܪܐ',
  'ܨܒܥܝܐ',
  'ܐܪܘܙܐ',
  'ܓܠܝܛܐ',
  'ܕܝܢܝܐ',
  'ܟܘܘܢܐ',
  'ܥܪܝܡܐ',
  'ܥܒܘܕܐ',
  'ܒܣܬܟܐ',
  'ܬܠܝܩܐ',
  'ܨܠܘܘܐ',
  'ܝܬܒܬܐ',
  'ܡܟܡܢܐ',
  'ܩܠܘܛܝ',
  'ܩܠܝܠܐ',
  'ܩܪܘܝܐ',
  'ܢܨܪܝܐ',
  'ܩܣܘܒܐ',
  'ܟܠܒܬܐ',
  'ܥܩܘܣܐ',
  'ܡܟܘܟܐ',
  'ܩܠܥܬܐ',
  'ܗܘܦܛܐ',
  'ܩܫܘܢܐ',
  'ܒܣܘܡܐ',
  'ܚܝܘܬܐ',
  'ܙܒܛܢܐ',
  'ܐܝܨܪܐ',
  'ܦܨܝܚܐ',
  'ܚܒܛܢܐ',
  'ܬܘܩܦܐ',
  'ܟܝܣܬܐ',
  'ܠܘܠܘܐ',
  'ܩܦܘܠܐ',
  'ܒܩܫܝܫ',
  'ܡܘܝܥܐ',
  'ܡܟܠܢܐ',
  'ܩܘܪܐܢ',
  'ܛܘܝܒܐ',
  'ܚܪܝܬܐ',
  'ܙܓܝܪܐ',
  'ܦܫܘܥܐ',
  'ܝܕܥܝܐ',
  'ܪܫܘܝܐ',
  'ܡܬܚܝܐ',
  'ܙܢܝܬܐ',
  'ܡܘܪܕܐ',
  'ܐܘܢܬܐ',
  'ܕܡܕܘܓ',
  'ܩܝܡܬܐ',
  'ܡܘܚܝܐ',
  'ܡܬܘܒܐ',
  'ܡܦܬܦܬ',
  'ܛܡܪܢܐ',
  'ܪܘܝܚܐ',
  'ܓܕܘܝܐ',
  'ܐܫܕܗܐ',
  'ܡܒܙܡܪ',
  'ܡܛܪܢܐ',
  'ܡܫܗܘܪ',
  'ܐܫܬܝܢ',
  'ܬܕܡܘܪ',
  'ܣܠܐܬܐ',
  'ܨܡܬܘܪ',
  'ܐܬܬܝܒ',
  'ܚܢܢܝܐ',
  'ܕܘܪܫܐ',
  'ܫܒܥܬܐ',
  'ܚܢܘܨܐ',
  'ܢܬܪܬܐ',
  'ܩܠܘܠܐ',
  'ܠܘܕܪܐ',
  'ܐܪܝܢܐ',
  'ܪܥܝܝܐ',
  'ܚܡܛܢܐ',
  'ܗܓܘܝܐ',
  'ܚܝܠܦܐ',
  'ܨܒܝܢܐ',
  'ܣܡܘܢܐ',
  'ܡܛܝܒܐ',
  'ܟܡܝܠܐ',
  'ܟܘܦܬܐ',
  'ܣܝܘܥܐ',
  'ܚܟܝܡܐ',
  'ܩܘܬܬܐ',
  'ܥܫܝܢܐ',
  'ܡܩܕܫܐ',
  'ܝܡܝܫܝ',
  'ܣܒܘܢܐ',
  'ܫܛܘܚܐ',
  'ܐܛܘܡܐ',
  'ܟܢܬܠܝ',
  'ܡܫܝܕܢ',
  'ܢܕܝܬܐ',
  'ܨܘܒܥܐ',
  'ܫܠܩܢܐ',
  'ܩܘܡܡܐ',
  'ܛܐܘܠܐ',
  'ܥܘܘܝܐ',
  'ܚܨܘܝܐ',
  'ܟܘܡܘܣ',
  'ܢܛܘܛܐ',
  'ܦܠܚܢܐ',
  'ܙܪܩܛܐ',
  'ܫܝܘܚܐ',
  'ܛܘܟܢܐ',
  'ܚܝܕܬܐ',
  'ܚܦܘܪܐ',
  'ܛܠܘܒܐ',
  'ܡܝܛܪܐ',
  'ܨܝܨܪܐ',
  'ܢܩܡܬܐ',
  'ܛܫܘܝܐ',
  'ܛܝܡܘܢ',
  'ܢܚܝܒܐ',
  'ܦܘܪܝܐ',
  'ܣܠܢܬܐ',
  'ܪܡܝܙܐ',
  'ܛܠܫܒܐ',
  'ܡܛܢܛܢ',
  'ܡܥܝܬܐ',
  'ܣܘܛܡܐ',
  'ܨܡܪܬܐ',
  'ܛܠܘܩܐ',
  'ܟܘܬܫܐ',
  'ܚܩܪܢܐ',
  'ܦܦܪܘܢ',
  'ܢܘܩܙܐ',
  'ܠܥܘܣܐ',
  'ܦܝܫܢܐ',
  'ܛܘܦܢܐ',
  'ܐܒܝܓܠ',
  'ܦܫܩܬܐ',
  'ܥܨܘܨܐ',
  'ܣܓܘܕܐ',
  'ܒܘܕܩܐ',
  'ܐܓܘܓܐ',
  'ܡܬܘܒܐ',
  'ܚܘܦܝܐ',
  'ܡܦܠܚܐ',
  'ܡܒܥܘܪ',
  'ܦܩܥܝܐ',
  'ܡܠܘܬܐ',
  'ܛܘܛܢܐ',
  'ܠܡܚܣܢ',
  'ܡܟܦܪܐ',
  'ܡܛܫܘܐ',
  'ܒܟܙܕܐ',
  'ܩܘܠܠܐ',
  'ܡܦܓܢܐ',
  'ܡܣܘܩܐ',
  'ܟܢܘܢܐ',
  'ܫܪܝܟܐ',
  'ܓܠܥܬܐ',
  'ܡܝܢܝܐ',
  'ܡܓܪܓܫ',
  'ܐܝܣܠܡ',
  'ܟܒܘܫܐ',
  'ܫܒܚܘܢ',
  'ܓܠܝܣܐ',
  'ܕܘܪܡܐ',
  'ܚܠܡܠܡ',
  'ܒܝܠܬܝ',
  'ܩܠܦܬܐ',
  'ܡܢܝܢܐ',
  'ܐܪܢܒܐ',
  'ܒܝܘܡܐ',
  'ܚܘܬܪܐ',
  'ܛܐܝܦܐ',
  'ܡܕܪܡܐ',
  'ܩܐܠܬܐ',
  'ܚܝܘܟܐ',
  'ܫܟܝܪܐ',
  'ܓܘܛܬܐ',
  'ܐܙܕܗܪ',
  'ܒܦܠܓܐ',
  'ܩܪܘܫܐ',
  'ܡܬܚܡܐ',
  'ܦܝܠܡܐ',
  'ܕܣܢܝܐ',
  'ܛܡܝܫܐ',
  'ܟܪܟܬܐ',
  'ܫܓܘܠܐ',
  'ܥܣܪܝܢ',
  'ܪܕܘܝܐ',
  'ܡܫܓܬܐ',
  'ܪܓܘܕܐ',
  'ܢܨܘܝܐ',
  'ܐܕܪܝܣ',
  'ܫܬܐܣܐ',
  'ܚܫܝܠܐ',
  'ܢܟܘܝܐ',
  'ܙܐܘܕܐ',
  'ܙܒܝܪܐ',
  'ܟܝܦܝܬ',
  'ܫܘܠܒܐ',
  'ܓܘܒܢܐ',
  'ܬܘܠܣܐ',
  'ܐܠܗܬܐ',
  'ܠܘܐܕܐ',
  'ܣܬܝܬܐ',
  'ܡܥܕܢܐ',
  'ܚܣܪܬܐ',
  'ܒܕܒܚܬ',
  'ܬܛܝܪܐ',
  'ܣܩܘܡܐ',
  'ܕܪܘܫܐ',
  'ܚܠܢܢܐ',
  'ܙܠܘܦܐ',
  'ܕܘܡܒܐ',
  'ܦܩܝܬܐ',
  'ܩܨܘܦܐ',
  'ܡܫܒܚܐ',
  'ܚܘܒܝܐ',
  'ܩܢܝܬܐ',
  'ܥܪܘܝܐ',
  'ܨܒܥܢܐ',
  'ܐܦܬܦܐ',
  'ܣܟܠܬܐ',
  'ܫܢܝܬܐ',
  'ܗܘܐܢܐ',
  'ܦܫܝܚܐ',
  'ܥܢܩܝܐ',
  'ܦܠܦܠܐ',
  'ܡܪܘܬܐ',
  'ܦܫܡܢܐ',
  'ܒܣܝܪܐ',
  'ܓܪܝܕܐ',
  'ܝܬܝܒܐ',
  'ܡܠܘܠܐ',
  'ܡܒܙܒܙ',
  'ܩܪܢܦܠ',
  'ܡܒܣܬܐ',
  'ܕܠܟܘܢ',
  'ܥܓܘܠܐ',
  'ܫܦܘܢܐ',
  'ܥܕܢܬܐ',
  'ܙܘܡܘܣ',
  'ܥܠܝܬܐ',
  'ܕܝܠܬܐ',
  'ܣܢܘܝܐ',
  'ܗܘܨܠܐ',
  'ܡܣܠܝܐ',
  'ܣܦܩܬܐ',
  'ܒܘܪܩܐ',
  'ܫܘܚܬܐ',
  'ܓܕܘܕܐ',
  'ܕܘܟܣܐ',
  'ܩܪܝܬܐ',
  'ܡܪܬܢܐ',
  'ܬܪܓܘܫ',
  'ܢܝܡܬܐ',
  'ܫܝܦܬܐ',
  'ܚܛܘܛܐ',
  'ܡܬܩܢܐ',
  'ܦܪܦܫܐ',
  'ܡܕܘܟܐ',
  'ܟܪܟܕܢ',
  'ܫܘܫܛܐ',
  'ܦܘܓܡܐ',
  'ܙܕܝܐܐ',
  'ܦܣܥܬܐ',
  'ܒܒܪܕܐ',
  'ܡܥܦܦܐ',
  'ܚܝܬܝܐ',
  'ܕܒܨܬܐ',
  'ܕܪܕܪܝ',
  'ܡܫܝܬܐ',
  'ܐܓܠܒܐ',
  'ܛܘܠܩܐ',
  'ܟܫܝܠܐ',
  'ܕܠܘܚܐ',
  'ܝܠܘܕܐ',
  'ܣܓܕܬܐ',
  'ܒܠܥܬܐ',
  'ܐܪܘܦܐ',
  'ܩܛܘܢܐ',
  'ܢܘܛܪܐ',
  'ܫܘܠܦܐ',
  'ܟܘܬܟܐ',
  'ܚܢܝܛܐ',
  'ܕܡܕܡܐ',
  'ܒܘܠܒܐ',
  'ܣܘܒܪܐ',
  'ܦܘܠܝܘ',
  'ܫܠܝܬܐ',
  'ܐܠܘܡܐ',
  'ܚܘܪܪܐ',
  'ܒܣܝܬܐ',
  'ܡܟܘܦܐ',
  'ܠܫܝܢܐ',
  'ܗܘܝܬܐ',
  'ܚܠܬܢܐ',
  'ܨܢܝܐܐ',
  'ܡܘܝܩܐ',
  'ܢܟܝܚܐ',
  'ܪܓܫܬܐ',
  'ܕܘܝܠܐ',
  'ܐܟܠܘܣ',
  'ܩܪܩܝܣ',
  'ܫܟܘܬܐ',
  'ܟܬܘܪܐ',
  'ܓܘܨܬܐ',
  'ܥܫܘܡܐ',
  'ܐܘܕܠܐ',
  'ܡܫܟܠܟ',
  'ܚܘܝܢܐ',
  'ܪܡܠܕܪ',
  'ܩܠܝܣܐ',
  'ܦܘܫܟܐ',
  'ܡܓܝܕܐ',
  'ܬܢܢܬܐ',
  'ܕܪܫܬܐ',
  'ܫܡܚܬܐ',
  'ܢܛܘܠܐ',
  'ܙܕܩܝܐ',
  'ܣܢܝܬܐ',
  'ܡܦܪܣܐ',
  'ܦܘܦܓܐ',
  'ܚܫܠܬܐ',
  'ܫܝܘܫܐ',
  'ܬܢܝܟܐ',
  'ܒܣܛܪܐ',
  'ܡܣܠܝܐ',
  'ܡܪܘܥܐ',
  'ܓܘܡܕܐ',
  'ܕܘܘܕܪ',
  'ܓܒܝܢܐ',
  'ܣܕܝܪܐ',
  'ܥܝܛܪܐ',
  'ܣܩܘܪܐ',
  'ܫܩܘܬܐ',
  'ܟܒܘܢܐ',
  'ܕܣܛܘܪ',
  'ܡܛܪܦܢ',
  'ܦܘܫܪܐ',
  'ܓܒܝܚܐ',
  'ܡܘܫܚܐ',
  'ܫܝܫܡܐ',
  'ܦܠܛܢܐ',
  'ܙܪܝܩܐ',
  'ܠܘܩܛܐ',
  'ܚܦܪܬܐ',
  'ܡܒܝܥܟ',
  'ܣܝܩܬܐ',
  'ܙܗܘܪܐ',
  'ܬܘܘܚܐ',
  'ܒܛܝܠܐ',
  'ܠܒܝܟܐ',
  'ܦܨܐܗܬ',
  'ܢܘܩܫܐ',
  'ܡܙܙܓܪ',
  'ܒܐܡܪܐ',
  'ܫܪܓܘܙ',
  'ܡܬܘܟܐ',
  'ܢܩܝܕܐ',
  'ܥܠܡܝܢ',
  'ܡܚܪܒܩ',
  'ܦܘܨܝܐ',
  'ܬܫܪܝܢ',
  'ܢܘܬܥܐ',
  'ܢܘܘܠܐ',
  'ܙܘܥܬܐ',
  'ܡܬܢܦܪ',
  'ܒܕܘܩܐ',
  'ܐܙܘܠܐ',
  'ܨܒܘܬܐ',
  'ܢܩܝܠܐ',
  'ܟܘܪܬܐ',
  'ܡܩܡܩܡ',
  'ܒܠܝܠܐ',
  'ܠܥܘܙܐ',
  'ܡܣܘܬܐ',
  'ܐܦܝܦܐ',
  'ܗܫܟܪܐ',
  'ܟܗܪܒܐ',
  'ܥܝܘܒܐ',
  'ܓܘܓܬܐ',
  'ܡܠܝܠܐ',
  'ܛܪܝܢܐ',
  'ܡܚܣܚܣ',
  'ܡܙܥܘܪ',
  'ܠܓܝܢܐ',
  'ܩܘܦܕܐ',
  'ܢܝܫܝܐ',
  'ܡܕܪܣܐ',
  'ܥܘܡܐܢ',
  'ܨܘܒܬܐ',
  'ܐܒܪܬܐ',
  'ܚܬܘܬܐ',
  'ܡܛܪܩܐ',
  'ܡܢܝܗܝ',
  'ܪܥܕܢܐ',
  'ܟܠܕܝܐ',
  'ܙܝܕܬܐ',
  'ܓܪܣܢܐ',
  'ܣܒܠܬܐ',
  'ܫܕܘܪܐ',
  'ܩܘܩܡܐ',
  'ܡܬܢܝܐ',
  'ܣܘܝܡܐ',
  'ܡܕܡܥܢ',
  'ܒܘܛܠܐ',
  'ܚܫܝܚܐ',
  'ܫܘܠܚܐ',
  'ܠܦܢܬܐ',
  'ܢܘܣܝܐ',
  'ܡܣܡܟܐ',
  'ܒܟܘܘܐ',
  'ܣܩܘܠܐ',
  'ܫܪܘܟܐ',
  'ܣܠܕܬܐ',
  'ܩܘܕܡܐ',
  'ܕܒܠܬܐ',
  'ܡܣܟܪܐ',
  'ܪܕܝܪܐ',
  'ܡܗܘܠܐ',
  'ܡܝܘܪܐ',
  'ܛܐܘܠܐ',
  'ܩܒܝܬܐ',
  'ܝܩܘܪܐ',
  'ܕܒܪܬܐ',
  'ܒܪܘܒܪ',
  'ܩܙܕܢܐ',
  'ܝܘܢܬܐ',
  'ܓܡܝܪܐ',
  'ܦܠܥܬܐ',
  'ܙܪܝܦܐ',
  'ܟܡܘܪܐ',
  'ܩܒܝܫܐ',
  'ܡܪܘܦܐ',
  'ܕܥܕܢܐ',
  'ܨܚܘܬܐ',
  'ܓܘܠܠܐ',
  'ܬܥܠܝܐ',
  'ܫܡܥܢܐ',
  'ܛܪܘܓܐ',
  'ܦܪܘܚܐ',
  'ܥܘܒܛܐ',
  'ܣܘܦܬܐ',
  'ܠܚܝܦܐ',
  'ܒܚܘܪܐ',
  'ܡܫܡܪܢ',
  'ܟܐܝܪܐ',
  'ܓܒܒܬܐ',
  'ܒܙܘܢܐ',
  'ܠܕܘܪܐ',
  'ܛܝܢܝܐ',
  'ܒܪܢܘܬ',
  'ܚܐܘܬܐ',
  'ܢܝܙܟܐ',
  'ܓܢܕܠܐ',
  'ܫܚܡܬܐ',
  'ܓܡܘܥܐ',
  'ܢܩܕܬܐ',
  'ܦܩܥܝܐ',
  'ܟܦܬܘܪ',
  'ܫܘܪܬܢ',
  'ܚܘܒܠܐ',
  'ܡܫܠܒܛ',
  'ܟܘܕܢܐ',
  'ܡܟܪܛܢ',
  'ܫܟܘܠܐ',
  'ܡܘܗܡܕ',
  'ܢܚܪܝܐ',
  'ܛܪܝܬܐ',
  'ܥܒܝܕܐ',
  'ܣܛܢܬܐ',
  'ܩܘܪܡܐ',
  'ܩܝܩܢܐ',
  'ܡܘܝܢܐ',
  'ܠܘܒܟܐ',
  'ܩܬܪܢܐ',
  'ܟܬܝܒܐ',
  'ܝܘܐܪܫ',
  'ܓܫܝܡܐ',
  'ܣܬܘܪܐ',
  'ܩܪܝܢܐ',
  'ܙܓܘܓܐ',
  'ܪܘܨܚܬ',
  'ܚܘܪܠܐ',
  'ܐܡܙܪܐ',
  'ܕܠܘܠܐ',
  'ܡܟܫܬܐ',
  'ܣܦܘܝܐ',
  'ܦܠܘܫܐ',
  'ܗܩܝܬܐ',
  'ܒܘܡܣܐ',
  'ܟܪܘܙܐ',
  'ܦܪܩܬܐ',
  'ܡܦܘܦܐ',
  'ܪܫܘܦܐ',
  'ܓܘܝܬܐ',
  'ܡܥܝܕܐ',
  'ܣܬܝܢܐ',
  'ܬܘܚܡܐ',
  'ܓܒܘܝܐ',
  'ܕܘܕܩܐ',
  'ܓܪܝܬܐ',
  'ܦܚܘܚܐ',
  'ܓܙܟܬܐ',
  'ܨܘܗܒܬ',
  'ܦܪܝܥܐ',
  'ܬܐܒܝܐ',
  'ܡܪܡܬܐ',
  'ܟܐܦܝܐ',
  'ܛܒܝܥܬ',
  'ܥܘܡܩܐ',
  'ܬܢܘܝܐ',
  'ܩܪܝܬܐ',
  'ܢܚܫܬܢ',
  'ܦܬܘܪܐ',
  'ܒܘܪܨܐ',
  'ܡܣܝܒܐ',
  'ܟܘܢܬܐ',
  'ܛܪܘܓܐ',
  'ܫܪܥܕܪ',
  'ܓܡܘܕܐ',
  'ܒܝܚܝܐ',
  'ܥܙܘܒܐ',
  'ܡܘܠܝܐ',
  'ܝܗܘܒܐ',
  'ܕܟܝܢܐ',
  'ܬܒܝܪܐ',
  'ܩܨܘܨܐ',
  'ܡܫܩܠܩ',
  'ܦܝܪܛܐ',
  'ܫܘܩܪܐ',
  'ܝܬܘܡܐ',
  'ܕܥܬܝܕ',
  'ܡܩܦܩܦ',
  'ܦܪܘܛܐ',
  'ܫܒܫܬܐ',
  'ܟܘܪܝܫ',
  'ܙܠܘܡܐ',
  'ܓܒܝܠܐ',
  'ܫܩܪܩܐ',
  'ܚܡܝܡܐ',
  'ܒܪܚܫܐ',
  'ܓܒܝܬܐ',
  'ܒܠܘܥܐ',
  'ܡܬܢܬܠ',
  'ܟܘܛܛܐ',
  'ܐܫܘܦܐ',
  'ܐܓܢܬܐ',
  'ܠܘܓܛܐ',
  'ܦܝܘܫܐ',
  'ܦܐܝܕܐ',
  'ܣܪܦܘܫ',
  'ܒܚܕܕܐ',
  'ܙܦܫܟܐ',
  'ܪܓܝܓܐ',
  'ܟܬܝܬܐ',
  'ܦܩܕܢܐ',
  'ܕܘܚܒܐ',
  'ܡܘܬܝܐ',
  'ܡܓܦܠܐ',
  'ܕܘܒܨܐ',
  'ܙܡܝܟܐ',
  'ܐܝܣܚܩ',
  'ܣܪܬܝܒ',
  'ܓܠܝܫܐ',
  'ܚܪܘܫܐ',
  'ܗܒܠܬܐ',
  'ܐܘܣܝܐ',
  'ܡܚܪܙܦ',
  'ܒܕܡܫܟ',
  'ܠܘܛܘܣ',
  'ܐܡܠܝܐ',
  'ܢܚܝܪܐ',
  'ܐܚܘܬܐ',
  'ܚܝܘܟܐ',
  'ܪܘܒܝܠ',
  'ܩܦܝܠܐ',
  'ܒܪܘܠܐ',
  'ܡܣܪܥܦ',
  'ܛܥܡܢܐ',
  'ܫܡܘܥܐ',
  'ܬܢܝܢܐ',
  'ܟܣܘܪܐ',
  'ܥܘܡܛܐ',
  'ܒܛܠܬܐ',
  'ܡܫܘܝܐ',
  'ܟܒܢܬܐ',
  'ܒܒܣܝܠ',
  'ܗܦܟܬܐ',
  'ܣܝܠܝܣ',
  'ܫܝܪܝܓ',
  'ܡܢܕܝܐ',
  'ܬܐܪܬܢ',
  'ܗܠܘܟܐ',
  'ܬܡܡܬܐ',
  'ܐܣܝܬܐ',
  'ܦܛܘܫܐ',
  'ܡܢܕܪܫ',
  'ܨܘܠܚܐ',
  'ܗܐܝܕܐ',
  'ܫܛܪܢܐ',
  'ܢܦܘܪܐ',
  'ܚܡܝܨܐ',
  'ܨܘܦܚܐ',
  'ܢܫܘܡܐ',
  'ܚܒܪܕܪ',
  'ܒܪܩܩܐ',
  'ܚܕܘܬܐ',
  'ܠܘܩܘܡ',
  'ܡܬܢܢܐ',
  'ܫܦܬܝܐ',
  'ܙܪܙܡܐ',
  'ܥܒܘܪܐ',
  'ܫܥܫܬܐ',
  'ܩܗܝܬܐ',
  'ܥܘܩܒܐ',
  'ܥܘܩܘܒ',
  'ܚܪܛܬܐ',
  'ܕܘܪܓܪ',
  'ܡܚܝܕܐ',
  'ܐܘܙܢܐ',
  'ܓܝܚܘܢ',
  'ܩܝܠܬܐ',
  'ܛܘܘܝܐ',
  'ܡܚܡܒܢ',
  'ܒܣܡܢܐ',
  'ܚܢܝܢܐ',
  'ܐܘܟܝܬ',
  'ܡܙܘܢܐ',
  'ܓܡܪܬܐ',
  'ܬܦܢܟܐ',
  'ܘܣܘܣܐ',
  'ܕܡܘܬܐ',
  'ܩܩܘܢܐ',
  'ܥܬܢܝܐ',
  'ܩܘܩܬܐ',
  'ܡܘܫܥܐ',
  'ܩܨܝܬܐ',
  'ܡܐܠܕܐ',
  'ܪܗܡܢܐ',
  'ܘܠܝܬܐ',
  'ܢܛܠܢܐ',
  'ܡܚܘܝܐ',
  'ܬܘܪܝܚ',
  'ܙܝܪܬܐ',
  'ܩܒܝܠܐ',
  'ܐܣܡܪܝ',
  'ܫܛܝܬܐ',
  'ܡܘܐܝܠ',
  'ܢܐܪܗܬ',
  'ܙܢܢܬܐ',
  'ܨܥܬܪܐ',
  'ܚܠܘܠܐ',
  'ܦܠܐܬܐ',
  'ܫܘܫܢܐ',
  'ܡܘܬܠܬ',
  'ܗܘܠܟܝ',
  'ܒܠܘܛܐ',
  'ܡܢܝܢܐ',
  'ܢܒܘܙܐ',
  'ܙܢܩܬܐ',
  'ܬܣܠܝܡ',
  'ܡܠܚܫܐ',
  'ܒܢܝܬܐ',
  'ܡܟܘܣܐ',
  'ܫܝܠܫܐ',
  'ܩܘܛܥܐ',
  'ܡܘܙܓܐ',
  'ܩܣܛܠܐ',
  'ܫܥܒܐܢ',
  'ܗܘܬܚܐ',
  'ܛܠܩܬܐ',
  'ܡܝܝܛܐ',
  'ܣܡܝܕܐ',
  'ܕܒܘܫܐ',
  'ܚܘܓܬܐ',
  'ܒܠܒܠܐ',
  'ܝܘܝܚܢ',
  'ܢܒܝܕܐ',
  'ܡܩܣܩܣ',
  'ܐܟܬܘܢ',
  'ܗܓܝܢܐ',
  'ܢܘܗܡܐ',
  'ܡܩܠܒܐ',
  'ܓܘܙܥܢ',
  'ܒܪܟܬܐ',
  'ܚܓܝܣܐ',
  'ܐܕܡܬܐ',
  'ܫܘܦܥܐ',
  'ܦܝܛܘܢ',
  'ܡܫܩܫܩ',
  'ܩܣܡܬܐ',
  'ܙܝܬܝܐ',
  'ܬܡܙܬܐ',
  'ܡܢܘܕܐ',
  'ܪܟܘܒܐ',
  'ܢܡܓܬܪ',
  'ܚܢܢܬܐ',
  'ܡܣܝܬܐ',
  'ܦܝܫܘܢ',
  'ܩܪܝܬܐ',
  'ܥܘܕܒܐ',
  'ܦܡܘܡܐ',
  'ܟܪܝܬܐ',
  'ܥܘܙܠܐ',
  'ܡܒܪܒܪ',
  'ܛܐܘܡܐ',
  'ܢܛܝܠܐ',
  'ܬܓܒܝܪ',
  'ܦܪܙܘܢ',
  'ܬܕܐܝܐ',
  'ܡܫܗܘܬ',
  'ܢܗܝܪܐ',
  'ܨܘܕܪܐ',
  'ܪܕܝܬܐ',
  'ܕܚܘܩܐ',
  'ܚܒܘܛܐ',
  'ܒܘܥܕܐ',
  'ܬܐܢܬܐ',
  'ܝܡܢܝܐ',
  'ܬܠܝܡܐ',
  'ܣܡܘܟܐ',
  'ܩܘܩܬܐ',
  'ܡܚܘܬܐ',
  'ܨܪܘܡܐ',
  'ܦܫܘܛܐ',
  'ܕܩܢܢܐ',
  'ܡܘܚܪܡ',
  'ܡܦܙܦܙ',
  'ܣܘܗܒܬ',
  'ܚܘܠܦܐ',
  'ܐܝܣܪܐ',
  'ܐܢܕܙܐ',
  'ܠܘܠܓܐ',
  'ܫܠܘܚܐ',
  'ܪܙܢܝܐ',
  'ܒܘܗܪܐ',
  'ܡܗܪܗܡ',
  'ܫܒܝܬܐ',
  'ܦܫܘܩܐ',
  'ܛܪܝܡܐ',
  'ܢܘܩܦܐ',
  'ܫܪܒܬܐ',
  'ܩܘܠܓܐ',
  'ܣܦܘܩܐ',
  'ܬܠܘܫܐ',
  'ܡܛܡܐܐ',
  'ܡܥܠܢܐ',
  'ܣܦܝܪܐ',
  'ܫܘܪܒܐ',
  'ܫܩܝܦܐ',
  'ܩܝܢܘܢ',
  'ܨܚܝܚܐ',
  'ܡܘܓܠܐ',
  'ܓܘܡܠܐ',
  'ܢܝܘܨܐ',
  'ܥܢܢܝܐ',
  'ܕܝܘܗܝ',
  'ܫܢܝܢܐ',
  'ܬܢܗܝܝ',
  'ܪܡܘܢܐ',
  'ܐܘܒܪܐ',
  'ܨܒܥܝܐ',
  'ܦܬܘܟܐ',
  'ܟܡܪܬܐ',
  'ܐܟܕܢܐ',
  'ܢܟܘܪܐ',
  'ܬܘܪܒܐ',
  'ܦܩܝܗܐ',
  'ܒܪܘܟܐ',
  'ܓܢܙܬܐ',
  'ܚܠܡܬܐ',
  'ܫܘܠܬܐ',
  'ܣܝܣܢܐ',
  'ܐܘܣܝܐ',
  'ܟܦܘܫܐ',
  'ܦܘܫܩܐ',
  'ܫܥܠܬܐ',
  'ܢܒܝܝܐ',
  'ܥܘܝܕܐ',
  'ܩܣܕܢܐ',
  'ܪܟܠܝܐ',
  'ܨܚܘܝܐ',
  'ܡܨܢܨܦ',
  'ܙܥܘܩܐ',
  'ܒܝܪܚܐ',
  'ܡܬܠܝܐ',
  'ܫܡܝܪܐ',
  'ܨܦܝܪܐ',
  'ܚܘܘܛܐ',
  'ܩܪܛܠܐ',
  'ܦܬܝܘܢ',
  'ܠܡܘܬܐ',
  'ܒܘܩܝܐ',
  'ܓܪܣܟܐ',
  'ܚܙܘܢܐ',
  'ܡܬܐܡܐ',
  'ܬܪܟܝܫ',
  'ܥܩܒܢܐ',
  'ܚܪܘܣܐ',
  'ܡܘܟܒܐ',
  'ܗܕܐܝܠ',
  'ܡܦܢܦܚ',
  'ܛܡܥܟܪ',
  'ܡܕܘܪܐ',
  'ܦܪܣܝܣ',
  'ܓܘܒܝܐ',
  'ܝܠܘܦܐ',
  'ܩܛܘܢܐ',
  'ܣܦܪܬܐ',
  'ܐܬܘܬܐ',
  'ܡܚܪܫܢ',
  'ܕܪܕܢܐ',
  'ܐܘܙܘܢ',
  'ܥܣܘܣܐ',
  'ܙܝܘܢܐ',
  'ܩܕܡܬܐ',
  'ܡܢܙܘܢ',
  'ܥܨܠܬܐ',
  'ܣܡܠܝܐ',
  'ܨܦܚܢܐ',
  'ܡܚܪܕܐ',
  'ܙܛܘܡܐ',
  'ܡܘܪܙܐ',
  'ܦܘܙܢܐ',
  'ܒܕܘܝܐ',
  'ܣܡܘܡܐ',
  'ܦܚܘܠܐ',
  'ܡܫܘܫܐ',
  'ܡܣܩܪܐ',
  'ܚܪܘܦܐ',
  'ܟܘܪܟܐ',
  'ܡܕܪܕܚ',
  'ܡܦܪܫܚ',
  'ܦܢܝܬܐ',
  'ܒܨܝܪܐ',
  'ܫܘܪܠܐ',
  'ܝܘܢܬܢ',
  'ܙܘܢܝܐ',
  'ܟܪܬܝܓ',
  'ܒܒܠܬܐ',
  'ܕܪܘܡܐ',
  'ܗܪܣܠܐ',
  'ܚܡܫܝܢ',
  'ܩܘܪܝܫ',
  'ܓܘܠܩܐ',
  'ܦܩܪܢܐ',
  'ܓܘܢܚܐ',
  'ܟܘܚܬܐ',
  'ܡܓܘܫܐ',
  'ܥܒܕܢܐ',
  'ܒܙܥܙܐ',
  'ܬܢܬܢܐ',
  'ܫܡܝܢܐ',
  'ܥܡܘܛܐ',
  'ܓܕܘܠܐ',
  'ܡܟܠܟܬ',
  'ܫܬܠܬܐ',
  'ܐܒܝܕܐ',
  'ܩܘܠܒܐ',
  'ܡܐܟܠܐ',
  'ܚܫܟܢܐ',
  'ܓܪܫܘܡ',
  'ܡܨܪܝܡ',
  'ܫܕܝܬܐ',
  'ܩܣܦܝܐ',
  'ܦܩܝܕܐ',
  'ܗܘܪܣܬ',
  'ܚܘܓܬܐ',
  'ܢܒܚܬܐ',
  'ܛܘܦܚܐ',
  'ܨܘܢܡܐ',
  'ܫܚܝܢܐ',
  'ܓܪܝܦܐ',
  'ܩܒܘܠܐ',
  'ܬܒܥܬܐ',
  'ܠܫܘܢܐ',
  'ܡܫܒܗܪ',
  'ܪܥܫܬܐ',
  'ܫܪܝܩܐ',
  'ܨܠܘܡܐ',
  'ܠܘܠܒܐ',
  'ܓܗܢܬܐ',
  'ܡܓܕܝܐ',
  'ܡܦܠܬܐ',
  'ܡܐܡܪܐ',
  'ܠܚܘܫܐ',
  'ܒܘܬܬܐ',
  'ܙܒܢܬܐ',
  'ܟܣܝܪܐ',
  'ܗܟܘܡܐ',
  'ܫܘܝܢܐ',
  'ܡܬܓܒܪ',
  'ܒܘܪܢܐ',
  'ܡܕܪܫܢ',
  'ܓܒܪܢܐ',
  'ܚܪܘܬܐ',
  'ܬܘܠܘܓ',
  'ܠܫܚܘܪ',
  'ܢܘܟܦܐ',
  'ܦܝܫܠܝ',
  'ܬܙܝܢܐ',
  'ܨܠܝܠܐ',
  'ܦܘܚܠܐ',
  'ܩܘܒܠܐ',
  'ܙܩܪܬܐ',
  'ܙܒܢܝܐ',
  'ܦܘܓܡܝ',
  'ܬܝܪܬܐ',
  'ܥܘܛܫܐ',
  'ܪܦܘܝܐ',
  'ܡܫܟܒܐ',
  'ܓܝܘܠܐ',
  'ܫܫܠܬܐ',
  'ܡܘܣܘܣ',
  'ܬܡܘܡܐ',
  'ܣܢܕܢܐ',
  'ܡܗܪܢܐ',
  'ܡܘܡܬܐ',
  'ܨܒܐܘܬ',
  'ܡܫܪܦܩ',
  'ܓܘܓܠܐ',
  'ܫܘܦܠܐ',
  'ܢܘܙܠܐ',
  'ܟܠܝܦܐ',
  'ܫܒܥܝܢ',
  'ܠܓܘܝܝ',
  'ܩܛܝܥܐ',
  'ܚܡܘܨܐ',
  'ܣܒܥܝܐ',
  'ܚܒܘܪܐ',
  'ܕܚܘܠܐ',
  'ܒܥܘܬܐ',
  'ܥܘܢܦܐ',
  'ܢܬܝܬܐ',
  'ܝܠܗܢܝ',
  'ܢܦܝܪܐ',
  'ܚܠܒܢܐ',
  'ܡܘܩܕܡ',
  'ܥܘܛܫܐ',
  'ܣܘܟܢܐ',
  'ܚܪܘܣܐ',
  'ܛܥܘܡܐ',
  'ܥܟܘܙܐ',
  'ܠܘܥܣܐ',
  'ܓܘܘܓܐ',
  'ܨܕܝܕܐ',
  'ܫܢܘܝܐ',
  'ܬܢܝܚܐ',
  'ܓܙܪܬܐ',
  'ܡܟܝܢܐ',
  'ܫܘܡܪܐ',
  'ܡܦܛܪܡ',
  'ܣܡܪܡܐ',
  'ܡܓܣܢܐ',
  'ܛܠܝܒܐ',
  'ܗܨܠܬܐ',
  'ܡܣܝܬܐ',
  'ܥܪܝܬܐ',
  'ܪܐܝܟܐ',
  'ܡܚܪܚܪ',
  'ܡܛܝܒܐ',
  'ܐܒܘܬܐ',
  'ܩܒܠܢܐ',
  'ܓܒܝܬܐ',
  'ܩܝܢܪܣ',
  'ܗܢܝܐܐ',
  'ܓܒܪܬܐ',
  'ܪܘܫܩܐ',
  'ܡܛܒܒܐ',
  'ܚܒܘܠܐ',
  'ܡܘܪܣܐ',
  'ܩܘܛܥܐ',
  'ܕܡܝܟܐ',
  'ܣܝܘܛܐ',
  'ܡܡܘܢܐ',
  'ܒܛܘܒܐ',
  'ܡܗܢܕܫ',
  'ܡܒܠܒܨ',
  'ܟܣܢܝܐ',
  'ܚܘܠܬܐ',
  'ܪܝܛܠܐ',
  'ܗܪܣܝܣ',
  'ܣܛܘܛܐ',
  'ܙܒܘܠܐ',
  'ܫܘܚܕܐ',
  'ܕܪܝܬܐ',
  'ܟܪܟܬܐ',
  'ܩܪܝܫܐ',
  'ܩܠܝܡܐ',
  'ܓܠܝܢܐ',
  'ܫܘܬܣܐ',
  'ܚܘܕܪܐ',
  'ܬܢܘܚܐ',
  'ܢܟܣܬܐ',
  'ܦܙܗܪܝ',
  'ܦܩܚܝܐ',
  'ܪܪܨܗܬ',
  'ܫܬܘܝܐ',
  'ܨܢܢܬܐ',
  'ܬܦܘܚܐ',
  'ܨܦܘܪܐ',
  'ܡܥܩܠܢ',
  'ܩܘܚܬܐ',
  'ܓܘܣܬܚ',
  'ܐܪܝܘܣ',
  'ܩܢܙܬܐ',
  'ܓܘܢܨܐ',
  'ܐܝܬܝܐ',
  'ܡܘܠܕܐ',
  'ܪܦܬܝܐ',
  'ܛܘܓܪܐ',
  'ܒܚܠܢܐ',
  'ܒܐܝܢܐ',
  'ܐܐܪܝܐ',
  'ܫܒܘܥܐ',
  'ܩܛܥܢܐ',
  'ܫܪܘܝܐ',
  'ܓܡܫܬܐ',
  'ܝܟܐܝܬ',
  'ܡܪܝܥܐ',
  'ܡܕܘܕܐ',
  'ܫܘܠܘܩ',
  'ܡܡܘܠܐ',
  'ܟܘܪܦܐ',
  'ܕܥܨܬܐ',
  'ܡܠܝܬܐ',
  'ܒܘܚܬܢ',
  'ܕܡܟܬܐ',
  'ܐܘܫܦܐ',
  'ܠܝܠܝܐ',
  'ܣܘܛܦܐ',
  'ܡܕܢܕܢ',
  'ܡܩܛܪܐ',
  'ܣܪܝܙܕ',
  'ܓܠܓܠܐ',
  'ܡܥܪܒܐ',
  'ܦܠܝܡܐ',
  'ܬܪܘܨܐ',
  'ܦܣܘܪܐ',
  'ܢܒܘܪܐ',
  'ܟܒܫܬܐ',
  'ܡܠܘܛܐ',
  'ܬܙܟܪܐ',
  'ܩܘܘܡܐ',
  'ܕܒܘܚܐ',
  'ܥܒܕܠܐ',
  'ܐܬܘܝܐ',
  'ܝܡܘܬܐ',
  'ܡܙܪܝܐ',
  'ܒܬܘܠܐ',
  'ܥܘܪܠܐ',
  'ܬܒܥܬܐ',
  'ܛܪܦܝܠ',
  'ܨܦܘܝܐ',
  'ܡܙܪܙܡ',
  'ܩܠܒܠܩ',
  'ܡܣܬܪܐ',
  'ܕܝܢܪܐ',
  'ܗܓܘܝܐ',
  'ܡܛܝܬܐ',
  'ܨܗܘܠܐ',
  'ܨܒܥܘܢ',
  'ܫܪܝܬܐ',
  'ܕܚܠܬܐ',
  'ܫܡܚܬܐ',
  'ܐܪܒܥܐ',
  'ܒܟܬܬܐ',
  'ܫܘܟܪܐ',
  'ܪܘܝܬܐ',
  'ܫܦܪܝܐ',
  'ܚܪܫܝܐ',
  'ܚܡܝܢܐ',
  'ܦܝܕܪܝ',
  'ܨܢܥܬܐ',
  'ܚܒܪܬܐ',
  'ܕܬܠܬܐ',
  'ܚܘܫܟܐ',
  'ܐܣܦܢܐ',
  'ܐܓܘܪܐ',
  'ܡܘܫܚܐ',
  'ܦܠܘܣܐ',
  'ܓܥܝܪܐ',
  'ܡܘܕܢܐ',
  'ܥܘܩܡܐ',
  'ܫܦܘܢܐ',
  'ܬܘܠܬܐ',
  'ܓܘܪܢܐ',
  'ܡܠܣܠܣ',
  'ܕܘܓܫܐ',
  'ܡܦܪܕܐ',
  'ܚܘܠܨܐ',
  'ܫܟܝܦܐ',
  'ܦܫܛܬܐ',
  'ܡܬܢܬܫ',
  'ܣܙܓܪܝ',
  'ܪܘܛܢܐ',
  'ܟܪܘܒܐ',
  'ܣܚܘܪܐ',
  'ܢܙܝܪܐ',
  'ܪܐܪܬܐ',
  'ܨܒܚܬܐ',
  'ܦܩܝܠܐ',
  'ܒܪܡܫܐ',
  'ܥܕܗܫܐ',
  'ܩܠܝܕܐ',
  'ܠܐܝܟܐ',
  'ܕܘܠܒܐ',
  'ܛܘܦܬܐ',
  'ܦܘܢܝܐ',
  'ܕܘܕܪܓ',
  'ܒܗܪܬܐ',
  'ܩܒܪܬܐ',
  'ܢܘܪܘܙ',
  'ܡܩܒܝܐ',
  'ܥܛܦܬܐ',
  'ܩܠܒܬܐ',
  'ܫܦܪܢܐ',
  'ܕܚܝܠܐ',
  'ܛܟܣܝܣ',
  'ܡܢܓܢܐ',
  'ܠܘܝܬܐ',
  'ܐܓܪܢܝ',
  'ܢܡܘܪܕ',
  'ܬܩܝܦܐ',
  'ܐܘܘܢܐ',
  'ܡܪܥܝܐ',
  'ܥܠܡܝܐ',
  'ܡܥܪܬܐ',
  'ܟܘܪܝܐ',
  'ܠܚܘܝܐ',
  'ܦܪܘܢܐ',
  'ܐܘܪܡܝ',
  'ܪܘܚܡܐ',
  'ܓܠܦܢܐ',
  'ܚܡܨܬܐ',
  'ܩܠܡܘܚ',
  'ܫܒܬܝܐ',
  'ܣܝܠܐܒ',
  'ܬܘܒܪܐ',
  'ܥܩܝܣܐ',
  'ܟܪܝܗܐ',
  'ܡܒܠܛܐ',
  'ܫܡܫܘܢ',
  'ܠܘܝܬܢ',
  'ܬܘܒܟܪ',
  'ܥܪܒܢܐ',
  'ܛܒܚܬܐ',
  'ܣܘܙܢܓ',
  'ܐܣܬܝܪ',
  'ܡܙܪܙܐ',
  'ܡܣܪܗܒ',
  'ܓܦܝܦܐ',
  'ܣܝܒܬܐ',
  'ܠܟܣܠܗ',
  'ܩܘܠܠܐ',
  'ܗܘܘܬܐ',
  'ܥܘܦܩܐ',
  'ܗܕܘܣܐ',
  'ܬܪܡܐܐ',
  'ܡܕܒܠܩ',
  'ܛܝܙܬܐ',
  'ܐܝܠܝܢ',
  'ܒܪܘܫܝ',
  'ܕܐܫܒܥ',
  'ܫܝܚܢܐ',
  'ܦܝܓܪܐ',
  'ܐܪܚܝܢ',
  'ܪܩܝܥܐ',
  'ܨܘܪܦܐ',
  'ܓܠܢܝܐ',
  'ܕܘܚܢܐ',
  'ܙܢܒܘܠ',
  'ܠܐܣܐܐ',
  'ܠܘܝܬܢ',
  'ܡܥܣܪܐ',
  'ܙܘܝܙܐ',
  'ܬܘܬܢܐ',
  'ܐܒܝܕܐ',
  'ܬܟܪܬܐ',
  'ܕܪܝܢܐ',
  'ܨܠܠܬܐ',
  'ܢܘܬܦܐ',
  'ܦܣܬܢܟ',
  'ܡܪܩܪܩ',
  'ܗܡܐܝܠ',
  'ܕܪܘܓܐ',
  'ܢܝܢܝܐ',
  'ܐܡܘܡܐ',
  'ܡܬܢܒܠ',
  'ܩܦܣܢܐ',
  'ܦܫܝܬܐ',
  'ܚܘܠܠܐ',
  'ܐܡܢܬܐ',
  'ܬܦܚܬܐ',
  'ܚܦܝܝܐ',
  'ܫܘܒܥܐ',
  'ܬܒܝܥܐ',
  'ܣܘܪܚܐ',
  'ܢܓܝܪܐ',
  'ܠܘܚܫܐ',
  'ܡܢܘܪܐ',
  'ܫܥܘܬܐ',
  'ܣܦܝܣܐ',
  'ܡܪܘܬܐ',
  'ܕܝܘܩܐ',
  'ܝܨܝܨܐ',
  'ܙܡܪܝܐ',
  'ܡܕܪܢܐ',
  'ܒܪܘܢܐ',
  'ܢܣܝܣܐ',
  'ܡܣܚܪܬ',
  'ܥܕܝܝܐ',
  'ܒܘܢܒܐ',
  'ܦܚܪܬܐ',
  'ܫܪܚܬܐ',
  'ܫܡܘܫܐ',
  'ܡܙܟܝܐ',
  'ܚܘܝܓܐ',
  'ܢܘܕܢܐ',
  'ܫܟܝܪܐ',
  'ܩܠܘܠܐ',
  'ܫܪܝܒܐ',
  'ܪܡܕܐܢ',
  'ܠܝܡܝܐ',
  'ܐܗܘܠܬ',
  'ܚܘܒܨܐ',
  'ܟܬܒܝܐ',
  'ܡܣܟܡܐ',
  'ܡܛܘܪܐ',
  'ܗܘܕܝܐ',
  'ܦܪܥܘܢ',
  'ܕܒܘܩܐ',
  'ܫܥܘܡܐ',
  'ܡܐܪܙܢ',
  'ܡܓܘܢܐ',
  'ܒܘܒܟܐ',
  'ܡܘܗܪܐ',
  'ܦܢܕܟܪ',
  'ܥܦܪܝܐ',
  'ܡܘܫܘܠ',
  'ܐܣܣܬܐ',
  'ܡܪܝܡܐ',
  'ܠܥܘܒܐ',
  'ܟܘܟܒܐ',
  'ܡܠܘܡܐ',
  'ܚܘܪܙܐ',
  'ܡܡܚܬܐ',
  'ܘܙܝܪܐ',
  'ܫܠܘܡܐ',
  'ܦܫܪܬܐ',
  'ܣܘܕܩܐ',
  'ܠܟܘܢܐ',
  'ܡܠܝܠܐ',
  'ܟܐܢܬܐ',
  'ܩܛܪܢܐ',
  'ܓܒܥܬܐ',
  'ܚܦܘܪܐ',
  'ܡܕܡܝܐ',
  'ܬܝܒܬܐ',
  'ܩܙܠܒܫ',
  'ܩܒܝܥܐ',
  'ܦܠܚܬܐ',
  'ܡܚܘܡܐ',
  'ܡܥܘܕܐ',
  'ܫܦܝܠܐ',
  'ܪܨܝܦܐ',
  'ܨܠܘܬܐ',
  'ܛܠܝܬܐ',
  'ܕܒܘܪܐ',
  'ܚܝܘܬܐ',
  'ܚܪܘܓܐ',
  'ܬܙܝܙܐ',
  'ܙܝܘܢܐ',
  'ܫܘܪܒܝ',
  'ܚܒܪܘܢ',
  'ܩܡܨܬܐ',
  'ܒܘܪܡܐ',
  'ܠܝܛܪܐ',
  'ܨܕܝܕܐ',
  'ܪܚܫܝܐ',
  'ܫܘܚܪܐ',
  'ܒܠܘܡܐ',
  'ܕܘܟܝܐ',
  'ܫܠܘܠܐ',
  'ܟܫܡܝܫ',
  'ܣܘܕܪܐ',
  'ܦܫܪܢܐ',
  'ܥܟܘܪܐ',
  'ܦܝܣܘܟ',
  'ܟܘܫܝܐ',
  'ܩܠܘܪܐ',
  'ܚܙܘܝܐ',
  'ܒܠܚܢܐ',
  'ܟܘܘܙܐ',
  'ܫܪܘܬܐ',
  'ܒܕܝܬܐ',
  'ܒܬܫܒܥ',
  'ܫܩܪܩܐ',
  'ܣܕܘܢܐ',
  'ܐܘܣܠܐ',
  'ܬܝܘܒܐ',
  'ܙܓܘܢܐ',
  'ܕܕܘܢܐ',
  'ܥܘܫܪܐ',
  'ܐܫܟܪܐ',
  'ܚܫܒܘܢ',
  'ܗܕܝܐܐ',
  'ܝܠܨܘܝ',
  'ܡܙܡܢܐ',
  'ܣܪܘܦܐ',
  'ܣܩܕܬܐ',
  'ܦܪܘܟܐ',
  'ܡܕܝܩܐ',
  'ܢܡܝܩܐ',
  'ܙܪܘܥܐ',
  'ܣܘܦܪܐ',
  'ܐܙܕܗܐ',
  'ܪܘܡܝܐ',
  'ܢܡܘܣܐ',
  'ܩܨܡܬܐ',
  'ܠܝܟܝܢ',
  'ܕܡܢܝܐ',
  'ܫܬܝܬܐ',
  'ܓܘܝܚܐ',
  'ܚܘܝܕܐ',
  'ܡܚܡܪܐ',
  'ܪܡܘܝܐ',
  'ܚܘܪܕܐ',
  'ܫܫܒܨܪ',
  'ܙܡܪܬܐ',
  'ܙܗܝܪܐ',
  'ܕܝܘܟܘ',
  'ܚܘܕܝܐ',
  'ܩܢܘܢܐ',
  'ܗܘܕܪܐ',
  'ܟܘܟܬܐ',
  'ܓܘܢܣܩ',
  'ܫܥܘܠܐ',
  'ܫܟܝܬܐ',
  'ܩܘܛܡܐ',
  'ܡܥܣܦܪ',
  'ܣܘܘܕܐ',
  'ܡܟܐܢܐ',
  'ܒܪܢܫܐ',
  'ܐܕܡܬܐ',
  'ܓܪܕܬܐ',
  'ܡܝܬܬܐ',
  'ܨܘܪܥܐ',
  'ܣܒܠܬܐ',
  'ܛܡܘܬܐ',
  'ܕܘܚܠܐ',
  'ܛܘܦܪܐ',
  'ܢܦܝܚܐ',
  'ܓܫܘܢܐ',
  'ܓܓܘܝܐ',
  'ܦܬܢܟܪ',
  'ܥܠܘܒܐ',
  'ܫܛܘܪܐ',
  'ܝܘܩܪܐ',
  'ܦܣܬܩܐ',
  'ܢܘܫܛܐ',
  'ܐܡܘܪܐ',
  'ܬܘܢܒܐ',
  'ܐܘܩܪܢ',
  'ܚܢܩܬܐ',
  'ܩܢܦܝܐ',
  'ܡܨܡܬܐ',
  'ܦܘܢܟܣ',
  'ܗܘܕܣܐ',
  'ܩܨܨܬܐ',
  'ܬܦܟܬܐ',
  'ܫܘܒܢܐ',
  'ܦܕܘܕܐ',
  'ܡܘܪܝܐ',
  'ܡܪܩܘܣ',
  'ܐܚܘܕܐ',
  'ܗܡܢܬܐ',
  'ܒܙܘܥܐ',
  'ܢܐܚܘܫ',
  'ܣܘܪܛܐ',
  'ܟܕܘܪܐ',
  'ܬܠܘܘܐ',
  'ܒܗܝܪܐ',
  'ܚܪܘܫܬ',
  'ܥܘܪܒܐ',
  'ܠܐܘܬܐ',
  'ܡܓܢܝܐ',
  'ܒܫܝܠܐ',
  'ܗܪܘܕܣ',
  'ܦܪܘܣܐ',
  'ܩܠܡܕܢ',
  'ܒܝܫܬܐ',
  'ܐܬܘܢܐ',
  'ܪܬܝܬܐ',
  'ܦܬܓܡܐ',
  'ܡܛܚܬܐ',
  'ܥܪܒܠܐ',
  'ܫܦܘܦܐ',
  'ܡܘܙܪܐ',
  'ܐܣܬܪܐ',
  'ܬܢܝܬܐ',
  'ܩܘܢܝܐ',
  'ܡܣܪܓܢ',
  'ܩܦܩܠܝ',
  'ܬܚܘܡܐ',
  'ܟܐܟܘܐ',
  'ܠܟܣܠܗ',
  'ܐܢܢܩܐ',
  'ܓܝܘܪܐ',
  'ܥܘܦܪܐ',
  'ܩܡܝܢܐ',
  'ܒܨܝܢܐ',
  'ܬܪܡܢܐ',
  'ܫܐܘܬܐ',
  'ܢܦܘܩܐ',
  'ܐܝܠܘܠ',
  'ܢܪܓܝܠ',
  'ܫܝܚܢܐ',
  'ܓܢܝܬܐ',
  'ܙܟܘܪܐ',
  'ܚܘܠܦܐ',
  'ܡܬܪܬܚ',
  'ܦܫܝܓܐ',
  'ܡܬܢܬܪ',
  'ܕܘܐܪܐ',
  'ܒܘܒܠܐ',
  'ܓܘܢܝܐ',
  'ܐܡܝܫܐ',
  'ܪܘܛܟܐ',
  'ܠܘܚܬܐ',
  'ܒܐܫܬܐ',
  'ܣܘܬܪܐ',
  'ܪܝܫܢܐ',
  'ܥܠܘܠܐ',
  'ܐܚܘܕܐ',
  'ܨܒܚܬܐ',
  'ܕܘܝܠܐ',
  'ܩܕܝܡܐ',
  'ܡܛܢܦܢ',
  'ܟܒܝܢܐ',
  'ܦܘܢܕܐ',
  'ܒܗܪܢܐ',
  'ܦܟܘܪܐ',
  'ܪܩܝܩܐ',
  'ܬܠܝܟܐ',
  'ܣܛܝܢܐ',
  'ܡܢܓܝܪ',
  'ܗܡܫܪܝ',
  'ܚܐܝܝܢ',
  'ܢܝܠܘܣ',
  'ܩܠܣܡܐ',
  'ܦܠܘܛܐ',
  'ܩܪܩܢܐ',
  'ܟܘܠܫܐ',
  'ܫܝܪܣܪ',
  'ܚܦܛܬܐ',
  'ܫܘܕܪܐ',
  'ܨܪܝܡܐ',
  'ܫܗܘܝܐ',
  'ܛܒܥܬܐ',
  'ܦܝܝܬܐ',
  'ܫܪܘܝܐ',
  'ܢܛܝܪܐ',
  'ܥܩܝܡܐ',
  'ܒܚܠܘܬ',
  'ܢܣܘܟܐ',
  'ܫܡܝܬܐ',
  'ܟܪܝܟܐ',
  'ܩܠܝܬܐ',
  'ܐܚܠܡܐ',
  'ܡܘܬܒܐ',
  'ܦܘܝܪܐ',
  'ܨܡܝܪܐ',
  'ܚܡܝܢܐ',
  'ܒܫܘܠܐ',
  'ܩܛܝܬܐ',
  'ܚܒܪܬܐ',
  'ܙܗܝܪܐ',
  'ܫܝܓܬܐ',
  'ܚܕܢܝܐ',
  'ܓܘܪܬܐ',
  'ܩܡܛܬܐ',
  'ܒܘܚܪܝ',
  'ܥܘܩܢܐ',
  'ܫܦܘܢܐ',
  'ܗܕܝܪܐ',
  'ܝܗܘܕܐ',
  'ܣܡܘܟܐ',
  'ܩܘܠܘܓ',
  'ܐܢܘܗܐ',
  'ܡܝܡܘܢ',
  'ܡܕܘܕܐ',
  'ܡܢܐܝܠ',
  'ܡܣܟܪܐ',
  'ܣܪܗܢܓ',
  'ܫܬܡܢܐ',
  'ܡܥܠܢܐ',
  'ܬܘܪܦܝ',
  'ܚܣܡܬܐ',
  'ܛܘܓܢܐ',
  'ܢܗܠܬܐ',
  'ܪܫܩܬܐ',
  'ܡܕܡܟܐ',
  'ܕܒܚܢܐ',
  'ܡܬܘܒܐ',
  'ܐܕܝܘܡ',
  'ܣܒܘܠܬ',
  'ܫܪܘܝܐ',
  'ܒܓܘܠܐ',
  'ܦܢܬܝܪ',
  'ܟܝܝܫܐ',
  'ܪܘܡܝܐ',
  'ܟܘܪܦܢ',
  'ܡܡܘܝܐ',
  'ܚܢܘܛܐ',
  'ܩܦܘܚܐ',
  'ܦܐܬܢܐ',
  'ܫܘܠܘܓ',
  'ܦܬܚܢܐ',
  'ܡܕܢܕܠ',
  'ܡܬܚܡܢ',
  'ܟܝܡܬܐ',
  'ܡܠܝܬܐ',
  'ܕܡܟܘܬ',
  'ܡܠܘܟܐ',
  'ܥܬܝܬܐ',
  'ܚܘܪܘܫ',
  'ܟܘܢܕܐ',
  'ܪܚܡܬܐ',
  'ܢܘܓܗܐ',
  'ܡܪܝܫܥ',
  'ܢܟܘܬܐ',
  'ܚܙܝܢܐ',
  'ܬܪܘܨܐ',
  'ܕܡܢܝܐ',
  'ܙܪܝܙܐ',
  'ܩܦܘܦܐ',
  'ܥܘܪܝܢ',
  'ܓܢܘܢܐ',
  'ܚܕܬܬܐ',
  'ܕܪܝܬܐ',
  'ܫܚܕܢܐ',
  'ܡܩܢܒܐ',
  'ܡܟܢܝܐ',
  'ܙܘܢܘܣ',
  'ܦܢܝܬܐ',
  'ܡܨܢܕܠ',
  'ܚܬܡܬܐ',
  'ܩܐܪܣܐ',
  'ܥܝܘܛܐ',
  'ܠܘܕܬܐ',
  'ܥܓܠܬܐ',
  'ܚܫܒܬܐ',
  'ܩܝܘܨܐ',
  'ܫܬܘܠܐ',
  'ܬܦܘܟܐ',
  'ܕܪܝܟܐ',
  'ܓܘܣܢܐ',
  'ܡܥܩܘܠ',
  'ܚܠܝܦܐ',
  'ܒܪܓܝܪ',
  'ܪܦܬܬܐ',
  'ܙܕܘܥܐ',
  'ܐܢܗܝܢ',
  'ܩܝܡܬܐ',
  'ܡܟܦܢܐ',
  'ܡܨܡܢܐ',
  'ܩܥܝܕܐ',
  'ܓܘܡܬܐ',
  'ܪܗܛܢܐ',
  'ܫܘܡܪܐ',
  'ܡܦܛܦܛ',
  'ܛܥܘܫܐ',
  'ܡܓܣܬܐ',
  'ܢܘܡܪܐ',
  'ܦܓܘܕܐ',
  'ܚܪܚܨܐ',
  'ܦܘܪܣܐ',
  'ܝܩܝܪܐ',
  'ܓܒܝܬܐ',
  'ܩܒܘܬܐ',
  'ܬܠܘܘܐ',
  'ܩܒܠܢܐ',
  'ܡܬܫܒܚ',
  'ܝܪܝܥܐ',
  'ܩܝܢܬܐ',
  'ܡܝܪܐܒ',
  'ܥܟܣܝܐ',
  'ܦܥܘܪܐ',
  'ܣܦܢܝܐ',
  'ܒܠܙܡܬ',
  'ܡܛܡܬܐ',
  'ܒܐܣܝܠ',
  'ܩܘܛܒܐ',
  'ܚܝܦܬܐ',
  'ܗܠܝܘܢ',
  'ܡܡܘܨܐ',
  'ܡܬܝܚܐ',
  'ܪܘܡܬܐ',
  'ܡܗܢܗܢ',
  'ܡܒܪܒܐ',
  'ܥܠܘܩܐ',
  'ܡܝܫܢܐ',
  'ܦܢܘܝܐ',
  'ܫܪܟܢܐ',
  'ܩܘܡܕܐ',
  'ܡܘܨܘܨ',
  'ܢܣܘܒܐ',
  'ܐܡܝܬܐ',
  'ܡܝܘܬܐ',
  'ܫܗܪܝܐ',
  'ܝܕܝܕܐ',
  'ܥܣܩܬܐ',
  'ܡܫܪܩܛ',
  'ܦܝܠܣܐ',
  'ܫܥܠܝܡ',
  'ܚܘܫܐܒ',
  'ܐܝܠܢܐ',
  'ܒܠܒܨܐ',
  'ܡܓܪܒܢ',
  'ܟܠܝܢܐ',
  'ܗܒܫܝܐ',
  'ܚܒܘܫܐ',
  'ܚܡܘܨܐ',
  'ܕܝܣܝܣ',
  'ܣܝܠܝܐ',
  'ܦܛܒܓܐ',
  'ܨܡܘܢܐ',
  'ܐܣܬܟܢ',
  'ܝܠܗܢܝ',
  'ܬܓܪܝܐ',
  'ܥܘܛܝܐ',
  'ܡܫܗܕܝ',
  'ܡܟܬܒܐ',
  'ܛܥܘܡܐ',
  'ܓܐܠܣܐ',
  'ܥܪܘܩܐ',
  'ܚܪܡܬܐ',
  'ܩܒܘܠܐ',
  'ܡܢܙܠܐ',
  'ܐܬܢܘܣ',
  'ܢܕܝܪܐ',
  'ܪܬܘܡܐ',
  'ܥܡܪܢܐ',
  'ܗܝܣܦܪ',
  'ܕܣܘܩܐ',
  'ܡܦܠܬܟ',
  'ܫܪܘܥܐ',
  'ܐܙܓܕܐ',
  'ܩܝܘܡܐ',
  'ܟܦܦܬܐ',
  'ܚܘܨܬܐ',
  'ܣܟܘܝܐ',
  'ܡܙܒܢܐ',
  'ܠܚܝܡܐ',
  'ܒܨܡܪܐ',
  'ܒܢܦܫܓ',
  'ܚܪܘܪܐ',
  'ܗܘܡܣܐ',
  'ܒܒܗܠܐ',
  'ܣܘܕܟܪ',
  'ܫܘܝܠܐ',
  'ܗܢܘܝܐ',
  'ܫܘܢܝܡ',
  'ܗܙܙܬܐ',
  'ܦܝܫܬܐ',
  'ܩܪܘܛܐ',
  'ܚܣܘܝܐ',
  'ܦܘܚܡܐ',
  'ܛܡܥܢܐ',
  'ܩܘܒܠܐ',
  'ܪܦܘܝܐ',
  'ܐܣܘܝܐ',
  'ܐܪܒܥܝ',
  'ܡܩܪܦܚ',
  'ܡܙܝܕܐ',
  'ܣܩܠܬܐ',
  'ܢܩܘܪܐ',
  'ܝܘܢܬܐ',
  'ܫܘܪܡܐ',
  'ܕܝܘܨܐ',
  'ܒܒܠܝܐ',
  'ܫܢܘܙܐ',
  'ܠܘܛܬܐ',
  'ܣܝܒܪܐ',
  'ܐܪܘܓܐ',
  'ܥܬܝܕܐ',
  'ܥܨܪܝܐ',
  'ܠܩܠܩܐ',
  'ܓܪܝܣܐ',
  'ܦܠܘܛܐ',
  'ܡܟܬܟܬ',
  'ܫܒܘܛܐ',
  'ܡܐܟܢܐ',
  'ܫܬܠܬܐ',
  'ܦܪܝܪܐ',
  'ܙܐܦܢܐ',
  'ܡܛܝܪܐ',
  'ܒܪܢܝܐ',
  'ܕܟܝܬܐ',
  'ܪܟܒܬܐ',
  'ܗܡܪܬܐ',
  'ܣܛܪܛܣ',
  'ܬܒܪܝܙ',
  'ܐܘܣܡܢ',
  'ܝܩܘܢܕ',
  'ܒܘܨܪܐ',
  'ܚܪܓܘܠ',
  'ܠܚܡܬܐ',
  'ܩܘܝܨܐ',
  'ܕܢܝܚܐ',
  'ܚܙܘܝܐ',
  'ܛܒܘܥܐ',
  'ܚܛܝܬܐ',
  'ܡܪܝܙܐ',
  'ܡܝܟܒܐ',
  'ܦܪܦܪܐ',
  'ܡܦܢܦܟ',
  'ܚܫܝܫܐ',
  'ܦܪܣܝܢ',
  'ܙܪܝܒܐ',
  'ܒܪܘܨܐ',
  'ܚܙܡܝܝ',
  'ܐܬܬܘܝ',
  'ܣܦܪܢܐ',
  'ܓܠܦܬܐ',
  'ܩܕܝܡܐ',
  'ܗܦܟܬܐ',
  'ܬܢܒܟܘ',
  'ܥܙܩܬܐ',
  'ܙܝܘܢܐ',
  'ܗܘܪܫܐ',
  'ܡܢܙܝܠ',
  'ܓܝܓܠܐ',
  'ܛܦܝܬܐ',
  'ܚܘܫܬܝ',
  'ܡܚܪܚܛ',
  'ܬܚܬܝܐ',
  'ܩܗܝܬܐ',
  'ܣܪܝܩܐ',
  'ܛܢܛܢܐ',
  'ܙܩܘܦܐ',
  'ܒܕܘܟܬ',
  'ܣܘܡܢܐ',
  'ܙܘܝܪܐ',
  'ܟܪܘܙܐ',
  'ܪܥܘܝܐ',
  'ܫܘܟܪܐ',
  'ܫܒܫܬܐ',
  'ܦܢܝܬܐ',
  'ܪܫܘܡܐ',
  'ܓܘܡܝܙ',
  'ܙܥܘܦܐ',
  'ܡܫܟܢܐ',
  'ܫܥܘܬܐ',
  'ܕܘܡܣܐ',
  'ܦܩܝܠܐ',
  'ܡܩܪܢܙ',
  'ܚܠܡܢܐ',
  'ܦܝܘܕܐ',
  'ܥܕܩܬܐ',
  'ܒܛܢܬܐ',
  'ܩܠܘܢܐ',
  'ܓܘܬܬܐ',
  'ܢܒܝܬܐ',
  'ܦܘܫܢܓ',
  'ܗܓܝܢܐ',
  'ܣܢܝܢܐ',
  'ܗܘܦܟܐ',
  'ܡܬܢܝܐ',
  'ܟܡܟܡܐ',
  'ܨܦܝܢܐ',
  'ܛܦܝܢܐ',
  'ܐܠܦܝܐ',
  'ܥܓܠܝܡ',
  'ܬܠܝܢܐ',
  'ܛܝܘܦܐ',
  'ܩܘܪܐܓ',
  'ܦܘܪܫܐ',
  'ܩܘܠܬܐ',
  'ܩܘܪܩܐ',
  'ܐܘܡܒܐ',
  'ܓܝܐܝܬ',
  'ܪܢܘܝܐ',
  'ܫܢܘܩܐ',
  'ܚܦܘܛܐ',
  'ܒܣܝܡܐ',
  'ܐܘܓܘܪ',
  'ܫܪܛܝܐ',
  'ܐܠܒܐܠ',
  'ܡܬܪܚܨ',
  'ܒܘܗܒܐ',
  'ܒܚܠܬܐ',
  'ܟܝܠܬܐ',
  'ܬܘܦܢܓ',
  'ܦܫܘܩܐ',
  'ܩܪܝܛܐ',
  'ܡܠܘܬܐ',
  'ܡܘܪܘܢ',
  'ܬܠܝܬܐ',
  'ܢܩܘܙܐ',
  'ܡܩܕܝܡ',
  'ܙܥܘܪܐ',
  'ܓܘܡܣܐ',
  'ܡܠܝܛܐ',
  'ܠܐܘܪܐ',
  'ܫܟܝܢܐ',
  'ܨܚܚܬܐ',
  'ܢܘܗܪܐ',
  'ܫܒܝܒܐ',
  'ܪܥܝܢܐ',
  'ܢܬܘܪܐ',
  'ܡܩܠܒܙ',
  'ܦܝܚܪܐ',
  'ܝܒܡܬܐ',
  'ܢܡܓܕܢ',
  'ܦܝܬܚܬ',
  'ܡܣܝܬܐ',
  'ܐܘܕܒܐ',
  'ܬܡܪܬܐ',
  'ܡܘܩܝܡ',
  'ܡܢܬܡܠ',
  'ܩܘܡܛܝ',
  'ܦܪܙܝܙ',
  'ܠܘܕܝܐ',
  'ܢܗܘܪܐ',
  'ܣܝܥܬܐ',
  'ܨܘܕܪܐ',
  'ܫܛܘܪܐ',
  'ܐܘܪܥܐ',
  'ܚܒܘܛܐ',
  'ܐܘܡܢܐ',
  'ܣܕܘܩܐ',
  'ܩܢܘܢܐ',
  'ܒܝܘܢܐ',
  'ܣܡܣܪܐ',
  'ܣܚܝܬܐ',
  'ܡܟܐܪܐ',
  'ܚܠܘܠܐ',
  'ܡܓܥܘܛ',
  'ܬܒܝܥܬ',
  'ܚܠܚܠܐ',
  'ܒܘܙܚܐ',
  'ܙܥܝܦܐ',
  'ܥܘܓܠܐ',
  'ܡܢܝܢܐ',
  'ܪܘܚܬܐ',
  'ܛܪܘܢܐ',
  'ܡܓܝܫܐ',
  'ܥܬܝܩܐ',
  'ܡܩܫܬܐ',
  'ܠܠܘܟܘ',
  'ܐܗܣܦܪ',
  'ܡܟܘܡܐ',
  'ܒܬܦܩܘ',
  'ܪܡܘܬܐ',
  'ܢܘܘܚܐ',
  'ܢܣܘܝܐ',
  'ܫܕܘܬܐ',
  'ܡܪܝܩܐ',
  'ܩܘܛܪܐ',
  'ܡܘܫܡܥ',
  'ܡܟܪܟܪ',
  'ܗܘܬܟܐ',
  'ܩܪܣܬܐ',
  'ܬܠܩܬܐ',
  'ܝܬܩܪܐ',
  'ܪܫܚܢܕ',
  'ܩܛܪܟܐ',
  'ܢܩܦܬܐ',
  'ܡܩܢܬܪ',
  'ܠܒܘܟܐ',
  'ܡܦܪܦܣ',
  'ܙܢܢܬܐ',
  'ܪܙܘܙܐ',
  'ܥܠܘܠܐ',
  'ܡܝܪܬܐ',
  'ܣܡܐܝܬ',
  'ܣܓܝܘܢ',
  'ܡܚܡܘܪ',
  'ܐܫܝܝܐ',
  'ܡܙܘܪܐ',
  'ܣܝܡܬܐ',
  'ܫܡܝܪܡ',
  'ܣܩܠܢܐ',
  'ܫܒܘܛܐ',
  'ܩܪܘܡܐ',
  'ܠܩܛܢܐ',
  'ܗܝܪܬܐ',
  'ܐܘܣܘܣ',
  'ܠܙܝܙܐ',
  'ܥܫܝܪܬ',
  'ܕܡܘܓܐ',
  'ܡܕܥܢܐ',
  'ܚܩܪܬܐ',
  'ܡܠܚܘܐ',
  'ܣܘܝܥܐ',
  'ܒܫܪܛܐ',
  'ܩܨܝܦܐ',
  'ܡܐܡܘܪ',
  'ܡܪܝܕܐ',
  'ܪܗܘܡܝ',
  'ܐܝܣܚܩ',
  'ܬܪܝܢܐ',
  'ܚܒܩܘܩ',
  'ܟܒܝܪܐ',
  'ܪܗܘܛܐ',
  'ܦܪܥܢܐ',
  'ܦܪܬܘܟ',
  'ܡܦܩܬܐ',
  'ܡܢܟܪܢ',
  'ܐܓܪܝܐ',
  'ܓܡܪܝܐ',
  'ܐܝܫܝܐ',
  'ܩܝܙܓܢ',
  'ܨܪܝܥܐ',
  'ܡܚܘܝܐ',
  'ܬܘܪܝܓ',
  'ܒܛܫܘܐ',
  'ܡܝܩܪܢ',
  'ܥܘܬܩܐ',
  'ܨܘܨܪܐ',
  'ܨܒܘܬܐ',
  'ܕܐܪܬܐ',
  'ܡܣܪܣܪ',
  'ܡܠܨܠܨ',
  'ܫܡܘܢܐ',
  'ܥܛܝܪܐ',
  'ܫܠܡܬܐ',
  'ܥܘܠܢܐ',
  'ܢܣܝܪܐ',
  'ܬܐܘܡܐ',
  'ܗܐܝܗܝ',
  'ܛܪܩܦܐ',
  'ܨܘܠܠܐ',
  'ܚܒܝܟܐ',
  'ܚܡܝܨܐ',
  'ܒܫܠܝܐ',
  'ܝܘܫܝܐ',
  'ܫܢܘܝܐ',
  'ܦܝܠܩܐ',
  'ܣܠܒܬܐ',
  'ܡܚܦܚܦ',
  'ܦܝܛܪܐ',
  'ܩܡܘܛܐ',
  'ܙܪܘܝܐ',
  'ܡܬܢܬܨ',
  'ܡܘܪܛܐ',
  'ܛܒܨܪܐ',
  'ܫܘܚܢܐ',
  'ܩܘܘܡܐ',
  'ܢܙܐܢܝ',
  'ܠܗܝܩܐ',
  'ܡܪܬܪܬ',
  'ܦܝܫܢܐ',
  'ܗܠܩܬܐ',
  'ܡܢܦܩܐ',
  'ܢܩܝܕܐ',
  'ܡܕܩܕܩ',
  'ܨܘܪܬܐ',
  'ܕܘܒܩܐ',
  'ܡܙܒܢܐ',
  'ܚܒܘܒܐ',
  'ܩܪܡܣܐ',
  'ܐܪܢܬܐ',
  'ܣܪܫܬܐ',
  'ܫܘܚܕܐ',
  'ܡܕܒܪܐ',
  'ܦܫܪܘܟ',
  'ܡܚܪܒܣ',
  'ܦܝܙܪܐ',
  'ܪܘܒܥܐ',
  'ܐܟܘܟܐ',
  'ܚܝܟܬܐ',
  'ܚܘܕܬܐ',
  'ܫܬܘܦܐ',
  'ܠܘܥܓܐ',
  'ܥܕܟܝܠ',
  'ܡܬܦܬܦ',
  'ܝܪܘܩܐ',
  'ܫܛܝܚܐ',
  'ܝܠܙܘܝ',
  'ܫܓܝܫܐ',
  'ܬܐܒܙܐ',
  'ܓܥܘܡܐ',
  'ܒܘܪܝܐ',
  'ܢܥܘܨܐ',
  'ܒܩܪܢܐ',
  'ܪܥܝܢܗ',
  'ܫܘܚܬܐ',
  'ܟܐܦܢܐ',
  'ܚܟܘܬܐ',
  'ܥܘܡܨܐ',
  'ܡܣܢܬܐ',
  'ܒܬܪܟܢ',
  'ܨܚܘܢܐ',
  'ܬܢܘܝܐ',
  'ܥܗܝܕܐ',
  'ܓܡܘܙܐ',
  'ܩܛܪܝܢ',
  'ܡܣܝܢܐ',
  'ܪܝܝܟܪ',
  'ܕܝܢܕܪ',
  'ܡܚܝܒܢ',
  'ܡܘܨܝܒ',
  'ܒܕܘܪܐ',
  'ܝܗܘܒܐ',
  'ܫܦܘܕܐ',
  'ܬܒܝܗܐ',
  'ܦܝܣܬܐ',
  'ܡܬܥܘܐ',
  'ܟܫܝܪܐ',
  'ܫܘܩܦܐ',
  'ܚܕܘܬܐ',
  'ܕܘܥܟܐ',
  'ܣܬܝܪܐ',
  'ܫܩܪܩܐ',
  'ܠܐܘܬܐ',
  'ܟܕܝܪܐ',
  'ܙܪܘܘܐ',
  'ܚܝܝܢܬ',
  'ܡܩܘܡܐ',
  'ܓܓܘܝܐ',
  'ܝܕܢܬܐ',
  'ܩܝܫܬܐ',
  'ܕܘܝܪܐ',
  'ܗܫܐܝܬ',
  'ܨܪܘܟܐ',
  'ܟܦܢܬܐ',
  'ܡܗܕܣܐ',
  'ܗܡܘܢܐ',
  'ܪܚܝܡܐ',
  'ܕܠܘܦܐ',
  'ܒܘܬܠܐ',
  'ܒܙܠܝܐ',
  'ܦܣܩܝܢ',
  'ܚܘܪܫܐ',
  'ܡܨܝܬܐ',
  'ܫܓܝܪܐ',
  'ܩܠܘܣܐ',
  'ܐܡܪܢܐ',
  'ܡܬܩܢܩ',
  'ܡܠܚܟܐ',
  'ܚܦܝܛܐ',
  'ܓܢܘܗܝ',
  'ܙܘܕܢܐ',
  'ܟܪܘܙܐ',
  'ܕܒܚܬܐ',
  'ܦܪܩܬܐ',
  'ܢܟܘܝܐ',
  'ܙܓܘܓܐ',
  'ܣܦܣܬܐ',
  'ܣܘܪܓܐ',
  'ܬܘܟܠܝ',
  'ܕܘܘܕܐ',
  'ܡܝܚܓܪ',
  'ܥܩܘܒܐ',
  'ܛܪܘܒܐ',
  'ܩܨܢܝܐ',
  'ܬܥܠܝܡ',
  'ܟܒܘܠܐ',
  'ܫܡܥܢܐ',
  'ܡܥܬܕܐ',
  'ܕܘܝܠܗ',
  'ܓܪܝܥܐ',
  'ܥܓܘܕܐ',
  'ܚܠܝܦܐ',
  'ܡܥܬܩܢ',
  'ܫܘܡܪܐ',
  'ܕܪܘܪܐ',
  'ܫܗܘܬܐ',
  'ܡܩܫܫܐ',
  'ܣܛܐܟܝ',
  'ܦܘܬܠܐ',
  'ܕܚܕܕܐ',
  'ܒܝܒܠܐ',
  'ܩܘܛܝܐ',
  'ܗܪܫܬܐ',
  'ܬܦܘܘܐ',
  'ܦܩܥܬܐ',
  'ܚܘܒܠܐ',
  'ܝܘܚܢܢ',
  'ܡܫܪܒܛ',
  'ܫܠܘܦܐ',
  'ܥܝܒܢܐ',
  'ܚܡܝܬܐ',
  'ܡܪܝܬܐ',
  'ܒܪܓܟܐ',
  'ܫܡܫܢܐ',
  'ܛܪܟܢܐ',
  'ܡܐܢܟܪ',
  'ܦܪܙܥܐ',
  'ܣܘܝܢܐ',
  'ܪܚܛܬܐ',
  'ܠܓܝܘܢ',
  'ܪܬܝܡܐ',
  'ܟܢܓܘܫ',
  'ܢܘܣܝܐ',
  'ܫܪܘܥܐ',
  'ܗܦܝܟܐ',
  'ܩܘܕܡܐ',
  'ܠܒܪܝܝ',
  'ܬܠܝܠܐ',
  'ܩܗܘܝܐ',
  'ܛܝܘܣܐ',
  'ܓܒܘܝܐ',
  'ܦܪܚܢܐ',
  'ܕܒܪܬܐ',
  'ܡܙܠܦܐ',
  'ܒܗܝܠܐ',
  'ܙܪܝܦܐ',
  'ܟܒܪܝܬ',
  'ܛܦܘܣܐ',
  'ܣܘܡܒܐ',
  'ܒܨܝܪܬ',
  'ܐܣܛܘܐ',
  'ܠܛܘܬܐ',
  'ܙܝܪܬܐ',
  'ܢܘܘܙܐ',
  'ܚܡܘܬܐ',
  'ܙܘܥܦܐ',
  'ܟܘܒܫܐ',
  'ܐܛܪܝܢ',
  'ܚܘܪܬܘ',
  'ܢܒܘܝܐ',
  'ܡܫܥܒܛ',
  'ܚܕܘܪܐ',
  'ܫܒܝܫܐ',
  'ܒܠܠܝܐ',
  'ܡܐܬܝܢ',
  'ܨܒܘܪܐ',
  'ܡܩܪܩܡ',
  'ܫܟܘܝܐ',
  'ܦܠܫܬܝ',
  'ܟܘܣܦܐ',
  'ܨܝܪܬܐ',
  'ܥܘܥܣܐ',
  'ܚܓܝܪܐ',
  'ܟܗܢܬܐ',
  'ܡܪܘܣܐ',
  'ܚܙܘܩܐ',
  'ܠܘܥܕܐ',
  'ܢܩܡܬܐ',
  'ܚܣܝܪܐ',
  'ܒܩܝܪܐ',
  'ܐܘܦܪܐ',
  'ܩܘܦܕܐ',
  'ܡܕܪܢܐ',
  'ܓܙܒܪܐ',
  'ܬܝܦܬܐ',
  'ܡܟܘܠܬ',
  'ܡܫܘܫܐ',
  'ܡܐܩܝܪ',
  'ܚܬܘܝܐ',
  'ܐܪܒܢܐ',
  'ܠܘܘܣܐ',
  'ܐܡܘܪܐ',
  'ܚܕܥܣܪ',
  'ܥܝܝܛܐ',
  'ܓܒܝܢܐ',
  'ܕܘܒܢܐ',
  'ܡܐܝܡܢ',
  'ܝܩܘܪܐ',
  'ܪܦܦܝܐ',
  'ܫܩܘܬܐ',
  'ܨܠܘܒܐ',
  'ܣܦܘܩܐ',
  'ܩܘܫܬܐ',
  'ܩܘܨܬܐ',
  'ܡܕܝܪܐ',
  'ܒܝܬܟܐ',
  'ܓܘܕܪܐ',
  'ܥܠܠܡܐ',
  'ܙܝܘܥܐ',
  'ܒܚܝܫܐ',
  'ܒܝܪܕܐ',
  'ܦܠܛܝܢ',
  'ܩܥܪܬܐ',
  'ܦܠܓܓܐ',
  'ܩܩܠܬܐ',
  'ܠܘܩܕܡ',
  'ܡܨܝܒܬ',
  'ܫܘܘܪܐ',
  'ܚܘܨܦܐ',
  'ܡܒܠܒܐ',
  'ܐܫܟܐܐ',
  'ܬܠܘܠܐ',
  'ܐܣܛܡܐ',
  'ܡܬܠܡܕ',
  'ܒܛܘܬܐ',
  'ܢܘܩܫܐ',
  'ܓܠܢܕܝ',
  'ܠܫܚܘܪ',
  'ܝܘܪܝܫ',
  'ܡܓܘܢܐ',
  'ܥܠܡܝܐ',
  'ܢܥܡܬܐ',
  'ܓܕܘܕܐ',
  'ܕܪܒܬܐ',
  'ܡܟܪܬܠ',
  'ܡܣܒܪܐ',
  'ܡܩܪܬܠ',
  'ܩܝܛܘܢ',
  'ܨܢܘܝܐ',
  'ܬܘܘܒܐ',
  'ܒܐܐܕܒ',
  'ܡܘܝܕܐ',
  'ܫܡܘܚܐ',
  'ܗܘܠܠܐ',
  'ܟܫܘܒܐ',
  'ܛܥܝܡܐ',
  'ܪܫܝܬܐ',
  'ܕܘܢܒܐ',
  'ܪܥܝܬܐ',
  'ܡܩܒܠܐ',
  'ܩܒܝܬܐ',
  'ܟܬܝܒܐ',
  'ܚܘܝܠܐ',
  'ܐܨܘܬܐ',
  'ܫܚܝܬܐ',
  'ܠܣܛܝܐ',
  'ܩܪܘܬܐ',
  'ܪܡܢܬܐ',
  'ܫܢܝܬܐ',
  'ܦܘܫܝܐ',
  'ܡܓܕܘܠ',
  'ܩܠܝܦܐ',
  'ܫܡܚܟܐ',
  'ܚܘܫܒܐ',
  'ܛܪܘܢܐ',
  'ܕܝܢܣܙ',
  'ܠܕܪܘܐ',
  'ܛܦܝܢܐ',
  'ܥܕܢܬܐ',
  'ܢܣܛܘܪ',
  'ܡܚܬܪܐ',
  'ܚܫܠܢܐ',
  'ܐܨܛܚܢ',
  'ܠܘܝܬܐ',
  'ܡܪܘܡܐ',
  'ܗܨܘܠܐ',
  'ܡܓܘܫܐ',
  'ܓܪܦܬܪ',
  'ܙܪܘܙܐ',
  'ܛܠܫܬܐ',
  'ܣܩܘܛܐ',
  'ܦܘܓܝܐ',
  'ܬܟܝܒܐ',
  'ܚܪܫܢܐ',
  'ܫܦܝܥܐ',
  'ܡܫܝܬܐ',
  'ܢܘܟܬܐ',
  'ܗܝܪܢܐ',
  'ܛܘܠܩܐ',
  'ܙܩܘܛܐ',
  'ܦܬܘܩܐ',
  'ܥܡܝܡܐ',
  'ܡܚܪܦܫ',
  'ܢܡܢܬܐ',
  'ܒܬܚܡܢ',
  'ܟܒܘܫܐ',
  'ܦܣܝܢܐ',
  'ܢܘܝܚܐ',
  'ܣܛܗܐܪ',
  'ܓܢܘܨܐ',
  'ܟܘܬܠܐ',
  'ܐܟܬܢܐ',
  'ܫܘܠܡܐ',
  'ܚܘܪܫܐ',
  'ܡܪܬܢܐ',
  'ܫܠܝܦܐ',
  'ܒܓܠܛܐ',
  'ܙܠܘܒܐ',
  'ܙܘܓܡܐ',
  'ܡܓܠܓܠ',
  'ܐܦܬܪܐ',
  'ܡܘܚܟܡ',
  'ܫܢܝܬܐ',
  'ܡܟܝܟܐ',
  'ܫܦܘܦܐ',
  'ܡܥܠܩܐ',
  'ܬܘܕܪܐ',
  'ܨܩܘܛܐ',
  'ܟܘܚܕܐ',
  'ܬܘܪܟܐ',
  'ܡܠܒܠܐ',
  'ܨܢܬܘܪ',
  'ܥܒܘܪܐ',
  'ܫܘܕܠܐ',
  'ܕܪܝܫܐ',
  'ܟܬܘܟܐ',
  'ܓܘܠܥܐ',
  'ܥܝܢܢܐ',
  'ܐܪܐܝܠ',
  'ܡܥܘܠܐ',
  'ܬܠܬܝܢ',
  'ܨܪܠܘܓ',
  'ܫܘܕܬܐ',
  'ܡܐܗܘܬ',
  'ܐܢܘܢܐ',
  'ܥܠܠܬܐ',
  'ܙܘܦܪܐ',
  'ܗܘܨܠܐ',
  'ܙܘܕܢܐ',
  'ܢܪܝܬܐ',
  'ܡܥܠܢܐ',
  'ܕܪܡܘܢ',
  'ܦܪܦܥܪ',
  'ܫܦܟܬܐ',
  'ܚܝܪܦܐ',
  'ܪܪܕܫܪ',
  'ܝܢܘܩܐ',
  'ܡܚܒܢܗ',
  'ܣܝܢܡܐ',
  'ܛܡܢܝܐ',
  'ܦܠܘܥܐ',
  'ܟܡܝܫܐ',
  'ܒܙܒܢܐ',
  'ܡܦܣܥܠ',
  'ܡܐܕܪܢ',
  'ܟܦܢܬܐ',
  'ܚܛܝܢܐ',
  'ܓܪܣܝܐ',
  'ܕܥܪܬܐ',
  'ܦܬܠܬܐ',
  'ܕܟܪܝܐ',
  'ܒܘܡܒܐ',
  'ܦܣܝܣܐ',
  'ܟܘܪܢܐ',
  'ܣܗܝܢܐ',
  'ܟܘܬܬܐ',
  'ܒܥܬܢܐ',
  'ܓܢܝܬܐ',
  'ܚܕܝܥܐ',
  'ܒܕܓܘܢ',
  'ܥܠܘܝܐ',
  'ܦܘܠܓܐ',
  'ܣܥܪܬܐ',
  'ܡܘܢܘܢ',
  'ܩܘܠܝܐ',
  'ܥܕܘܕܐ',
  'ܦܗܠܘܢ',
  'ܡܘܩܕܐ',
  'ܡܝܝܬܐ',
  'ܟܡܘܪܐ',
  'ܐܝܢܣܢ',
  'ܠܚܕܕܐ',
  'ܚܘܡܫܐ',
  'ܫܪܘܙܐ',
  'ܪܥܝܥܐ',
  'ܛܘܪܦܐ',
  'ܝܢܫܘܦ',
  'ܩܠܦܬܐ',
  'ܐܪܢܒܐ',
  'ܒܛܒܛܐ',
  'ܐܠܨܝܐ',
  'ܝܐܝܬܐ',
  'ܡܫܬܐܣ',
  'ܐܒܘܠܐ',
  'ܦܘܠܩܐ',
  'ܣܘܢܒܐ',
  'ܦܢܘܩܐ',
  'ܣܠܝܩܐ',
  'ܐܦܪܬܐ',
  'ܦܪܝܕܐ',
  'ܓܘܡܨܐ',
  'ܥܘܝܘܢ',
  'ܓܘܚܬܐ',
  'ܫܘܗܪܐ',
  'ܦܘܣܬܐ',
  'ܚܨܝܢܐ',
  'ܒܣܬܢܐ',
  'ܚܒܝܪܐ',
  'ܢܛܘܠܐ',
  'ܐܘܒܝܐ',
  'ܬܪܥܝܐ',
  'ܚܝܘܛܐ',
  'ܚܦܘܬܐ',
  'ܙܘܝܩܐ',
  'ܠܣܛܝܟ',
  'ܛܠܒܬܐ',
  'ܒܗܦܟܐ',
  'ܨܪܝܚܐ',
  'ܫܦܘܦܐ',
  'ܒܓܝܪܐ',
  'ܦܘܪܛܐ',
  'ܚܠܢܝܐ',
  'ܣܪܝܕܐ',
  'ܡܨܚܘܢ',
  'ܠܥܣܠܣ',
  'ܐܬܠܝܐ',
  'ܠܐܒܢܐ',
  'ܒܩܘܪܐ',
  'ܓܪܣܬܐ',
  'ܒܠܥܬܐ',
  'ܕܒܘܪܐ',
  'ܐܘܟܬܐ',
  'ܡܛܢܛܠ',
  'ܡܥܝܬܐ',
  'ܒܐܨܒܪ',
  'ܚܢܟܝܐ',
  'ܝܙܦܬܐ',
  'ܣܘܪܝܐ',
  'ܐܒܙܪܐ',
  'ܩܨܘܡܐ',
  'ܬܘܦܘܣ',
  'ܚܣܝܠܐ',
  'ܠܟܘܬܐ',
  'ܒܘܕܠܐ',
  'ܙܕܝܬܐ',
  'ܣܓܕܬܐ',
  'ܐܠܬܡܐ',
  'ܚܘܦܛܐ',
]
